import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class AuthNoticeService {
    constructor() {
        this.onNoticeChanged$ = new BehaviorSubject(null);
    }
    setNotice(message, type) {
        const notice = {
            message: message,
            type: type
        };
        this.onNoticeChanged$.next(notice);
    }
}
AuthNoticeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthNoticeService_Factory() { return new AuthNoticeService(); }, token: AuthNoticeService, providedIn: "root" });
