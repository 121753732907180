import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
// NGRX
import { BeneficiariesActions, BeneficiariesActionTypes } from './../actions/beneficiaries';
import { Beneficiary } from '../../models/beneficiary';

export interface BeneficiaryState extends EntityState<Beneficiary> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Beneficiary> = createEntityAdapter<Beneficiary>();

export const initialBeneficiaryState: BeneficiaryState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedUserId: undefined,
    showInitWaitingMessage: true
});

export function beneficiariesReducer(state = initialBeneficiaryState, action: BeneficiariesActions): BeneficiaryState {
    switch (action.type) {
        case BeneficiariesActionTypes.LOAD_BENEFICIARIES_PAGE: {
            return adapter.addMany(action.payload.beneficiaries, {
                ...initialBeneficiaryState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        // case EntitiesActionTypes.ADD_DOCUMENT_TO_ENTITY: {
        //     const beneficiaries = state.onboarding.beneficiaries.slice(0);
        //     const addEntities = beneficiaries.map(entity => {
        //         return Object.assign({ ...entity }, { documents: action.payload[0].entity_id === entity.id ? action.payload : [] });
        //     });
        //     return {
        //         ...state,
        //         onboarding: {
        //             beneficiaries: [...addEntities]
        //         }
        //     };
        // }
        default: return state;
    }
}
