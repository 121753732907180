<!--begin::Head-->
<div class="kt-login__head">
    <span class="kt-login__signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
    <a href="javascript:;" routerLink="/auth/register" class="kt-link kt-login__signup-link">{{ 'AUTH.GENERAL.SIGNUP_BUTTON' | translate }}</a>
</div>
<!--end::Head-->

<!--begin::Body-->
<div class="kt-login__body">
    <!--begin::Signin-->
    <div class="kt-login__form">
        <div class="kt-login__title">
            <h3>Sign In</h3>
        </div>

        <kt-auth-notice></kt-auth-notice>

        <!--begin::Form-->
        <form class="kt-form" [formGroup]="loginForm" autocomplete="off">
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
                    <input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('email','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','email')">
                        <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('password','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <!--begin::Action-->
            <div class="kt-login__actions">
                <a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
                    {{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
                </a>
                <button (click)="submit()" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}" id="kt_login_signin_submit"
                        class="btn btn-primary btn-elevate kt-login__btn-primary">{{ 'AUTH.LOGIN.BUTTON' | translate }}</button>
            </div>
            <!--end::Action-->
        </form>
        <!--end::Form-->

        <!--begin::Divider-->
        <div class="kt-login__divider">
            <div class="kt-divider">
                <span></span>
                <span>OR</span>
                <span></span>
            </div>
        </div>
        <!--end::Divider-->

        <!--begin::Options-->
        <div class="kt-login__options">
            <button href="javascript:;" class="btn btn-primary kt-btn">
                <i class="fab fa-facebook-f"></i>
                Facebook
            </button>

            <a href="https://twitter.com/keenthemes/" target="_blank" class="btn btn-info kt-btn">
                <i class="fab fa-twitter"></i>
                Twitter
            </a>

            <a href="javascript:;" class="btn btn-danger kt-btn">
                <i class="fab fa-google"></i>
                Google
            </a>
        </div>
        <!--end::Options-->
    </div>
    <!--end::Signin-->
</div>
<!--end::Body-->
