/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./views/partials/layout/splash-screen/splash-screen.component.ngfactory";
import * as i3 from "./views/partials/layout/splash-screen/splash-screen.component";
import * as i4 from "./core/_base/layout/services/layout-config.service";
import * as i5 from "./core/_base/layout/services/splash-screen.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/router";
import * as i8 from "./app.component";
import * as i9 from "./core/_base/layout/services/translation.service";
import * as i10 from "./services/auth.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-splash-screen", [], null, null, null, i2.View_SplashScreenComponent_0, i2.RenderType_SplashScreenComponent)), i1.ɵdid(1, 114688, null, 0, i3.SplashScreenComponent, [i1.ElementRef, i4.LayoutConfigService, i5.SplashScreenService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [["class", "looking"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loader; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "body", [["kt-root", ""]], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i9.TranslationService, i7.Router, i10.AuthenticationService, i4.LayoutConfigService, i5.SplashScreenService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("body[kt-root]", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
