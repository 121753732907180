<div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" id="kt_login">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
        <!--begin::Aside-->
        <!-- <div class="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
             style="background-image: url(./assets/media//bg/bg-4.jpg);">
            <div class="kt-grid__item">
                <a href="javascript:;" class="kt-login__logo">
                    <img src="./assets/media/logos/logo-4.png" alt="">
                </a>
            </div>
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                <div class="kt-grid__item kt-grid__item--middle">
                    <h3 class="kt-login__title">BCB Group</h3>
                    <!-- <h4 class="kt-login__subtitle">The ultimate Bootstrap &amp; Angular 8 admin theme framework for next generation web apps.</h4> -->
                <!-- </div>
            </div>
            <div class="kt-grid__item">
                <div class="kt-login__info">
                    <div class="kt-login__copyright">
                        © {{today|date:'yyyy'}} BCB Group
                    </div>
                </div>
            </div>
        </div> -->
        <!--begin::Aside-->

        <!--begin::Content-->
        <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper">
            <router-outlet></router-outlet>
        </div>
        <!--end::Content-->
    </div>
</div>
