import { FormControl } from '@angular/forms'
import { PageConfigService } from './../../../../../core/_base/layout/services/page-config.service'
import { AddCorporateFormModalComponent } from './../../add-corporate-form-modal/add-corporate-form-modal.component'
import { AddIndividualFormModalComponent } from './../../add-individual-form-modal/add-individual-form-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
// Angular
import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core'
// RxJS
import { Subscription } from 'rxjs'
// Layout
import { SubheaderService } from '../../../../../core/_base/layout'
import { Breadcrumb } from '../../../../../core/_base/layout/services/subheader.service'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
	selector: 'kt-subheader3',
	templateUrl: './subheader3.component.html',
	styleUrls: ['./subheader3.component.scss']
})
export class Subheader3Component implements OnInit, OnDestroy, AfterContentInit {
	// Public properties
	@Input() fluid: boolean
	@Input() clear: boolean
	currentUrl: string
	currentRoute: string
	today: number = Date.now()
	title: string = ''
	desc: string = ''
	breadcrumbs: Breadcrumb[] = []
	showFilter = false
	// Private properties
	private subscriptions: Subscription[] = []
	queryField: FormControl = new FormControl()
	/**
	 * Component constructor
	 *
	 * @param subheaderService: SubheaderService
	 */
	constructor(
		public subheaderService: SubheaderService,
		private modalService: NgbModal,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private pageConfigService: PageConfigService
	) {}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */
	/**
	 * On init
	 */
	ngOnInit() {
		// this.queryField.valueChanges.pipe(debounceTime(200), distinctUntilChanged())
		//     .subscribe(result => {
		//         this.navigateToPage(result);
		//     });
	}

	onSearch() {
		this.navigateToPage(this.queryField.value)
	}

	private navigateToPage(keyword: string): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: { keyword },
			queryParamsHandling: 'merge',
			replaceUrl: true
		})
	}

	/**
	 * After view init
	 */
	ngAfterContentInit(): void {
		this.subscriptions.push(
			this.subheaderService.title$.subscribe(bt => {
				const url = this.pageConfigService.cleanUrl(this.router.url)
				const currentRouteArray = url.split('.')

				if (currentRouteArray[0] === 'entities' && !currentRouteArray[2]) {
					this.showFilter = true
					this.currentUrl = this.router.url.split('?')[0]
					this.currentRoute = currentRouteArray[1] || ''
				} else {
					this.showFilter = false
				}

				// breadcrumbs title sometimes can be undefined
				if (bt) {
					Promise.resolve(null).then(() => {
						this.title = bt.title
						this.desc = bt.desc
					})
				}
			})
		)

		this.subscriptions.push(
			this.subheaderService.breadcrumbs$.subscribe(bc => {
				Promise.resolve(null).then(() => {
					this.breadcrumbs = bc
				})
			})
		)
	}

	onAddIndvidual() {
		this.modalService.open(AddIndividualFormModalComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		})
	}

	onAddCorporate() {
		this.modalService.open(AddCorporateFormModalComponent, {
			size: 'xl',
			backdrop: 'static',
			keyboard: false
		})
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.subscriptions.forEach(sb => sb.unsubscribe())
	}
}
