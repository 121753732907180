/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./balance-fee.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "ng-bootstrap-form-validation";
import * as i4 from "./balance-fee.component";
import * as i5 from "../utils/state/account-fees-state.service";
var styles_BalanceFeeComponent = [i0.styles];
var RenderType_BalanceFeeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BalanceFeeComponent, data: {} });
export { RenderType_BalanceFeeComponent as RenderType_BalanceFeeComponent };
export function View_BalanceFeeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "balance"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "form_items row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "form_container row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "fieldset", [], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["appearance", "outline"], ["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "input", [["class", "form-control col-4"], ["min", "0"], ["required", ""], ["type", "number"], ["value", "0"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "is-valid", null], [2, "is-invalid", null]], [[null, "ngModelChange"], [null, "keydown"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.amount = $event) !== false);
        ad = (pd_7 && ad);
    } if (("keydown" === en)) {
        var pd_8 = (_co.removeInvalidCharacters($event) !== false);
        ad = (pd_8 && ad);
    } if (("change" === en)) {
        var pd_9 = (_co.onChanged() !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(10, 16384, null, 0, i2.NumberValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵdid(11, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.NumberValueAccessor]), i1.ɵdid(14, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(16, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(17, 16384, null, 0, i3.ɵb, [[8, null], i3.BOOTSTRAP_VERSION], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_12 = ""; _ck(_v, 11, 0, currVal_12); var currVal_13 = _co.amount; _ck(_v, 14, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readOnly; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.balanceFeeText; _ck(_v, 7, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 11).required ? "" : null); var currVal_3 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 16).ngClassValid; var currVal_8 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 16).ngClassPending; var currVal_10 = i1.ɵnov(_v, 17).validClass; var currVal_11 = i1.ɵnov(_v, 17).invalidClass; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
export function View_BalanceFeeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-balance-fee", [], null, null, null, View_BalanceFeeComponent_0, RenderType_BalanceFeeComponent)), i1.ɵdid(1, 114688, null, 0, i4.BalanceFeeComponent, [i5.AccountFeesStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BalanceFeeComponentNgFactory = i1.ɵccf("kt-balance-fee", i4.BalanceFeeComponent, View_BalanceFeeComponent_Host_0, { currency: "currency", readOnly: "readOnly" }, { balanceOutput: "balanceOutput" }, []);
export { BalanceFeeComponentNgFactory as BalanceFeeComponentNgFactory };
