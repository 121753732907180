/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./topbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../partials/layout/topbar/user-profile/user-profile.component.ngfactory";
import * as i3 from "../../../partials/layout/topbar/user-profile/user-profile.component";
import * as i4 from "../../../../services/auth.service";
import * as i5 from "./topbar.component";
var styles_TopbarComponent = [i0.styles];
var RenderType_TopbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopbarComponent, data: {} });
export { RenderType_TopbarComponent as RenderType_TopbarComponent };
export function View_TopbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "kt-header__topbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kt-user-profile", [], null, null, null, i2.View_UserProfileComponent_0, i2.RenderType_UserProfileComponent)), i1.ɵdid(2, 114688, null, 0, i3.UserProfileComponent, [i4.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_TopbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-topbar", [], null, null, null, View_TopbarComponent_0, RenderType_TopbarComponent)), i1.ɵdid(1, 49152, null, 0, i5.TopbarComponent, [], null, null)], null, null); }
var TopbarComponentNgFactory = i1.ɵccf("kt-topbar", i5.TopbarComponent, View_TopbarComponent_Host_0, {}, {}, []);
export { TopbarComponentNgFactory as TopbarComponentNgFactory };
