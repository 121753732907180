import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
// NGRX
import { Action } from '@ngrx/store';
import { SystemUser } from './../../models/system-user';


export enum SystemsActionTypes {
    LOAD_SYSTEM_USERS = '[SYSTEM USERS] Load System Users',
    GET_SYSTEM_USERS = '[SYSTEM USERS] Get System Users',
    GET_SYSTEM_USERS_FAILURE = '[SYSTEM USERS] Get System Users failure',
    GET_SYSTEM_USERS_SUCCESS = '[SYSTEM USERS] Get System Users Success',
    ADD_SYSTEM_USER = '[SYSTEM USERS] Add System User',
    LOAD_SYSTEM_USERS_PAGE = '[SYSTEM USERS] System Users Loaded',
    ADD_SYSTEM_USER_SUCCESS = '[SYSTEM USERS] Add System User Success',
    ADD_SYSTEM_USER_FAILURE = '[SYSTEM USERS] Add System User Failure',
    EDIT_SYSTEM_USER = '[SYSTEM USERS] Edit System User',
    EDIT_SYSTEM_USER_SUCCESS = '[SYSTEM USERS] System User Edited',
    EDIT_SYSTEM_USER_FAIL = '[SYSTEM USERS] System User Failed to Edit',
    DISABLE_SYSTEM_USER = '[SYSTEM USERS] Disable System User',
    DELETE_SYSTEM_USER_SUCCESS = '[SYSTEM USERS] System User Deleted',
    DELETE_SYSTEM_USER_FAIL = '[SYSTEM USERS] System User Failed to Delete'
}


export class LoadSystemUsersAction implements Action {
    readonly type = SystemsActionTypes.LOAD_SYSTEM_USERS;
    constructor(public payload: SystemUser[]) { }
}

export class SystemUsersPageRequestedAction implements Action {
    readonly type = SystemsActionTypes.GET_SYSTEM_USERS;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class SystemUsersPageLoadedAction implements Action {
    readonly type = SystemsActionTypes.LOAD_SYSTEM_USERS_PAGE;
    constructor(public payload: { users: SystemUser[], totalCount: number, page: QueryParamsModel }) { }
}

export class DisableSystemUserAction implements Action {
    readonly type = SystemsActionTypes.DISABLE_SYSTEM_USER;
    constructor(public payload: { userId: string }) { }
}

export class GetSystemUsersFailureAction implements Action {
    readonly type = SystemsActionTypes.GET_SYSTEM_USERS_FAILURE;
    constructor(public payload: string) { }
}

export class GetSystemUsersSuccessAction implements Action {
    readonly type = SystemsActionTypes.GET_SYSTEM_USERS_SUCCESS;
    constructor(public payload: string) { }
}

export class AddSystemUserAction implements Action {
    readonly type = SystemsActionTypes.ADD_SYSTEM_USER;
    constructor(public payload: SystemUser) { }
}

export class AddSystemUserSuccessAction implements Action {
    readonly type = SystemsActionTypes.ADD_SYSTEM_USER_SUCCESS;
    constructor(public payload: string) { }
}

export class AddSystemUserFailureAction implements Action {
    readonly type = SystemsActionTypes.ADD_SYSTEM_USER_FAILURE;
    constructor(public payload: string) { }
}

export class EditSystemUserAction implements Action {
    readonly type = SystemsActionTypes.EDIT_SYSTEM_USER;
    constructor(public payload: SystemUser) { }
}

export type SystemsActions
    = LoadSystemUsersAction
    | SystemUsersPageRequestedAction
    | GetSystemUsersFailureAction
    | GetSystemUsersSuccessAction
    | AddSystemUserAction
    | AddSystemUserSuccessAction
    | AddSystemUserFailureAction
    | SystemUsersPageLoadedAction
    | EditSystemUserAction;
