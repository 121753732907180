/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../partials/layout/subheader/subheader3/subheader3.component.ngfactory";
import * as i2 from "../../partials/layout/subheader/subheader3/subheader3.component";
import * as i3 from "../../../core/_base/layout/services/subheader.service";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/_base/layout/services/page-config.service";
import * as i7 from "@angular/common";
import * as i8 from "./subheader.component";
import * as i9 from "../../../core/_base/layout/services/layout-config.service";
var styles_SubheaderComponent = [];
var RenderType_SubheaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SubheaderComponent, data: {} });
export { RenderType_SubheaderComponent as RenderType_SubheaderComponent };
function View_SubheaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader3", [], null, null, null, i1.View_Subheader3Component_0, i1.RenderType_Subheader3Component)), i0.ɵdid(1, 1294336, null, 0, i2.Subheader3Component, [i3.SubheaderService, i4.NgbModal, i5.ActivatedRoute, i5.Router, i6.PageConfigService], { fluid: [0, "fluid"], clear: [1, "clear"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fluid; var currVal_1 = _co.clear; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SubheaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SubheaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.layout === "subheader-v1"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubheaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-subheader", [], null, null, null, View_SubheaderComponent_0, RenderType_SubheaderComponent)), i0.ɵdid(1, 114688, null, 0, i8.SubheaderComponent, [i9.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubheaderComponentNgFactory = i0.ɵccf("kt-subheader", i8.SubheaderComponent, View_SubheaderComponent_Host_0, {}, {}, []);
export { SubheaderComponentNgFactory as SubheaderComponentNgFactory };
