// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ComponentsModule } from './entities/components/components.module';
import {MatStepperModule} from "@angular/material/stepper";


@NgModule({

    exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        CoreModule,
        PartialsModule,
        DashboardModule,
        ComponentsModule,
    ],
    providers: [],

})
export class PagesModule {
}
