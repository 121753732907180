import { ValidatorFn } from '@angular/forms';
import { countries } from './countries';

export const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

export const emailPattern = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const positiveNumber = /^((0|[1-9][0-9]*)|(0|[1-9][0-9]*)(\.\d+)?|(\.\d+)?)$/

export const ukPostalCodePattern = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
export const statusOptions = {
    dark: ['unknown'],
    danger: ['non-verified', 'flagged', 'exempt', 'awaiting_applicant', 'withdrawn', 'consider', 'unidentified'],
    warning: ['onboarding', 'inProgress', 'queued', 'non-verified', 'in_progress', 'awaiting_applicant', 'kyc_unsupported', 'rejected'],
    success: ['verifiedL2', 'verifiedL1', 'clear']
};

export const currencies = ['AED', 'AUD', 'BHD', 'CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MXN', 'NOK', 'NZD', 'PLN', 'RON', 'SEK', 'SGD', 'TRY', 'USD', 'ZAR'];
export const cryptoCurrencies = [
    'ADA', 'ALGO', 'AMS', 'ARDR', 'ARK', 'BAY', 'BCH', 'BTC', 'ENJ', 'EOS', 'ETC', 'ETH', 'FUN', 'GAME', 'HVN',
    'IOTA', 'KMD', 'LOOM', 'LSK', 'LTC', 'MAID', 'MIOTA', 'NANO', 'NEO', 'NXT', 'OMG', 'OST', 'PPT', 'PURE', 'RADS',
    'REN', 'REQ', 'RUPX', 'SC', 'SPANK', 'SPSL', 'STEEM', 'STRAT', 'SUR', 'SYS', 'TRX', 'USDT', 'VRC',
    'WAX', 'XEL', 'XEM', 'XLM', 'XMR', 'XRP', 'XVG', 'ZEC', 'ZIL'
];

export const banks = [{currencies: [], name: 'INCOREBANK'}, {currencies: ['EUR', 'GBP'], name: 'MODULR'}, {currencies: ['GBP'], name: 'CLEARBANK'}, {currencies: ['USD'], name: 'SIGNATURE'}, {currencies: ['EUR'], name: 'BANKING_CIRCLE'}];

export const modulrAssociateTypes = ['DIRECTOR', 'PARTNER', 'CSECRETARY', 'SOLETRADER', 'BENE_OWNER', 'C_INTEREST', 'INDIVIDUAL', 'PCM_INDIVIDUAL'
];
export const modulrAccountTypes = ['LLC', 'PLC', 'SOLETRADER', 'OPARTNRSHP', 'LPARTNRSHP', 'LLP', 'INDIVIDUAL'];
export const modulrLegalEntities = ['GB', 'IE'];

export const accountPurposes = ['Master Settlement Account', 'Safeguarding Account', 'Client Account - Payment', 'Client Account - BLINC', 'Client Account - Trading', 'Client Account - Test', 'BCB OPEX','BCB Settlement']

export enum LegalEntityIds {
	FRANCE_LEGAL_ENTITY_ID = 5
}

export const products = [
    {id: 1, name: 'Accounts'},
    {id: 2, name: 'Custody'},
    {id: 3, name: 'Trading - Crypto'},
    {id: 5, name: 'Trading - FX'},
    {id: 4, name: 'Treasury'},
    {id: 5, name: 'Wealth Partners'},
    {id: 6, name: 'Yield'}
];

export const breakdownFunc = (breakdown) => {
    return Object.keys(breakdown).map(key => {

        return {
            key: key.replace(/_/g, ' '),
            data: breakdown[key]
        };

    });
};

export const MaterialDateFormat = {
    parse: {
        dateInput: 'YYYY/MM/DD',
    },
    display: {
        dateInput: 'YYYY/MM/DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

export const mapQuestion = (questionObj) => {
    return Object.keys(questionObj).map(key => {
        const question = key;
        if (key === 'country') {
            return {
                question,
                answer: this.country.countryName(questionObj[key]) // this.countries.find(country => country.code === questionObj[key]).name || ''
            };
        } else if (key === 'What countries are you expect to send funds to or receive funds from?') {
            return {
                question,
                answer: (questionObj[key]).map(country => country.item_text || country) // NO Need for this but they was an bugfix, so it's for backward
            };
        } else {
            return {
                question,
                answer: questionObj[key]
            };
        }

    });

};

export const onboardingQuestionMap = (questionObj) => {
    return Object.keys(questionObj).map(key => {
        const question = key.replace(/_/g, ' ');
        switch (key) {
            case 'country':
                return {
                    question,
                    answer: countries.find(country => country.code === questionObj[key])
                };
            case 'countries_to_send_or_receive_funds':
                return {
                    question,
                    answer: (questionObj[key]).map(country => country.item_text)
                };
            case 'country_of_operation':
                return {
                    question,
                    answer: (questionObj[key]).map(country => country.name)
                };
            default:
                return {
                    question,
                    answer: questionObj[key]
                };
        }
    });
};

export function conditionalValidator(predicate: () => boolean, validator: ValidatorFn, errorNamespace?: string): ValidatorFn {
    return (formControl => {
        if (!formControl.parent) {
            return null;
        }
        let error = null;
        if (predicate()) {
            error = validator(formControl);
        }
        if (errorNamespace && error) {
            const customError = {};
            customError[errorNamespace] = error;
            error = customError;
        }
        return error;
    });
}
