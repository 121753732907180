<div class="modal-header">
    <h4 class="modal-title">Link Entity</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="input-group mb-3">
        <input #searchInput type="text" class="form-control" placeholder="Type CID, email, name, last name..." aria-label="Search"
            aria-describedby="basic-addon2">
        <div class="input-group-append">
            <button class="btn btn-info btn-sm" type="button" (click)="onSearch()" [disabled]="(isLoading$ | async)">{{ (isLoading$ | async) ? 'Loading...' : 'Search' }}</button>
        </div>
    </div>
    <div class="kt-portlet kt-portlet--tabs kt-portlet--height-fluid" *ngIf="searched$ | async">
        <div class="kt-portlet__head px-0">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    Search Results
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body px-0">
            <div class="kt-widget4" *ngIf="results$ | async as _results">
                <div class="kt-widget4__item" *ngFor="let result of _results">
                    <div class="kt-widget4__pic kt-widget4__pic--pic">
                        <span class="kt-badge kt-badge--brand kt-badge--lg kt-badge--bold">{{  (result.first_name + ' ' + result.last_name|firstLetter) }}</span>
                    </div>
                    <div class="kt-widget4__info">
                        <a href="#" class="kt-widget4__username">
                            {{ result.first_name }} {{ result.last_name }}
                        </a>
                        <p class="kt-widget4__text">
                            {{result.partners[0].cid}} | {{ result.email }}
                        </p>
                    </div>
                    <a href="javascript:void" (click)="onLinking(cofirmation)" class="btn btn-sm btn-label-brand btn-bold">Link to {{ entity.entity_name }}</a>
                    <ng-template #cofirmation let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title" id="modal-basic-title">Position</h4>
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="positionForm" (validSubmit)="onSubmit(result)">
                                <div class="alert alert-bold"
                                    [ngClass]="{ 'alert-solid-success': !responseStatus.isError, 'alert-solid-danger': responseStatus.isError }"
                                    *ngIf="responseStatus.show" id="thanksalot">
                                    {{ responseStatus.message }}
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Postion</label>
                                    <select formControlName="postion" (change)="onChangesPosition($event.target.value)" class="custom-select">
                                        <option selected value="">Select position</option>
                                        <option value="director">Director</option>
                                        <option value="shareholder">Shareholder</option>
                                        <option value="both">Both</option>
                                    </select>
                                </div>
                                <div class="form-group" *ngIf="positionForm.get('shares')">
                                    <label>Shares</label>
                                    <input formControlName="shares" mask="percent" suffix="%" type="text" class="form-control">
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-dark" [disabled]="isLoadingPostion$ | async">{{ (isLoadingPostion$ | async) ? 'Loading...' : 'Link Entity'}} </button>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                </div>
                <div class="text-center" *ngIf="!_results.length">
                    {{ (isLoading$ | async) ? 'Loading...' : 'No Entity found' }}
                </div>
            </div>

        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">Close</button>
</div>
