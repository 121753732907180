// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// State
import { AuthenticationService } from './../../../../../services/auth.service';

@Component({
    selector: 'kt-user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
    // Public properties
    user$: Observable<any>;

    @Input() avatar = false;
    @Input() greeting = true;
    @Input() badge = true;
    @Input() icon: boolean;

    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     */
    constructor(private authentication: AuthenticationService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.user$ = this.authentication.userProfile$;
        this.user$.subscribe(user => {
            if (user && user.picture) {
                this.avatar = true;
                this.badge = false;
            } else {
                this.badge = true;
            }
        });
    }

    /**
     * Log out
     */
    logout(): void {
        this.authentication.logout();
    }
}
