import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Update } from '@ngrx/entity'
import { Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { ModulrAccount } from '../views/pages/accounts/accounts-list/accounts-list.component'
import { EntityUser } from '../views/pages/entities/components/entity-users/interfaces/entity-user.interface'
import { EntityWalletsSearchResults } from '../interface/entity-wallets-search-results.interface'
import { EntityWallets } from '../interface/entity-wallets.interface'
import { WelcomePackRequest } from '../interface/welcome-pack-request'
import { AccountQueryParams } from '../models/account-query'
import { Accounts } from '../models/accounts'
import { Beneficiary } from '../models/beneficiary'
import { IDashboard } from '../models/dashboard'
import { Entity, EntityResponse } from '../models/entity'
import { EntityProfileModel } from '../models/entity-profile'
import { GetReport, KycReport } from '../models/kyc-report'
import { PartnerSetting } from '../models/partner-settings'
import { Partner } from '../models/partners'
import { WalletStatus } from '../views/pages/entities/components/entity-accounts/entity-accounts.component'
import { QueryParamsModel } from './../core/_base/crud/models/query-models/query-params.model'
import { QueryResultsModel } from './../core/_base/crud/models/query-models/query-results.model'
import { HttpUtilsService } from './../core/_base/crud/utils/http-utils.service'
import { AddressModel } from './../models/address'
import { BankAccountModel } from './../models/bank-account'
import { NoteModel } from './../models/note'
import { SystemUser } from './../models/system-user'
import { EntityUsersSearchResults } from '../views/pages/entities/components/entity-users/interfaces/entity-users-search-results.interface'
import { IndividualSearchResults } from '../views/pages/entities/components/identity-verification/interfaces/individual-search-results.interface'
import { Individual } from '../views/pages/entities/components/identity-verification/interfaces/individual.interface'
import { AttemptsResponse } from '../views/pages/entities/components/identity-verification/interfaces/attempts-response.interface'
import { RequestCodeResponse } from '../views/pages/entities/components/identity-verification/interfaces/request-code-response.interface'
import { EntityBankAccountsSearchResults } from '../interface/entity-bank-accounts-search-results.interface'
import { EntityBankAccount } from '../interface/entity-bank-account.interface'
import { ReportResponseModel } from '../views/pages/entities/components/kyc-reports/models/report-response.model'
import { OnboardingReview } from '../views/pages/entities/review/interface/onboarding-review.interface'
import { DefaultGeneralFees } from '../models/default-general-fees.dto'
import { EntityFeesGeneralRequest } from '../views/partials/layout/add-corporate-form-modal/utils/entity-creation-service/general-fees-request.dto'
import { PaymentRailConfig } from '../views/partials/layout/add-corporate-form-modal/transaction-fees-component/utils/payment-rail-config.dto'
import { PaymentRailDefaultFees } from '../views/partials/layout/add-corporate-form-modal/transaction-fees-component/utils/payment-rail-default.dto'
import { PaymentRailContractFees } from '../views/partials/layout/add-corporate-form-modal/transaction-fees-component/utils/payment-rail-contract.dto'
import { ActivateIncoreAccount } from '../interface/activate-incore-account.interface'
import { AddEntityContractTransactionFeesResponse, EntityFeesDto } from '../views/pages/fees-schedule/models'
import { ConfigQuestion } from '../interface/config-question.interface'
import { ActivateBankingCircleAccount } from '../interface/activate-banking-circle-account.interface'
import { EmployeeContact } from '../views/pages/entities/components/welcome-pack-modal/interfaces/employee-contact.dto'
import { AccountGroup } from '../models/account-group'
import { AccountLegalEntity } from '../models/account-legal-entity'
import { Accounts as LegacyAccounts } from 'src/app/models/legacy-accounts'

@Injectable()
export class RestApiService {
	private headers = new HttpHeaders().set('Content-Type', 'application/json')

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private router: Router) {}

	getEntities(query): Observable<EntityResponse> {
		return this.http.get<EntityResponse>(`${environment.apiUrl}/entities`, {
			params: query,
			headers: this.headers
		})
	}

	getDashboardData(query): Observable<IDashboard[]> {
		return this.http.get<IDashboard[]>(`${environment.apiUrl}/entities/dashboard`, {
			params: query,
			headers: this.headers
		})
	}

	searchEntityLink(query): Observable<Entity[]> {
		return this.http.get<Entity[]>(`${environment.apiUrl}/entities/search`, {
			params: query,
			headers: this.headers
		})
	}

	getCorporateEntities(query): Observable<EntityResponse> {
		return this.http.get<EntityResponse>(`${environment.apiUrl}/entities/corporates`, {
			params: query,
			headers: this.headers
		})
	}

	getIndividualsEntities(query): Observable<EntityResponse> {
		return this.http.get<EntityResponse>(`${environment.apiUrl}/entities/individuals`, {
			params: query,
			headers: this.headers
		})
	}

	approveEntity(id) {
		return this.http.get<EntityResponse>(`${environment.apiUrl}/entities/approve/${id}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	rejectEntity(id) {
		return this.http.get<EntityResponse>(`${environment.apiUrl}/entities/reject/${id}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	getSubEntities(entityId, flags?: Array<string>): Observable<Entity[]> {
		return this.http.get<any>(`${environment.apiUrl}/entities/sub-entities/${entityId}`, {
			observe: 'body',
			responseType: 'json',
			params: {
				flags
			}
		})
	}

	getSubCorporateEntities(entityId): Observable<Entity[]> {
		return this.http.get<any>(`${environment.apiUrl}/entities/sub-corporate-entities/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	getSubIndivdualEntities(entityId): Observable<Entity[]> {
		return this.http.get<any>(`${environment.apiUrl}/entities/sub-individual-entities/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	getOnboardinglink(entityId): Observable<Entity[]> {
		return this.http.get<any>(`${environment.apiUrl}/entities/onboarding-link/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	sendWelcomePack(data: WelcomePackRequest): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/entities/welcome-pack/send`, data)
	}

	addEntity(entity: Entity): Observable<Entity> {
		return this.http.post<Entity>(`${environment.apiUrl}/entities`, entity)
	}

	archiveSubEntity(entityId: string): Observable<Entity> {
		return this.http.put<Entity>(`${environment.apiUrl}/entities/archive/${entityId}`, undefined)
	}

	reinstateSubEntity(entityId: string): Observable<Entity> {
		return this.http.put<Entity>(`${environment.apiUrl}/entities/reinstate/${entityId}`, undefined)
	}

	deleteEntity(entityId: string): Observable<any> {
		return this.http.delete<Entity>(`${environment.apiUrl}/entities/soft/${entityId}`)
	}

	createSubIndividualEntity(entity): Observable<Entity> {
		return this.http.post<Entity>(`${environment.apiUrl}/entities/sub-individual`, entity)
	}

	linkEntity(value: { entity_id: string; individual_id: string }): Observable<Entity> {
		return this.http.post<Entity>(`${environment.apiUrl}/entities/link-entity`, value)
	}

	getEntity(entityId: string): Observable<Entity> {
		return this.http.get<Entity>(`${environment.apiUrl}/entities/${entityId}`)
	}

	updateEntity(entity: Entity): Observable<Entity> {
		return this.http.put<Entity>(`${environment.apiUrl}/entities/${entity.id}`, entity)
	}

	updateEntitySettings(entity: Entity): Observable<Entity> {
		return this.http.put<Entity>(`${environment.apiUrl}/entities/settings/${entity.id}`, entity)
	}

	addEntityIndividualDocuments(documents): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/documents/${documents.entity_id}`, documents)
	}

	getEntityIndividualDocuments(entityId): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/documents/entity/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	updateIndividualProfile(profile: EntityProfileModel): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/entities/individual/${profile.entity_id}`, profile)
	}

	getTestAccount(entityId): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/entities/corporate/test-account/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	addTestAccount(entityId: string): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/entities/corporate/test-account`, { entityId })
	}

	updateCorporateProfile(profile: EntityProfileModel): Observable<any> {
		return this.http.put<Entity>(`${environment.apiUrl}/entities/corporate/${profile.entity_id}`, profile)
	}

	getEntityProfile(entityId: string): Observable<EntityProfileModel> {
		return this.http.get<EntityProfileModel>(`${environment.apiUrl}/entities/profile/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	getCorporateProfile(entityId: string): Observable<EntityProfileModel> {
		return this.http.get<EntityProfileModel>(`${environment.apiUrl}/entities/profile/corporate/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	getSubEntityProfile(entityId: string): Observable<EntityProfileModel> {
		return this.http.get<EntityProfileModel>(`${environment.apiUrl}/entities/sub-profile/${entityId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	addNote(note: NoteModel): Observable<NoteModel> {
		return this.http.post<NoteModel>(`${environment.apiUrl}/notes`, note)
	}

	getNotes(entityId: string): Observable<NoteModel[]> {
		return this.http.get<NoteModel[]>(`${environment.apiUrl}/notes/${entityId}`, {
			headers: this.headers
		})
	}

	getEntityUsers(entityId: string, pageNumber: number, pageSize: number): Observable<EntityUsersSearchResults> {
		return this.http.get<EntityUsersSearchResults>(`${environment.apiUrl}/entity/${entityId}/users?pageSize=${pageSize}&pageNumber=${pageNumber}`, {
			headers: this.headers
		})
	}

	getEntityUser(entityId: string, userId: string): Observable<EntityUser> {
		return this.http.get<EntityUser>(`${environment.apiUrl}/entity/${entityId}/users/${userId}`, {
			headers: this.headers
		})
	}

	addEntityUser(entityId: string, entityUser: EntityUser): Observable<EntityUser> {
		return this.http.post<EntityUser>(`${environment.apiUrl}/entity/${entityId}/users`, entityUser)
	}

	updateEntityUser(entityId: string, entityUser: EntityUser): Observable<EntityUser> {
		return this.http.put<EntityUser>(`${environment.apiUrl}/entity/${entityId}/users`, entityUser)
	}

	getSystemUsers(): Observable<SystemUser[]> {
		return this.http.get<SystemUser[]>(`${environment.apiUrl}/systems/users`, {
			headers: this.headers
		})
	}

	getSystemUser(systemUserId: string): Observable<SystemUser> {
		return this.http.get<SystemUser>(`${environment.apiUrl}/systems/users/${systemUserId}`, {
			headers: this.headers
		})
	}

	addSystemUser(systemUser: SystemUser): Observable<SystemUser> {
		return this.http.post<SystemUser>(`${environment.apiUrl}/systems/users/`, systemUser)
	}

	updateSystemUser(update: Update<SystemUser>): Observable<SystemUser> {
		return this.http.put<SystemUser>(`${environment.apiUrl}/systems/users/${update.id}`, update.changes)
	}

	getOnfidoLastCheckByEntityId(entityId: string) {
		return this.http.get<any>(`${environment.apiUrl}/entities/onfido/check/${entityId}`)
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	findSystemUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// This code imitates server calls
		return this.getSystemUsers().pipe(
			mergeMap((response: SystemUser[]) => {
				const result = this.httpUtils.baseFilter(response, queryParams, [])
				return of(result)
			})
		)
	}

	validateSystemUsersCreatePassword(query) {
		return this.http.get<SystemUser>(`${environment.apiUrl}/systems/validate-token`, {
			params: query,
			headers: this.headers
		})
	}

	validateSystemUser() {
		return this.http.get<SystemUser>(`${environment.authUrl}/admin/validate`)
	}

	systemUserCreatePassword(data: { password_token: string; id: string; password: string }): Observable<SystemUser> {
		return this.http.post<SystemUser>(`${environment.apiUrl}/systems/create-password/`, data)
	}

	disableSystemUser(userId: string): Observable<SystemUser> {
		return this.http.get<SystemUser>(`${environment.apiUrl}/systems/activate-suspend-user/${userId}`)
	}

	deleteSystemUser(userId: string): Observable<SystemUser> {
		return this.http.delete<SystemUser>(`${environment.apiUrl}/systems/users/${userId}`)
	}

	findAccounts(entityId: string, queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// This code imitates server calls
		return this.getAllEntitiesAccount(entityId).pipe(
			mergeMap((response: Accounts[]) => {
				const result = this.httpUtils.baseFilter(response, queryParams, [])
				return of(result)
			})
		)
	}

	getOmnibusAccounts(source?: string): Observable<LegacyAccounts[]> {
		const params: HttpParams = new HttpParams().set('source', source)
		return this.http.get<LegacyAccounts[]>(`${environment.apiUrl}/accounts/designation/omnibus`, { params })
	}

	getAccountGroups(): Observable<AccountGroup[]> {
		return this.http.get<AccountGroup[]>(`${environment.apiUrl}/account-groups`)
	}

	getAccountLegalEntities(): Observable<AccountLegalEntity[]> {
		return this.http.get<AccountLegalEntity[]>(`${environment.apiUrl}/legal-entities`)
	}

	addAccounts(data: any): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/accounts/${data.entity_partner_id}`, data)
	}

	enableCamt053(accountId: number): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/accounts/camt053/${accountId}/enable`, {})
	}

	disableCamt053(accountId: number): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/accounts/camt053/${accountId}/disable`)
	}
	getCamt053(accountId: number): Observable<boolean> {
		return this.http.get<boolean>(`${environment.apiUrl}/accounts/camt053/${accountId}`, {})
	}

	getAccountById(query: AccountQueryParams) {
		return this.http.get<any>(`${environment.apiUrl}/accounts/entity/${query.entityId}/account/${query.accountId}`)
	}

	getAllEntitiesAccount(entityId: string) {
		return this.http.get<any>(`${environment.apiUrl}/accounts/${entityId}`)
	}

	activateBankingCircleAccount(payload: ActivateBankingCircleAccount): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/accounts/activate-banking-circle-account`, payload)
	}

	activateIncoreAccount(payload: ActivateIncoreAccount): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/accounts/activate-incore-account`, payload)
	}

	updateBankDetails(bankDetails: any) {
		return this.http.put<any>(`${environment.apiUrl}/accounts/bank/${bankDetails.account_id}`, bankDetails)
	}

	updateEntityBankAccount(bankAccount: BankAccountModel) {
		return this.http.put<any>(`${environment.apiUrl}/entities/bank-account/${bankAccount.entity_id}`, bankAccount)
	}

	updateEntityAddress(entityAddress: AddressModel) {
		return this.http.put<any>(`${environment.apiUrl}/entities/address/${entityAddress.entity_id}`, entityAddress)
	}

	getAllPartners(): Observable<Partner[]> {
		return this.http.get<any>(`${environment.apiUrl}/partners`)
	}

	getPartner(id: string): Observable<Partner> {
		return this.http.get<any>(`${environment.apiUrl}/partners/${id}`)
	}

	getPartnerSetting(id: string): Observable<PartnerSetting> {
		return this.http.get<any>(`${environment.apiUrl}/partners/settings/${id}`)
	}

	updatePartner(partner: Partner): Observable<Partner> {
		return this.http.put<any>(`${environment.apiUrl}/partners/${partner.id}`, partner)
	}

	updatePartnerSetting(partnerSetting: PartnerSetting): Observable<PartnerSetting> {
		return this.http.put<any>(`${environment.apiUrl}/partners/settings/${partnerSetting.partner_id}`, partnerSetting)
	}

	findDocumentGenerateSignedUrl(document): Observable<{ url: string }> {
		return this.http.put<any>(`${environment.apiUrl}/documents/generate/signed/url`, document)
	}

	downloadDocument(document): Observable<any> {
		// @ts-ignore - response type of arraybuffer is supported
		return this.http.post<any>(`${environment.apiUrl}/documents/download`, document, { responseType: 'arraybuffer' })
	}

	//
	// Beneficiaries
	//
	findBeneficiaries(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.getBeneficiaries().pipe(
			mergeMap((response: Beneficiary[]) => {
				const result = this.httpUtils.baseFilter(response, queryParams, [])
				return of(result)
			})
		)
	}

	getEntityPartners(): Observable<Beneficiary[]> {
		return this.http.get<Beneficiary[]>(`${environment.apiUrl}/partners/entity-partners`, {
			headers: this.headers
		})
	}

	getBeneficiaries(): Observable<Beneficiary[]> {
		return this.http.get<Beneficiary[]>(`${environment.apiUrl}/beneficiaries`, {
			headers: this.headers
		})
	}

	getBeneficiary(param: { entityPartnerId: string; beneficiaryId: string }): Observable<Beneficiary> {
		return this.http.get<Beneficiary>(`${environment.apiUrl}/beneficiaries/${param.entityPartnerId}/Beneficiary/${param.beneficiaryId}`, {
			headers: this.headers
		})
	}

	addBeneficiary(beneficiary: Beneficiary): Observable<Beneficiary> {
		return this.http.post<Beneficiary>(`${environment.apiUrl}/beneficiaries/${beneficiary.entity_partner_id}`, beneficiary)
	}

	updateBeneficiary(update: Update<Beneficiary>): Observable<Beneficiary> {
		return this.http.put<Beneficiary>(
			`${environment.apiUrl}/beneficiaries/${update.changes.entity_partner_id}/beneficiary/${update.id}`,
			update.changes
		)
	}

	deleteBeneficiary(param: { entityPartnerId: string; beneficiaryId: string }): Observable<Beneficiary> {
		return this.http.delete<Beneficiary>(`${environment.apiUrl}/beneficiaries/${param.entityPartnerId}/Beneficiary/${param.beneficiaryId}`)
	}

	//
	// KYC REPORTS
	//
	findKycReports(entityId: string, queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		return this.getKycReports(entityId).pipe(
			// @ts-ignore
			mergeMap((response: KycReport[]) => {
				const result = this.httpUtils.baseFilter(response, queryParams, [])
				return of(result)
			})
		)
	}

	getKycReport(onfidoId: string): Observable<GetReport> {
		return this.http.get<GetReport>(`${environment.onfidoUrl}/report/${onfidoId}`, {
			headers: this.headers
		})
	}

	deleteKycReport(param: { entityPartnerId: string; beneficiaryId: string }): Observable<KycReport> {
		return this.http.delete<KycReport>(`${environment.apiUrl}/beneficiaries/${param.entityPartnerId}/KycReport/${param.beneficiaryId}`)
	}

	getKycReports(entityId: string, pageNumber?: number, pageSize?: number): Observable<ReportResponseModel> {
		const params: HttpParams = new HttpParams().set('pageNumber', String(pageNumber)).set('pageSize', String(pageSize))
		return this.http.get<ReportResponseModel>(`${environment.kycSpiderUrl}/reports/${entityId}`, {
			params
		})
	}

	getReportUrl(entityId: string, documentName: string): Observable<{ url: string }> {
		return this.http.post<{ url: string }>(`${environment.kycSpiderUrl}/reports/${entityId}`, {
			documentName
		})
	}

	downloadReportAtUrl(url: string): Observable<any> {
		return this.http.get<any>(`${url}`)
	}

	downloadKycReport(entityId: string, requestId: string): Observable<{ document: string }> {
		return this.http.get<{ document: string }>(`${environment.kycSpiderUrl}/reports/download/${entityId}/${requestId}`)
	}

	requestKycCheck(entityId: string): Observable<{ entitiesToKyc: string | string[] }> {
		return this.http.post<{ entitiesToKyc: string | string[] }>(
			`${environment.kycSpiderUrl}/check`,
			{
				entity_id: entityId,
				monitor: true
			},
			{ headers: this.headers }
		)
	}

	archiveToggle(entityId: string): Observable<GetReport> {
		return this.http.get<GetReport>(`${environment.apiUrl}/entities/archive/${entityId}`, {
			headers: this.headers
		})
	}

	resendCompletionEmail(entityId: string): Observable<GetReport> {
		return this.http.get<GetReport>(`${environment.apiUrl}/entities/resend/completion-email/${entityId}`, {
			headers: this.headers
		})
	}

	resendOnboardingEmail(entityId: string): Observable<GetReport> {
		return this.http.get<GetReport>(`${environment.apiUrl}/entities/resend/onboarding-email/${entityId}`, {
			headers: this.headers
		})
	}

	getEntityWallets(entityId: string, pageSize: number, pageNumber: number): Observable<EntityWalletsSearchResults> {
		return this.http.get<EntityWalletsSearchResults>(
			`${environment.apiUrl}/accounts/entity/${entityId}/wallets?pageSize=${pageSize}&pageNumber=${pageNumber}`,
			{
				observe: 'body',
				responseType: 'json'
			}
		)
	}

	addEntityWallet(entityId: string, entityWallet: EntityWallets): Observable<EntityWallets> {
		return this.http.post<EntityWallets>(`${environment.apiUrl}/accounts/entity/${entityId}/wallets`, entityWallet)
	}

	updateEntityWalletStatus(entityId: string, entityWallet: EntityWallets, status: WalletStatus): Observable<EntityWallets> {
		return this.http.put<EntityWallets>(`${environment.apiUrl}/accounts/entity/${entityId}/wallets/status`, { entityWallet, status })
	}

	// ------------ Bank Accounts ------------
	getEntityBankAccounts(entityId: string, pageSize: number, pageNumber: number): Observable<EntityBankAccountsSearchResults> {
		return this.http.get<EntityBankAccountsSearchResults>(
			`${environment.apiUrl}/entities/bank-accounts/${entityId}?pageSize=${pageSize}&pageNumber=${pageNumber}`,
			{
				observe: 'body',
				responseType: 'json'
			}
		)
	}

	getEntityBankAccount(entityId: string, accountId: string): Observable<EntityBankAccount> {
		return this.http.get<EntityBankAccount>(`${environment.apiUrl}/entities/bank-account/${entityId}/${accountId}`)
	}

	addEntityBankAccount(entityId: string, bankAccount: EntityBankAccount): Observable<EntityBankAccount> {
		return this.http.post<EntityBankAccount>(`${environment.apiUrl}/entities/bank-account/${entityId}`, bankAccount)
	}

	updateEntityBankAccountStatus(accountId: string, status: string): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/entities/bank-account/${accountId}/status`, { status })
	}

	removeEntityBankAccount(accountId: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/entities/bank-account/${accountId}`)
	}

	// ------------ Modulr ------------
	getModulrAccount(entityId: string): Observable<ModulrAccount> {
		return this.http.get<ModulrAccount>(`${environment.apiUrl}/entities/modulr/${entityId}`)
	}

	createModulrAccount(entityId: string, account: ModulrAccount): Observable<any> {
		return this.http.post(`${environment.apiUrl}/entities/modulr/${entityId}`, account)
	}

	// ------------ ID Verification ------------
	getIdentityVerificationIndividuals(entityId: String, pageSize?: number, pageNumber?: number): Observable<IndividualSearchResults> {
		return this.http.get<IndividualSearchResults>(`${environment.identityVerificationApiUrl}/individuals/${entityId}`, {
			params: { limit: String(pageSize), offset: String(pageNumber) }
		})
	}

	addIdentityVerificationIndividuals(individual: Individual): Observable<Individual> {
		return this.http.post<Individual>(`${environment.identityVerificationApiUrl}/individuals`, individual)
	}

	updateIdentityVerificationIndividuals(individual: Individual): Observable<{ affected: number }> {
		return this.http.put<{ affected: number }>(`${environment.identityVerificationApiUrl}/individuals`, individual)
	}

	deleteIdentityVerificationIndividuals(individual: Individual): Observable<{ affected: number }> {
		return this.http.delete<{ affected: number }>(`${environment.identityVerificationApiUrl}/individuals`, {
			params: { entity_id: individual.entityId, email: individual.email }
		})
	}

	getIdentityVerificationIndividualAttempts(individualId: string): Observable<AttemptsResponse[]> {
		return this.http.get<AttemptsResponse[]>(`${environment.identityVerificationApiUrl}/individuals/attempts/${individualId}`)
	}

	requestIdentityVerificationIndividualCode(individualId: string): Observable<RequestCodeResponse> {
		return this.http.get<RequestCodeResponse>(`${environment.identityVerificationApiUrl}/individuals/request-code/${individualId}`)
	}

	// ------------ HubSpot ------------
	validateHubSpotDealId(dealId: string): Observable<any> {
		return this.http.get<any>(`${environment.baseUrl}/hubspot/deals/validate/${dealId}`)
	}

	// ------------ Onboarding Review ------------
	getOnboardingReviews(entity_id: string): Observable<Array<OnboardingReview>> {
		return this.http.get<Array<OnboardingReview>>(`${environment.apiUrl}/onboarding-review/${entity_id}`)
	}

	getLatestOnboardingReview(entity_id: string): Observable<OnboardingReview> {
		return this.http.get<OnboardingReview>(`${environment.apiUrl}/onboarding-review/${entity_id}/latest`)
	}

	getActiveOnboardingReview(entity_id: string): Observable<OnboardingReview> {
		return this.http.get<OnboardingReview>(`${environment.apiUrl}/onboarding-review/${entity_id}/active`)
	}

	updateOnboardingReview(entity_id: string, review: OnboardingReview): Observable<OnboardingReview> {
		return this.http.put<OnboardingReview>(`${environment.apiUrl}/onboarding-review/${entity_id}`, { ...review })
	}

	startOnboardingReview(entity_id: string, review: OnboardingReview): Observable<OnboardingReview> {
		return this.http.post<OnboardingReview>(`${environment.apiUrl}/onboarding-review/${entity_id}`, { ...review })
	}

	completeOnboardingReview(entity_id: string, review_id: string, review: OnboardingReview, extraInfo?: string): Observable<OnboardingReview> {
		return this.http.put<OnboardingReview>(`${environment.apiUrl}/onboarding-review/${entity_id}/${review_id}`, { data: review, extraInfo })
	}

	removeOnboardingReview(entity_id: string, review_id: string): Observable<void> {
		return this.http.delete<void>(`${environment.apiUrl}/onboarding-review/${entity_id}/${review_id}`)
	}

	getOnboardingQuestions(): Observable<Record<string, ConfigQuestion>> {
		return this.http.get<Record<string, ConfigQuestion>>(`${environment.baseUrl}/onboarding/config/questions`)
	}

	// ------------ General Fees ------------

	getDefaultFees(): Observable<DefaultGeneralFees[]> {
		return this.http.get<DefaultGeneralFees[]>(`${environment.baseUrl}/fees/general/default`, { observe: 'body', responseType: 'json' })
	}

	addEntityGeneralFees(generalFees: EntityFeesGeneralRequest[]): Observable<string[]> {
		return this.http.post<string[]>(`${environment.baseUrl}/fees/general/contract`, generalFees)
	}

	// ------------ Transaction Fees ------------

	getPaymentRailConfigByCurrency(currency: string): Observable<PaymentRailConfig[]> {
		return this.http.get<PaymentRailConfig[]>(`${environment.baseUrl}/fees/config/${currency}`, { observe: 'body', responseType: 'json' })
	}

	getTransactionalDefaultFees(paymentRailId: string): Observable<PaymentRailDefaultFees[]> {
		return this.http.get<PaymentRailDefaultFees[]>(`${environment.baseUrl}/fees/transactions/default/${paymentRailId}`, {
			observe: 'body',
			responseType: 'json'
		})
	}

	getTransactionalContractFees(entityId: string, currencyCode: string, paymentRailId: string): Observable<PaymentRailDefaultFees[]> {
		return this.http.get<PaymentRailDefaultFees[]>(
			`${environment.baseUrl}/fees/transactions/contract/entity/${entityId}/${currencyCode}/${paymentRailId}`,
			{
				observe: 'body',
				responseType: 'json'
			}
		)
	}

	addTransactionalFees(entityId: string, paymentRailFees: PaymentRailContractFees[]): Observable<AddEntityContractTransactionFeesResponse[]> {
		return this.http.post<AddEntityContractTransactionFeesResponse[]>(
			`${environment.baseUrl}/fees/transactions/contract/entity/${entityId}`,
			paymentRailFees
		)
	}

	getAllEntityContractFees(entityId: string): Observable<EntityFeesDto> {
		return this.http.get<EntityFeesDto>(`${environment.baseUrl}/fees/entity/${entityId}`)
	}

	getAllEmployeeContacts(): Observable<EmployeeContact[]> {
		return this.http.get<EmployeeContact[]>(`${environment.apiUrl}/entities/employee-contacts/all`)
	}
}
