import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
const ɵ0 = () => import("./views/pages/auth/auth.module.ngfactory").then(m => m.AuthModuleNgFactory), ɵ1 = () => import("./views/pages/dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ2 = () => import("./views/pages/entities/entities.module.ngfactory").then(m => m.EntitiesModuleNgFactory), ɵ3 = () => import("./views/pages/system/system.module.ngfactory").then(m => m.SystemModuleNgFactory), ɵ4 = () => import("./views/pages/partners/partners.module.ngfactory").then(m => m.PartnersModuleNgFactory), ɵ5 = {
    type: 'error-v6',
    code: 403,
    title: '403... Access forbidden',
    desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
}, ɵ6 = {
    type: 'error-v3',
    code: 404,
    title: 'Page Not Found',
    desc: 'Page Not Found'
}, ɵ7 = {
    type: 'error-v6',
    code: 401,
    title: '401... Unauthorized',
    desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
};
// Auth
const routes = [
    { path: 'auth', loadChildren: ɵ0 },
    {
        path: '',
        component: BaseComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: ɵ1,
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'entities',
                loadChildren: ɵ2,
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'system',
                loadChildren: ɵ3,
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'partners',
                loadChildren: ɵ4,
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: ɵ5
            },
            {
                path: 'error/404',
                component: ErrorPageComponent,
                data: ɵ6
            },
            {
                path: 'error/401',
                component: ErrorPageComponent,
                data: ɵ7
            },
            { path: 'error/:type', component: ErrorPageComponent },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
        ]
    },
    { path: '**', redirectTo: 'error/403', pathMatch: 'full' },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
