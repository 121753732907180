export class MenuConfig {
    public defaults: any = {
        header: {
            self: {},
            items: [
                // {
                //     title: 'LINK1',
                //     root: true,
                //     alignment: 'left',
                //     page: 'link1',
                //     translate: 'MENU.LINK1',
                // },
                // {
                //     title: 'LINK2',
                //     root: true,
                //     alignment: 'left',
                //     page: 'link2',
                //     translate: 'MENU.LINK2',
                // },
                // {
                //     title: 'LINK3',
                //     root: true,
                //     alignment: 'left',
                //     page: 'link3',
                //     translate: 'MENU.LINK3',
                // },
                // {
                //     title: 'System',
                //     root: true,
                //     alignment: 'left',
                //     toggle: 'click',
                //     translate: 'MENU.SYSTEM',
                //     icon: 'flaticon-interface-7',
                //     submenu: [
                //         {
                //             title: 'System Users',
                //             bullet: 'dot',
                //             page: 'system/users',
                //         }
                //     ]
                // },
            ]
        },
        aside: {
            self: {},
            items: [
                {
                    title: 'Dashboard',
                    root: true,
                    icon: 'fa fa-chart-area',
                    page: '/dashboard',
                    translate: 'MENU.DASHBOARD',
                    bullet: 'dot',
                },
                {
                    title: 'Onboarding',
                    root: true,
                    icon: 'fa fa-folder-open',
                    page: '/entities/onboarding',
                    translate: 'MENU.ONBOARDING',
                    bullet: 'dot',
                },
                {
                    title: 'Corporate Entities',
                    root: true,
                    icon: 'fa fa-building',
                    page: '/entities/corporate',
                    translate: 'MENU.CORPORATE_ENTITIES',
                    bullet: 'dot',
                },
                {
                    title: 'Individual Entities',
                    root: true,
                    icon: 'fa fa-user',
                    page: '/entities/individual',
                    translate: 'MENU.INDIVIDUAL_ENTITIES',
                    bullet: 'dot',
                },
                {section: 'Administration'},
                {
                    title: 'Partners',
                    root: true,
                    bullet: 'dot',
                    page: '/partners',
                    icon: 'fa fa-building',
                    translate: 'MENU.PARTNERS'
                },

                {
                    title: 'System',
                    root: true,
                    bullet: 'dot',
                    icon: 'fa fa-cogs',
                    translate: 'MENU.SYSTEM',
                    submenu: [
                        {
                            title: 'System Users',
                            bullet: 'dot',
                            page: '/system/users',
                            translate: 'MENU.SYSTEM_USERS',
                        },
                    ]
                }
            ]
        },
    };

    public get configs(): any {
        return this.defaults;
    }
}
