import { ValueType } from '../../utils/state/value-type.enum'
import { PaymentRailConfig } from './payment-rail-config.dto'
import { PaymentRailDefaultFees } from './payment-rail-default.dto'

export class PaymentRailContractFees extends PaymentRailDefaultFees {
	currencyCode: string
	accountId: string
	entityId: string
	entityName: string
	effectiveFromDate: Date
	amount: number
	type: ValueType
	direction: string
	paymentRail: PaymentRailConfig
	valid?: boolean
}
