<div class="modal-header">
    <h4 class="modal-title">{{action}} User</h4>
</div>
<div class="modal-body">
    <form [formGroup]="userForm" (validSubmit)="onSubmit()">
        <div class="kt-form kt-form--label-right">
            <div class="modal-body">
                <div class="kt-section kt-section--first">
                    <div class="kt-section__body">

                        <input type="hidden" formControlName="id">
                        <input type="hidden" formControlName="role">

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Full Name</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="text" formControlName="fullName">
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="email" formControlName="email">
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Phone</label>
                            <div class="col-lg-9 col-xl-6">
                                <international-phone-input name="phone" class="form-control" placeholder="Phone"
                                    [preferredCountries]="['gb', 'ch', 'us']" thousandSeparator=" "
                                    formControlName="phone">
                                </international-phone-input>
                                <bfv-messages></bfv-messages>
                                <mat-error *ngIf="userForm.controls['phone']?.errors?.validatePhoneNumber">Invalid
                                    Number
                                </mat-error>
                            </div>
                        </div>

                        <div class="kt-separator kt-separator--space-sm kt-separator--fit kt-separator--border-solid">
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Permissions</label>
                            <div class="col-lg-9 col-xl-6 permissions-container">
                                <mat-checkbox mat-tooltip class="permissions-checkbox"
                                    *ngFor="let permission of permissions" [name]="permission?.fieldName"
                                    [formControlName]="permission?.fieldName" [checked]="permission?.value"
                                    [matTooltip]="permission?.description" (change)="onPermissionChange($event)">
                                    {{permission.name}}</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="onCancel()">Cancel</button>
    <button type="submit" class="btn btn-primary btn-bold nav-tab" (click)="onSubmit()">
        {{action}}</button>
</div>