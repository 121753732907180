<div class="row">
    <div class="col-md-12">
        <div class="kt-portlet fade-in">
            <div class="kt-portlet__body">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Starting from</mat-label>
                            <input matInput [matDatepicker]="picker" value="{{ startDate.format('YYYY-MM-DD') }}" (dateChange)="changeStartDate($event)" >
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field>
                            <mat-label>Until</mat-label>
                            <input matInput [matDatepicker]="picker2" value="{{ endDate.format('YYYY-MM-DD') }}" (dateChange)="changeEndDate($event)">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="kt-portlet">
    <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">

            <div class="col-md-12 col-lg-6 col-xl-2">
                <!--begin::Total Profit-->
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">Invited</h4>
                            <span class="kt-widget24__desc"></span>
                        </div>
                        <span class="kt-widget24__stats kt-font-brand">{{ total$ | async}}</span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-brand" role="progressbar" [style.width.%]="(100 * (total$ | async)) / (total$ | async)"
                             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">{{ (100 * (total$ | async)) / (total$ | async) | number : '1.0-2'}}%</span>
                    </div>
                </div>
                <!--end::Total Profit-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-2">
                <!--begin::New Feedbacks-->
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">Started</h4>
                            <span class="kt-widget24__desc"></span>
                        </div>
                        <span class="kt-widget24__stats kt-font-warning">{{ started$ | async}}</span>
                    </div>

                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-warning" role=" progressbar" [style.width.%]="(100 * (started$ | async)) / (total$ | async)"
                             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">{{ (100 * (started$ | async)) / (total$ | async) | number : '1.0-2' }}%</span>
                    </div>
                </div>
                <!--end::New Feedbacks-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-2">
                <!--begin::New Users-->
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">Completed</h4>
                            <span class="kt-widget24__desc"></span>
                        </div>
                        <span class="kt-widget24__stats kt-font-success">{{ completed$ | async}}</span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-success" role="progressbar" [style.width.%]="(100 * (completed$ | async)) / (total$ | async)"
                             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">{{ (100 * (completed$ | async)) / (total$ | async) | number : '1.0-2' }}%</span>
                    </div>
                </div>
                <!--end::New Users-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-2">
                <!--begin::New Orders-->
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">Not Complete</h4>
                            <span class="kt-widget24__desc"></span>
                        </div>
                        <span class="kt-widget24__stats kt-font-danger">{{ notCompleted$ | async}}</span>
                    </div>

                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-danger" role="progressbar" [style.width.%]="(100 * (notCompleted$ | async)) / (total$ | async)"
                             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">{{ (100 * (notCompleted$ | async)) / (total$ | async) | number : '1.0-2' }}%</span>
                    </div>
                </div>
                <!--end::New Orders-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-2">
                <!--begin::New Orders-->
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">Approved</h4>
                            <span class="kt-widget24__desc"></span>
                        </div>
                        <span class="kt-widget24__stats kt-font-success">{{ approved$ | async}}</span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-success" role="progressbar" [style.width.%]="(100 * (approved$ | async)) / (total$ | async)"
                             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">{{ (100 * (approved$ | async)) / (total$ | async) | number : '1.0-2'}}%</span>
                    </div>
                </div>
                <!--end::New Orders-->
            </div>
            <div class="col-md-12 col-lg-6 col-xl-2">
                <!--begin::New Orders-->
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">Rejected</h4>
                            <span class="kt-widget24__desc"></span>
                        </div>

                        <span class="kt-widget24__stats kt-font-danger">{{ rejected$ | async}}</span>
                    </div>

                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-danger" role="progressbar" [style.width.%]="(100 * (rejected$ | async)) / (total$ | async)"
                             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>

                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change"></span>
                        <span class="kt-widget24__number">{{ (100 * (rejected$ | async)) / (total$ | async)| number : '1.0-2' }}%</span>
                    </div>
                </div>
                <!--end::New Orders-->
            </div>


        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
        <div class="kt-portlet fade-in">
            <div class="kt-portlet__body">
                <canvas #lineCanvas></canvas>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="kt-portlet fade-in">
            <div class="kt-portlet__body">
                <canvas #barCanvas></canvas>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="kt-portlet fade-in">
            <div class="kt-portlet__body">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <!-- Position Column -->
                    <ng-container matColumnDef="entity">
                        <th mat-header-cell *matHeaderCellDef> Entity</th>
                        <td mat-cell
                            *matCellDef="let entity"> {{entity.entity_type === 'corporate' ? entity.entity_entity_name : entity.entity_first_name + ' ' + entity.entity_last_name}}</td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="contact">
                        <th mat-header-cell *matHeaderCellDef> Contact</th>
                        <td mat-cell *matCellDef="let entity">
                            {{ entity.entity_first_name + ' ' + entity.entity_last_name}}<br/>
                            <a href="mailto:{{entity.entity_email}}">{{entity.entity_email}}</a><br/>
                            {{entity.entity_mobile}}
                        </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="invited">
                        <th mat-header-cell *matHeaderCellDef> Invited</th>
                        <td mat-cell *matCellDef="let entity"> {{entity.entity_created_at | date:'d MMM, y'}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="started">
                        <th mat-header-cell *matHeaderCellDef> Started</th>
                        <td mat-cell *matCellDef="let entity"> {{entity.entity_started | date:'d MMM, y'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="completed">
                        <th mat-header-cell *matHeaderCellDef> Completed</th>
                        <td mat-cell *matCellDef="let entity"> {{entity.entity_completed | date:'d MMM, y'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status</th>
                        <td mat-cell *matCellDef="let entity">{{ entity | dashboardStatus }}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions</th>
                        <td mat-cell *matCellDef="let entity">
                            <a routerLink="/entities/{{entity.entity_type}}/{{entity.entity_id}}/profile" class="btn btn-primary btn-sm">View</a>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

            </div>
        </div>

    </div>
</div>
