/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-link-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./show-link-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_ShowLinkModalComponent = [i0.styles];
var RenderType_ShowLinkModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowLinkModalComponent, data: {} });
export { RenderType_ShowLinkModalComponent as RenderType_ShowLinkModalComponent };
function View_ShowLinkModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-bold"], ["id", "thanksalot"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "alert-solid-success": 0, "alert-solid-danger": 1 }), (_l()(), i1.ɵted(4, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert alert-bold"; var currVal_1 = _ck(_v, 3, 0, !_co.responseStatus.isError, _co.responseStatus.isError); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.responseStatus.message; _ck(_v, 4, 0, currVal_2); }); }
function View_ShowLinkModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "onboarding-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading...\n"]))], null, null); }
function View_ShowLinkModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "onboarding-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onboardingLink; _ck(_v, 1, 0, currVal_0); }); }
function View_ShowLinkModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "btn btn-bold btn-info"], ["target", "_blank"], ["type", "button"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to onboarding"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onboardingLink; _ck(_v, 0, 0, currVal_0); }); }
export function View_ShowLinkModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowLinkModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowLinkModalComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowLinkModalComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowLinkModalComponent_4)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.responseStatus.show; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.onboardingLink; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.onboardingLink; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.onboardingLink; _ck(_v, 14, 0, currVal_3); }, null); }
export function View_ShowLinkModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-show-link-modal", [], null, null, null, View_ShowLinkModalComponent_0, RenderType_ShowLinkModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShowLinkModalComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowLinkModalComponentNgFactory = i1.ɵccf("kt-show-link-modal", i3.ShowLinkModalComponent, View_ShowLinkModalComponent_Host_0, {}, {}, []);
export { ShowLinkModalComponentNgFactory as ShowLinkModalComponentNgFactory };
