<form [formGroup]="addIndividualForm" (validSubmit)="onSubmit()">
    <div class="modal-header">
        <h4 class="modal-title">Add Individual {{ entity ? "to " + entity.entity_name : "" }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="kt-form kt-form--label-right">
            <div class="kt-form__body">
                <div
                    class="alert alert-bold"
                    [ngClass]="{ 'alert-solid-success': !responseStatus.isError, 'alert-solid-danger': responseStatus.isError }"
                    *ngIf="responseStatus.show"
                    id="thanksalot"
                >
                    {{ responseStatus.message }}
                </div>
                <div class="kt-section kt-section--first">
                    <div class="kt-section__body">
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">First Name</label>
                            <div class="col-lg-9 col-xl-6">
                                <input
                                    class="form-control"
                                    [ngClass]="{
                                        'is-invalid':
                                            addIndividualForm.get('first_name').invalid &&
                                            (addIndividualForm.get('first_name').dirty || addIndividualForm.get('first_name').touched),
                                        'is-valid': addIndividualForm.get('first_name').valid,
                                        '': false
                                    }"
                                    type="text"
                                    formControlName="first_name"
                                    placeholder="First Name"
                                />
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Middle Name(s)</label>
                            <div class="col-lg-9 col-xl-6">
                                <input
                                    class="form-control"
                                    type="text"
                                    [ngClass]="{
                                        'is-invalid':
                                            addIndividualForm.get('middle_names').invalid &&
                                            (addIndividualForm.get('middle_names').dirty || addIndividualForm.get('middle_names').touched),
                                        'is-valid': addIndividualForm.get('middle_names').valid && addIndividualForm.get('middle_names').touched,
                                        '': false
                                    }"
                                    formControlName="middle_names"
                                    placeholder="Middle Name"
                                />
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Last Name</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="text" formControlName="last_name" placeholder="Last Name" />
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="entity">
                            <label for="typeStatus" class="col-xl-3 col-lg-3 col-form-label">Type/Status:</label>
                            <div class="col-lg-9 col-xl-6">
                                <select class="form-control" formControlName="title">
                                    <option value=""> - select -</option>
                                    <option value="Benefit Owner">Benefit Owner</option>
                                    <option value="Director">Director</option>
                                    <option value="Benefit Owner & Director">Benefit Owner & Director</option>
                                </select>
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="entity">
                            <label class="col-xl-3 col-lg-3 col-form-label">% Shares</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="text" mask="percent" suffix="%" formControlName="shares" placeholder="00%" />
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Mobile</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="input-group">
                                    <international-phone-input
                                        name="mobile"
                                        class="form-control"
                                        placeholder="Phone"
                                        [preferredCountries]="['gb', 'ch', 'us']"
                                        thousandSeparator=" "
                                        formControlName="mobile"
                                    >
                                    </international-phone-input>
                                </div>
                                <bfv-messages></bfv-messages>
                                <mat-error *ngIf="addIndividualForm.controls['mobile']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
                            <div class="col-lg-9 col-xl-6">
                                <div class="input-group">
                                    <input type="email" class="form-control" formControlName="email" placeholder="Email" aria-describedby="basic-addon1" />
                                </div>
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Notes</label>
                            <div class="col-lg-9 col-xl-6">
                                <textarea
                                    class="form-control"
                                    rows="5"
                                    [ngClass]="{
                                        'is-invalid':
                                            addIndividualForm.get('note').invalid &&
                                            (addIndividualForm.get('note').dirty || addIndividualForm.get('note').touched),
                                        'is-valid': addIndividualForm.get('note').valid,
                                        '': false
                                    }"
                                    type="text"
                                    formControlName="note"
                                ></textarea>
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>

                        <div class="row" *ngIf="entity">
                            <div class="col-md-10 offset-md-1 mb-4">
                                <h5>Verification Documents</h5>
                                <hr />
                            </div>
                        </div>
                        <div formGroupName="documents" *ngIf="entity">
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label" for="national_identity_card">ID Document</label>
                                <div class="col-lg-9 col-xl-6">
                                    <div class=" custom-file">
                                        <input
                                            type="file"
                                            class="custom-file-input"
                                            (change)="onFileChange($event)"
                                            formControlName="national_identity_card"
                                            id="national_identity_card"
                                        />
                                        <label class="custom-file-label text-left" for="national_identity_card">{{ filename.national_identity_card }}</label>
                                    </div>
                                    <bfv-messages></bfv-messages>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-lg-3 col-form-label" for="proofOAddressDocument">Proof of Address</label>
                                <div class="col-lg-9 col-xl-6">
                                    <div class="custom-file">
                                        <input
                                            type="file"
                                            class="custom-file-input"
                                            (change)="onFileChange($event)"
                                            formControlName="proofOAddressDocument"
                                            id="proofOAddressDocument"
                                        />
                                        <label class="custom-file-label text-left" for="proofOAddressDocument">{{ filename.proofOAddressDocument }}</label>
                                    </div>
                                    <bfv-messages></bfv-messages>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
        <button type="button" class="btn btn-outline-primary" (click)="this.isManualCapture = true; onSubmit()" *ngIf="!isLoading">Manually Onboard</button>
        <button type="submit" class="btn btn-primary" (click)="this.isManualCapture = undefined" *ngIf="!isLoading">Save Changes</button>
        <button *ngIf="isLoading" type="button" class="btn btn-primary" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </button>
    </div>
</form>
