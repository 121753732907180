import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RestApiService } from '../../../services/rest-api.service';
import { IDashboard } from '../../../models/dashboard';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import moment from "moment";

@Component({
    selector: 'kt-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
    @ViewChild('barCanvas', {static: true}) barCanvas;
    @ViewChild('lineCanvas', {static: true}) lineCanvas;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    private barChart: Chart;
    private lineChart: Chart;

    title: 'Dashboard';
    displayedColumns: string[] = ['entity', 'contact', 'invited', 'started', 'completed', 'status', 'actions'];
    dataSource = new MatTableDataSource<IDashboard>();

    total$: BehaviorSubject<number> = new BehaviorSubject(0);
    started$: BehaviorSubject<number> = new BehaviorSubject(0);
    completed$: BehaviorSubject<number> = new BehaviorSubject(0);
    notCompleted$: BehaviorSubject<number> = new BehaviorSubject(0);
    approved$: BehaviorSubject<number> = new BehaviorSubject(0);
    rejected$: BehaviorSubject<number> = new BehaviorSubject(0);
    status$: BehaviorSubject<string> = new BehaviorSubject('');

    startDate;
    endDate;
    dateRange;

    constructor(private restApiService: RestApiService) {
        this.endDate = moment();
        this.startDate = moment().subtract(7, 'day');
        this.dateRange = this._getRangeOfDates(this.startDate, this.endDate);
    }

    ngOnInit() {
        this.loadData();
    }

    changeStartDate(event) {
        this.startDate = moment(event.value);
        this.dateRange = this._getRangeOfDates(this.startDate, this.endDate);
        this.loadData();
    }

    changeEndDate(event) {
        this.endDate = moment(event.value);
        this.dateRange = this._getRangeOfDates(this.startDate, this.endDate);
        this.loadData();
    }


    loadData() {
        let formattedData;

        this.restApiService.getDashboardData({
            start_date: this.startDate.format('YYYY-MM-DD'),
            end_date: this.endDate.format('YYYY-MM-DD')
        }).subscribe(dashboardData => {

            formattedData = this._formatData(dashboardData);

            this.total$.next(formattedData.total);
            this.started$.next(formattedData.started);
            this.completed$.next(formattedData.completed);
            this.notCompleted$.next(formattedData.notCompleted);
            this.approved$.next(formattedData.approved);
            this.rejected$.next(formattedData.rejected);

            this.dataSource.data = dashboardData;
            this.dataSource.paginator = this.paginator;

            this.barChartInit([
                dashboardData.length, formattedData.started, formattedData.completed,
                formattedData.notCompleted, formattedData.approved, formattedData.rejected
            ]);

            this.lineChartInit(formattedData);
        });
    }

    barChartInit(data: number[]) {
        this.barChart = new Chart(this.barCanvas.nativeElement, {
            type: 'horizontalBar',
            data: {
                labels: ['Invited', 'Started', 'Completed', 'Not Complete', 'Approved', 'Rejected'],
                datasets: [{
                    label: '',
                    backgroundColor: ['#609acd', '#c16161', '#70d97f', '#C192D9', '#D9D652', '#D98F35'],
                    data
                }]
            },
            options: {
                legend: {
                    display: false
                },
                responsive: true,
                title: {
                    display: true,
                    text: 'Invited vs Started vs Completed'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    xAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            userCallback: function (label, index, labels) {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                    return label;
                                }
                            },
                        }
                    }],
                    yAxes: [{
                        display: true,
                    }]
                }
            }
        });
    }

    lineChartInit(formattedData) {
        this.lineChart = new Chart(this.lineCanvas.nativeElement, {
            type: 'line',
            data: {
                labels: this.dateRange.map((date) => date.format('DD/MM')),
                datasets: [{
                    label: 'Invited',
                    backgroundColor: '#609acd',
                    borderColor: '#609ACD',
                    data: formattedData.data.invited,
                    fill: false,
                }, {
                    label: 'Started',
                    backgroundColor: '#c16161',
                    borderColor: '#c16161',
                    fill: false,
                    data: formattedData.data.started,
                }, {
                    label: 'Completed',
                    fill: false,
                    backgroundColor: '#70d97f',
                    borderColor: '#70D97F',
                    data: formattedData.data.completed,
                }]
            },
            options: {
                responsive: true,
                title: {
                    display: true,
                    text: 'Invited vs Started vs Completed'
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    xAxes: [{
                        display: true,
                    }],
                    yAxes: [{
                        display: true,
                    }]
                }
            }
        });
    }

    _formatData(dashboardData){

        const approved = dashboardData.filter(data => data.entity_approved).length;
        const completed = dashboardData.filter(data => data.entity_completed).length;
        const rejected = dashboardData.filter(data => data.entity_status === 'rejected').length;
        const started = dashboardData.filter(data => data.entity_started).length;
        const total = dashboardData.length;
        const notCompleted = started - completed;

        let formattedData = {
            approved: approved,
            completed: completed,
            notCompleted: notCompleted,
            rejected: rejected,
            started: started,
            total: total, // invited
            data: {
                invited: [],
                completed: [],
                started: [],
            }
        };

        for(let date of this.dateRange){
            // formattedData
            let invited     = 0;
            let completed   = 0;
            let started     = 0;

            dashboardData.filter(
                (data) => {
                    if (moment(data.entity_created_at).isSame(date, 'date')) {
                        invited++;
                    }
                    if (moment(data.entity_completed).isSame(date, 'date')) {
                        completed++;
                    }
                    if (moment(data.entity_started).isSame(date, 'date')) {
                        started++;
                    }
                }
            );

            formattedData.data.invited.push(invited);
            formattedData.data.completed.push(completed);
            formattedData.data.started.push(started);
        }

        return formattedData;
    }

    /**
     * @param {moment} start The start date
     * @param {moment} end The end date
     * @param {string} type The range type. eg: 'days', 'hours' etc
     */
    _getRangeOfDates(startDate, endDate, type = 'days') {
        let diff = endDate.diff(startDate, type);
        let range = [];
        for (let i = 0; i < diff; i++) {
         range.push(startDate.clone().add(i, 'd'));
        }
        return range;
     }
}
