import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api.service';
import { EntityProfileActions, EntityProfilesActionTypes, GetEntityProfileAction } from '../actions/entity-profiles';


@Injectable()
export class EntityProfileEffects {


    // @Effect()
    // getEntity$: Observable<Action> = this.actions$
    //     .pipe(ofType(EntityProfilesActionTypes.GET_PROFILE),
    //         mergeMap(() =>
    //             this.restApiService.getEntities()
    //                 .pipe(
    //                     map((entities) => {
    //                         const newEntities = entities.map(entity => ({...entity, documents: []}));
    //                         return {
    //                             type: EntityProfilesActionTypes.LOAD_ONBOARDING_ENTITIES,
    //                             payload: newEntities
    //                         };
    //                     }),
    //                     catchError(error => of({
    //                         type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
    //                         payload: error.error
    //                     })))
    //         ));

    @Effect()
    getEntityProfile$: Observable<Action> = this.actions$
        .pipe(ofType(EntityProfilesActionTypes.GET_PROFILE),
            map((action: GetEntityProfileAction) => action.payload),
            switchMap(payload => {
                return this.restApiService.getEntityProfile(payload)
                .pipe(
                    map(entity => ({
                        type: EntityProfilesActionTypes.ADD_PROFILE,
                        payload: entity
                    })),
                    catchError(error => of({
                        type: EntityProfilesActionTypes.ADD_PROFILE_FAILURE,
                        payload: error.error
                    }))
                );
                }));

    @Effect()
    getSubEntityProfile$: Observable<Action> = this.actions$
        .pipe(ofType(EntityProfilesActionTypes.GET_SUB_PROFILE),
            map((action: GetEntityProfileAction) => action.payload),
            switchMap(payload => {
                return this.restApiService.getEntityProfile(payload)
                    .pipe(
                        map(entity => ({
                            type: EntityProfilesActionTypes.ADD_PROFILE,
                            payload: entity
                        })),
                        catchError(error => of({
                            type: EntityProfilesActionTypes.ADD_PROFILE_FAILURE,
                            payload: error.error
                        }))
                    );
            }));
    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private router: Router,
    ) { }
}
