import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SystemsActionTypes, SystemUsersPageLoadedAction } from '../actions/systems';
export class SystemsEffects {
    constructor(actions$, restApiService, router) {
        this.actions$ = actions$;
        this.restApiService = restApiService;
        this.router = router;
        // showPageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: true });
        // hidePageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: false });
        // showActionLoadingDistpatcher = new UsersActionToggleLoading({ isLoading: true });
        // hideActionLoadingDistpatcher = new UsersActionToggleLoading({ isLoading: false });
        this.getSystemUsers$ = this.actions$
            .pipe(ofType(SystemsActionTypes.GET_SYSTEM_USERS), mergeMap(({ payload }) => {
            // this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.restApiService.findSystemUsers(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin([requestToServer, lastQuery]);
        }), map(response => {
            const result = response[0];
            const lastQuery = response[1];
            return new SystemUsersPageLoadedAction({
                users: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
        }));
    }
}
tslib_1.__decorate([
    Effect()
], SystemsEffects.prototype, "getSystemUsers$", void 0);
