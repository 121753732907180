import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BehaviorSubject, throwError } from 'rxjs'
import { RestApiService } from 'src/app/services/rest-api.service'
import { BalanceFeeDto } from './utils/balance-fee.dto'
import { PaymentRailConfig } from './utils/payment-rail-config.dto'
import { PaymentRailContractFees } from './utils/payment-rail-contract.dto'
import { catchError, first, tap } from 'rxjs/operators'
import { AccountFeesStateService } from '../utils/state/account-fees-state.service'
import { SystemUser } from 'src/app/models/system-user'

@Component({
	selector: 'kt-transaction-fees-component',
	templateUrl: './transaction-fees-component.component.html',
	styleUrls: ['./transaction-fees-component.component.scss']
})
export class TransactionFeesComponentComponent implements OnInit {
	paymentRails: BehaviorSubject<PaymentRailConfig[]> = new BehaviorSubject<PaymentRailConfig[]>(null)
	balanceCurrency = ['EUR', 'CHF', 'DKK', 'SEK', 'ILS', 'JPY']
	updatedBalances: BalanceFeeDto[] = []
	editedPaymentRails: PaymentRailContractFees[] = []
	detailsSubmitted: boolean
	updatedPaymentRailFees: PaymentRailContractFees[] = []

	@Input()
	currency: string

	@Input()
	entityId?: string

	@Input()
	readOnly?: boolean

	@Input()
	user?: SystemUser

	@Output()
	valid = new EventEmitter<{ ccy: string; valid: boolean }>()

	constructor(private restApiService: RestApiService, private readonly accountFeesStateService: AccountFeesStateService) {}

	ngOnInit() {
		if (this.currency && this.currency != 'null' && this.currency != 'undefined') {
			this.accountFeesStateService.detailsSubmitted
				.pipe(
					tap(data => {
						if (data === true) {
							const paymentRails = this.accountFeesStateService.paymentRails.value || []
							const fees = paymentRails.reduce((p, c) => [...p, ...c.paymentRailFees], [])
							this.accountFeesStateService.updatePaymentRailContractFees(fees)
							this.accountFeesStateService.finalisePaymentRails()
						}
					})
				)
				.subscribe()

			this.restApiService
				.getPaymentRailConfigByCurrency(this.currency)
				.pipe(
					first(railConfig => !!railConfig),
					tap(railConfig => {
						this.paymentRails.next(railConfig)
						if (this.entityId && this.readOnly) {
							this.accountFeesStateService.mapContractPaymentRailsFees(this.entityId, railConfig)
						} else {
							this.accountFeesStateService.mapDefaultPaymentRailsFees(railConfig)
						}
					}),
					catchError(error => {
						console.error(`CatchError: passPaymentRailConfig: `, error)
						return throwError(error)
					})
				)
				.subscribe()
		}
	}

	onBalanceOutput() {
		this.accountFeesStateService.updateDetailsSubmitted(true)
	}

	onValid(event: { ccy: string; valid: boolean }) {
		this.valid.emit(event)
	}
}
