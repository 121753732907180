import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import moment from 'moment'
import { map, tap } from 'rxjs/operators'
import { positiveNumber } from 'src/app/shared/helpers'
import { PaymentRailConfig } from '../transaction-fees-component/utils/payment-rail-config.dto'
import { PaymentRailContractFees } from '../transaction-fees-component/utils/payment-rail-contract.dto'
import { PaymentRailDefaultFees } from '../transaction-fees-component/utils/payment-rail-default.dto'
import { AccountFeesStateService } from '../utils/state/account-fees-state.service'
import { CHARGE_CURRENCY } from '../utils/state/constants'
import { Direction } from '../utils/state/direction.enum'
import { ValueType } from '../utils/state/value-type.enum'

@Component({
	selector: 'kt-in-out-bound',
	templateUrl: './in-out-bound.component.html',
	styleUrls: ['./in-out-bound.component.scss']
})
export class InOutBoundComponent implements OnInit {
	inBoundFees: PaymentRailContractFees = new PaymentRailContractFees()
	outBoundFees: PaymentRailContractFees = new PaymentRailContractFees()
	inOutBoundForm: FormGroup
	gbpZone: string = ''
	disableValidCheck = false

	@Input()
	inOutSubmitted: boolean

	@Input()
	paymentRail: PaymentRailConfig

	@Input()
	readOnly?: boolean

	@Output()
	valid = new EventEmitter<{ ccy: string; valid: boolean }>()

	types = [ValueType.ABS, ValueType.REL]
	readonly ValueType = ValueType
	readonly Direction = Direction

	constructor(private readonly accountFeesStateService: AccountFeesStateService) {}

	ngOnInit() {
		this.inOutBoundForm = new FormGroup(
			{
				inType: new FormControl({ value: ValueType.REL, disabled: this.readOnly }, [Validators.required]),
				inAmount: new FormControl({ value: undefined, disabled: this.readOnly }, [Validators.min(0), Validators.pattern(positiveNumber)]),
				inFloor: new FormControl({ value: undefined, disabled: this.readOnly }, [Validators.min(0), Validators.pattern(positiveNumber)]),
				inCap: new FormControl({ value: undefined, disabled: this.readOnly }, [Validators.min(0), Validators.pattern(positiveNumber)]),
				outType: new FormControl({ value: ValueType.REL, disabled: this.readOnly }, [Validators.required]),
				outAmount: new FormControl({ value: undefined, disabled: this.readOnly }, [Validators.min(0), Validators.pattern(positiveNumber)]),
				outFloor: new FormControl({ value: undefined, disabled: this.readOnly }, [Validators.min(0), Validators.pattern(positiveNumber)]),
				outCap: new FormControl({ value: undefined, disabled: this.readOnly }, [Validators.min(0), Validators.pattern(positiveNumber)])
			},
			{
				validators: [this.minInValidator, this.minOutValidator]
			}
		)

		this.inOutBoundForm.valueChanges
			.pipe(
				tap(data => {
					const isEmpty = !data.inAmount && !data.inCap && !data.inFloor && !data.outAmount && !data.outCap && !data.outFloor
					if (!this.readOnly && !isEmpty && !this.disableValidCheck) {
						this.valid.emit({ ccy: this.paymentRail.fromCcy, valid: this.inOutBoundForm.errors == null })
					}
				})
			)
			.subscribe()

		this.readOnly ? this.inOutBoundForm.disable() : this.inOutBoundForm.enable()

		if (this.paymentRail.paymentRailFees) {
			this.paymentRail.paymentRailFees.map(fee => this.mapInOutFees(fee))
		}

		if (this.inBoundFees && this.outBoundFees) {
			this.paymentRail.paymentRailFees = [this.inBoundFees, this.outBoundFees]
		}

		if (this.paymentRail.zone === 'Domestic' && this.paymentRail.fromCcy === CHARGE_CURRENCY) {
			this.gbpZone = this.paymentRail.maxValue == 1000000 ? `${this.paymentRail.zone} up to £1000000` : `${this.paymentRail.zone} over £1000000`
		} else {
			this.gbpZone = this.paymentRail.zone
		}

		this.accountFeesStateService.paymentRails
			.pipe(
				tap(() => {
					this.disableValidCheck = true
				}),
				map(rails => {
					if (rails) {
						return rails.find(rail => {
							return rail.id === this.paymentRail.id && rail.fromCcy === this.paymentRail.fromCcy
						})
					} else {
						return undefined
					}
				}),
				tap(rail => {
					if (rail) {
						if (rail.paymentRailFees) {
							rail.paymentRailFees.map(fee => this.mapInOutFees(fee))
							this.accountFeesStateService.updateDetailsSubmitted(true)
						} else {
							console.error(`no payment rail fees for ${rail.id}`)
						}
						this.disableValidCheck = false
					}
				})
			)
			.subscribe()
	}

	removeInvalidCharacters(event) {
		const value = event.target.value
		if (event.key != '.') {
			event.target.value = value.replace(/[^0-9.]/g, '')
		}
		event.preventDefault()
	}

	onChanged() {
		this.updateFeesFromForm()
	}

	onTypeChange(type: string, direction: Direction) {
		const amount = undefined

		if (type === ValueType.REL) {
			switch (direction) {
				case Direction.IN:
					this.inOutBoundForm.controls.inAmount.setValue(amount)
					this.inOutBoundForm.controls.inCap.enable()
					this.inOutBoundForm.controls.inFloor.enable()
					break
				case Direction.OUT:
					this.inOutBoundForm.controls.outAmount.setValue(amount)
					this.inOutBoundForm.controls.outCap.enable()
					this.inOutBoundForm.controls.outFloor.enable()
					break
			}
		} else {
			switch (direction) {
				case Direction.IN:
					this.inOutBoundForm.controls.inAmount.setValue(amount)
					this.inOutBoundForm.controls.inCap.disable()
					this.inOutBoundForm.controls.inFloor.disable()
					break
				case Direction.OUT:
					this.inOutBoundForm.controls.outAmount.setValue(amount)
					this.inOutBoundForm.controls.outCap.disable()
					this.inOutBoundForm.controls.outFloor.disable()
					break
			}
		}
		this.onChanged()
	}

	getCurrentUserId(): string {
		const currentUser = this.accountFeesStateService.getCurrentUser()
		return currentUser && currentUser.id
	}

	mapInOutFees(fee: PaymentRailDefaultFees): void {
		const direction = fee.direction
		const form = this.inOutBoundForm.getRawValue()
		const type = direction === Direction.IN ? form.inType : form.outType

		let fees = direction === Direction.IN ? this.inBoundFees : this.outBoundFees
		const amount = direction === Direction.IN ? form.inAmount : form.outAmount
		const floor = direction === Direction.IN ? form.inFloor : form.outFloor
		const cap = direction === Direction.IN ? form.inCap : form.outCap
		fees = this.mapUpdatedBoundFees(fees, type, direction, amount, floor, cap)

		fees.paymentRail = fee.paymentRail
		fees.balanceFee = fee.balanceFee
		fees.createdAt = moment(moment.now()).format()
		fees.valid = this.inOutBoundForm.valid
		fees.createdBy = this.getCurrentUserId()
		fees.reviewedAt = moment(moment.now()).format()
		fees.reviewedBy = this.getCurrentUserId()

		const defFeeAmount = fee.type === ValueType.ABS ? fee.defFeeAbsAmount : fee.defFeeRelPercent
		const defFeeCapAmount = type === ValueType.REL ? fee.defFeeCapAmount : undefined
		const defFeeFloorAmount = type === ValueType.REL ? fee.defFeeFloorAmount : undefined

		switch (direction) {
			case Direction.IN:
				this.inOutBoundForm.controls.inType.patchValue(fee.type === ValueType.ABS ? ValueType.ABS : ValueType.REL)
				this.inOutBoundForm.controls.inAmount.setValue(defFeeAmount)
				this.inOutBoundForm.controls.inCap.setValue(defFeeCapAmount)
				this.inOutBoundForm.controls.inFloor.setValue(defFeeFloorAmount)
				this.inBoundFees = fees
				break
			case Direction.OUT:
				this.inOutBoundForm.controls.outType.patchValue(fee.type === ValueType.ABS ? ValueType.ABS : ValueType.REL)
				this.inOutBoundForm.controls.outAmount.setValue(defFeeAmount)
				this.inOutBoundForm.controls.outCap.setValue(defFeeCapAmount)
				this.inOutBoundForm.controls.outFloor.setValue(defFeeFloorAmount)
				this.outBoundFees = fees
				break
		}
	}

	getErrorMessage(control: AbstractControl) {
		return control.getError('message')
	}

	private mapUpdatedBoundFees(
		fee: PaymentRailContractFees,
		type: ValueType,
		direction: string,
		amount: number,
		floor?: number,
		cap?: number
	): PaymentRailContractFees {
		const updatedFee = fee
		updatedFee.type = type
		updatedFee.direction = direction
		updatedFee.defFeeAbsAmount = type === ValueType.ABS ? amount : undefined
		updatedFee.defFeeRelPercent = type === ValueType.REL ? amount : undefined
		updatedFee.defFeeFloorAmount = type === ValueType.REL ? floor : undefined
		updatedFee.defFeeCapAmount = type === ValueType.REL ? cap : undefined
		updatedFee.paymentRailId = this.paymentRail.id
		updatedFee.currencyCode = this.paymentRail.fromCcy
		updatedFee.chargedCcyCode = CHARGE_CURRENCY
		updatedFee.effectiveFromDate = fee.effectiveFromDate

		return updatedFee
	}

	private updateFeesFromForm() {
		this.inBoundFees = this.mapUpdatedBoundFees(
			this.inBoundFees,
			this.inBoundFees.type,
			this.inBoundFees.direction,
			this.inOutBoundForm.controls.inAmount.value,
			this.inOutBoundForm.controls.inFloor.value,
			this.inOutBoundForm.controls.inCap.value
		)

		this.outBoundFees = this.mapUpdatedBoundFees(
			this.outBoundFees,
			this.outBoundFees.type,
			this.outBoundFees.direction,
			this.inOutBoundForm.controls.outAmount.value,
			this.inOutBoundForm.controls.outFloor.value,
			this.inOutBoundForm.controls.outCap.value
		)
		this.accountFeesStateService.updatePaymentRail(this.paymentRail.id, this.paymentRail.fromCcy, [this.inBoundFees, this.outBoundFees])
	}

	private minInValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
		const inType = control.get('inType')
		const inFloor = control.get('inFloor')
		const inCap = control.get('inCap')
		return this.isMinGreaterThanMax(inType.value, Direction.IN, inFloor, inCap)
	}

	private minOutValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
		const outType = control.get('outType')
		const outFloor = control.get('outFloor')
		const outCap = control.get('outCap')
		return this.isMinGreaterThanMax(outType.value, Direction.OUT, outFloor, outCap)
	}

	private isMinGreaterThanMax(type: ValueType, direction: Direction, floorControl: AbstractControl, capControl: AbstractControl): Object | null {
		const floor = parseFloat(floorControl.value)
		const cap = parseFloat(capControl.value)
		if (type === ValueType.REL && (!!floor || floor === 0) && (!!cap || cap === 0)) {
			if (floor > cap) {
				const error = { message: 'Minimum is greater than maximum' }
				floorControl.setErrors(error)
				this.setFormValid(direction, false)
				return error
			} else {
				floorControl.setErrors(null)
				this.setFormValid(direction, true)
				return null
			}
		} else {
			floorControl.setErrors(null)
			this.setFormValid(direction, true)
			return null
		}
	}

	private setFormValid(direction: Direction, valid: boolean) {
		if (direction === Direction.IN) {
			this.inBoundFees.valid = valid
		} else {
			this.outBoundFees.valid = valid
		}
	}
}
