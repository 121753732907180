import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
// NGRX
import { Action } from '@ngrx/store';
import { Beneficiary } from '../../models/beneficiary';


export enum BeneficiariesActionTypes {
    LOAD_BENEFICIARIES = '[BENEFICIARIES] Load Beneficiaries',
    GET_BENEFICIARIES = '[BENEFICIARIES] Get Beneficiaries',
    GET_BENEFICIARIES_FAILURE = '[BENEFICIARIES] Get Beneficiaries failure',
    GET_BENEFICIARIES_SUCCESS = '[BENEFICIARIES] Get Beneficiaries Success',
    ADD_BENEFICIARY = '[BENEFICIARIES] Add Beneficiary',
    LOAD_BENEFICIARIES_PAGE = '[BENEFICIARIES] Beneficiaries Loaded',
    ADD_BENEFICIARY_SUCCESS = '[BENEFICIARIES] Add Beneficiary Success',
    ADD_BENEFICIARY_FAILURE = '[BENEFICIARIES] Add Beneficiary Failure',
    EDIT_BENEFICIARY = '[BENEFICIARIES] Edit Beneficiary',
    EDIT_BENEFICIARY_SUCCESS = '[BENEFICIARIES] Beneficiary Edited',
    EDIT_BENEFICIARY_FAIL = '[BENEFICIARIES] Beneficiary Failed to Edit',
    DELETE_BENEFICIARY_SUCCESS = '[BENEFICIARIES] Beneficiary Deleted',
    DELETE_BENEFICIARY_FAIL = '[BENEFICIARIES] Beneficiary Failed to Delete'
}


export class LoadBeneficiariesAction implements Action {
    readonly type = BeneficiariesActionTypes.LOAD_BENEFICIARIES;
    constructor(public payload: Beneficiary[]) { }
}

export class BeneficiariesPageRequestedAction implements Action {
    readonly type = BeneficiariesActionTypes.GET_BENEFICIARIES;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class BeneficiariesPageLoadedAction implements Action {
    readonly type = BeneficiariesActionTypes.LOAD_BENEFICIARIES_PAGE;
    constructor(public payload: { beneficiaries: Beneficiary[], totalCount: number, page: QueryParamsModel }) { }
}

export class GetBeneficiariesFailureAction implements Action {
    readonly type = BeneficiariesActionTypes.GET_BENEFICIARIES_FAILURE;
    constructor(public payload: string) { }
}

export class GetBeneficiariesSuccessAction implements Action {
    readonly type = BeneficiariesActionTypes.GET_BENEFICIARIES_SUCCESS;
    constructor(public payload: string) { }
}

export class AddBeneficiaryAction implements Action {
    readonly type = BeneficiariesActionTypes.ADD_BENEFICIARY;
    constructor(public payload: Beneficiary) { }
}

export class AddBeneficiarySuccessAction implements Action {
    readonly type = BeneficiariesActionTypes.ADD_BENEFICIARY_SUCCESS;
    constructor(public payload: string) { }
}

export class AddBeneficiaryFailureAction implements Action {
    readonly type = BeneficiariesActionTypes.ADD_BENEFICIARY_FAILURE;
    constructor(public payload: string) { }
}

export class EditBeneficiaryAction implements Action {
    readonly type = BeneficiariesActionTypes.EDIT_BENEFICIARY;
    constructor(public payload: Beneficiary) { }
}

export type BeneficiariesActions
    = LoadBeneficiariesAction
    | BeneficiariesPageRequestedAction
    | GetBeneficiariesFailureAction
    | GetBeneficiariesSuccessAction
    | AddBeneficiaryAction
    | AddBeneficiarySuccessAction
    | AddBeneficiaryFailureAction
    | BeneficiariesPageLoadedAction
    | EditBeneficiaryAction;
