import { ActivatedRoute, Params } from '@angular/router';
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
// NGRX
import { Store } from '@ngrx/store';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Auth
import { AuthNoticeService, AuthService } from '../../../../core/auth/';
import { AppState } from '../../../../core/reducers';
import { ConfirmPasswordValidator } from './../register/confirm-password.validator';
import { Update } from '@ngrx/entity';
import { RestApiService } from '../../../../services/rest-api.service';

@Component({
  selector: 'kt-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, OnDestroy {
    createPasswordForm: FormGroup;
    loading = false;
    errors: any = [];
    paramSubscription: Subscription;
    id: string;
    password_token: string;
    private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    /**
     * Component constructor
     *
     * @param authNoticeService: AuthNoticeService
     * @param translate: TranslateService
     * @param router: Router
     * @param auth: AuthService
     * @param store: Store<AppState>
     * @param fb: FormBuilder
     * @param cdr
     */
    constructor(
        private authNoticeService: AuthNoticeService,
        private restApiService: RestApiService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private auth: AuthService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef
    ) {
        this.unsubscribe = new Subject();
    }

    /*
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

    /**
     * On init
     */
    ngOnInit() {
        this.paramSubscription = this.route.params.subscribe((params: Params) => {
            this.id = params.userId;
            this.password_token = params.token;
            this.restApiService
                .validateSystemUsersCreatePassword({ token: this.password_token, id: this.id })
                .subscribe(res => res, error => {
                    this.router.navigate(['/']);
                });
            this.initCreatePasswordForm();

        });

    }

    /*
    * On destroy
    */
    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    /**
     * Form initalization
     * Default params, validators
     */
    initCreatePasswordForm() {
        this.createPasswordForm = this.fb.group({
            password: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ],
            confirmPassword: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ])
            ]
        }, {
            validator: ConfirmPasswordValidator.MatchPassword
        });
    }

    /**
     * Form Submit
     */
    submit() {
        const controls = this.createPasswordForm.controls;

        // check form
        if (this.createPasswordForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );
            return;
        }

        this.loading = true;

        this.restApiService.systemUserCreatePassword({
            id: this.id,
            password_token: this.password_token,
            password: this.createPasswordForm.get('password').value
        }).subscribe(res => {
            this.authNoticeService.setNotice('You can now login', 'success');
            this.loading = false;
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 1000);
        }, error => {
            this.authNoticeService.setNotice(error.error.message, 'danger');
            this.loading = false;
        });
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.createPasswordForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }
}
