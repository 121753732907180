import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from '../../../../../services/rest-api.service';
import { Entity } from '../../../../../models/entity';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'kt-link-entity-modal',
    templateUrl: './link-entity-modal.component.html',
    styleUrls: ['./link-entity-modal.component.scss']
})
export class LinkEntityModalComponent implements OnInit {
    entity;
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isLoadingPostion$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    positionForm: FormGroup;
    isShareholder$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    searched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    results$: BehaviorSubject<Entity[]> = new BehaviorSubject([]);
    responseStatus = {
        show: false,
        isError: false,
        message: ''
    };
    @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
    constructor(public activeModal: NgbActiveModal, private restApiService: RestApiService, private modalService: NgbModal) { }

    ngOnInit() {
        this.positionForm = new FormGroup({
            postion: new FormControl({}, [Validators.required])
        });
    }

    onSearch() {
        const keyword = (this.searchInput.nativeElement.value).trim();
        if (keyword) {
            this.isLoading$.next(true);
            this.searched$.next(true);
            this.restApiService.searchEntityLink({ keyword }).subscribe(res => {
                this.isLoading$.next(false);
                this.results$.next(res);
            });
        }
    }

    onLinking(cofirmation) {
        const responseStatus = {
            show: false,
            isError: false,
            message: 'Individual has been link successfully'
        };
        this.modalService.open(cofirmation, { centered: true  }).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
            this.positionForm.reset();
            this.responseStatus = responseStatus;
        }, (reason) => {
            this.positionForm.reset();
            this.responseStatus = responseStatus;
        });

    }

    onChangesPosition(value) {
        if (value === 'shareholder' || value === 'both') {
            this.positionForm.addControl('shares', new FormControl('', Validators.required));
        } else {
            this.positionForm.removeControl('shares');
        }
    }

    onSubmit(result: Entity) {
        const formValues = this.positionForm.value;
        this.isLoadingPostion$.next(true);
        this.positionForm.disable();
        this.restApiService.linkEntity({
            entity_id: this.entity.id,
            individual_id: result.id,
            ...formValues
        }).subscribe(res => {
            this.responseStatus.show = true;
            this.responseStatus.isError = false;
            this.responseStatus.message = 'Individual has been link successfully';
            setTimeout(() => {
                this.modalService.dismissAll();
                this.activeModal.dismiss();
            }, 3000);
        }, error => {
            this.positionForm.enable();
            this.isLoadingPostion$.next(false);
            if (!error.status) {
                this.responseStatus.message = 'No internet connection, please try again later';
            } else {
                this.responseStatus.message = error.error.message;
            }
            this.responseStatus.isError = true;
            this.responseStatus.show = true;
        });
    }
}
