import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud'
import { AccountDetail } from '../../../../models/account-info.dto'
import { Entity } from '../../../../models/entity'
import { RestApiService } from '../../../../services/rest-api.service'
import { cryptoCurrencies, currencies, emailPattern, nameRegex, positiveNumber } from '../../../../shared/helpers'
import { EntityCreatedAction } from '../../../../store/actions/entities'
import { AppState } from '../../../../store/reducers/index'
import { BehaviorSubject, combineLatest, of, throwError } from 'rxjs'
import moment, { Moment } from 'moment'
import { DefaultGeneralFees } from 'src/app/models/default-general-fees.dto'
import { catchError, first, switchMap, tap } from 'rxjs/operators'
import { PaymentRailContractFees } from './transaction-fees-component/utils/payment-rail-contract.dto'
import { DateAdapter, MAT_DATE_LOCALE, MatDialog } from '@angular/material'
import { AccountFeesStateService } from './utils/state/account-fees-state.service'
import { AuthenticationService } from 'src/app/services/auth.service'
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component'

@Component({
	selector: 'kt-add-corporate-form-modal',
	templateUrl: './add-corporate-form-modal.component.html',
	styleUrls: ['./add-corporate-form-modal.component.scss'],
	providers: [AccountFeesStateService]
})
export class AddCorporateFormModalComponent implements OnInit, OnDestroy {
	updatedPaymentRailFee: PaymentRailContractFees[] = []
	detailsSubmitted: boolean
	confirmedFees: boolean = false
	transactionFeesValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	generalFeesValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	entity: Entity
	checkForm: FormGroup
	entityDetailsForm: FormGroup
	generalFeesForm: FormGroup
	transactionalFeesForm: FormGroup

	isDealIdValid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
	generalFees: DefaultGeneralFees[]

	isLoading = false
	startDate: Date
	responseStatus = {
		show: false,
		isError: false,
		message: 'Success, an email has been triggered to start the onboarding'
	}
	accounts: Array<AccountDetail> = []
	newAccountForm: FormGroup
	currencyGroup: CurrencyGroup[] = [
		{
			name: 'Fiat Currency',
			currency: currencies
		},
		{
			name: 'Cryptocurrency',
			currency: cryptoCurrencies
		}
	]
	industryTypes: IndustryType[] = [
		{ id: 'asset_manager', description: 'Asset Manager' },
		{ id: 'consultant', description: 'Consultant' },
		{ id: 'crypto_custodian', description: 'Crypto Custodian' },
		{ id: 'crypto_fund', description: 'Crypto Fund' },
		{ id: 'exchange', description: 'Exchange' },
		{ id: 'otc_desk', description: 'OTC Desk' },
		{ id: 'otc_trading', description: 'OTC Trading' },
		{ id: 'prop_trader', description: 'Prop Trader' },
		{ id: 'technology', description: 'Technology' }
	]
	products: Product[] = [
		{ id: 'Accounts', description: 'Accounts' },
		{ id: 'Custody', description: 'Custody' },
		{ id: 'Trading - Crypto', description: 'Trading - Crypto' },
		{ id: 'Trading - FX', description: 'Trading - FX' },
		{ id: 'Treasury', description: 'Treasury' },
		{ id: 'Wealth Partners', description: 'Wealth Partners' },
		{ id: 'Yield', description: 'Yield' }
	]
	isManualCapture = false
	activeAccountTabIndex = -1
	isDeleteEvent: boolean

	invalidChars = ['-', '+', 'e']

	constructor(
		public activeModal: NgbActiveModal,
		private layoutUtilsService: LayoutUtilsService,
		private store: Store<AppState>,
		private restApiService: RestApiService,
		private router: Router,
		private readonly accountFeesStateService: AccountFeesStateService,
		private adapter: DateAdapter<any>,
		private readonly authenticationService: AuthenticationService,
		@Inject(MAT_DATE_LOCALE) private _locale: string,
		public dialog: MatDialog
	) {
		this.adapter.setLocale(this._locale)
	}

	ngOnInit() {
		this.accountFeesStateService.disableTransactionsForm(false)
		this.startDate = new Date()
		this.accountFeesStateService.accounts.subscribe(accounts => (this.accounts = accounts))
		this.accountFeesStateService.detailsSubmitted.subscribe(res => (this.detailsSubmitted = res))
		this.accountFeesStateService.feesConfirmed.subscribe(res => (this.confirmedFees = res))
		this.accountFeesStateService.paymentRailContractFees.subscribe(res => (this.updatedPaymentRailFee = res || []))
		this.accountFeesStateService.generalFees.subscribe(res => (this.generalFees = res || []))

		this.authenticationService
			.getUser$()
			.pipe(
				first(data => !!data),
				tap(user => this.accountFeesStateService.setCurrentUser(user)),
				switchMap(() => this.restApiService.getDefaultFees()),
				tap(data => {
					this.accountFeesStateService.updateGeneralFees(data)
					this.mapDefaultGeneralFees(data)
				}),
				catchError(error => {
					console.error(`CatchError: generalFeesService`, error)
					return throwError(error)
				})
			)
			.subscribe()

		this.entityDetailsForm = new FormGroup({
			entity_name: new FormControl('', [Validators.required]),
			hubspot_deal: new FormControl('', [Validators.required]),
			first_name: new FormControl('', [Validators.required, Validators.pattern(nameRegex)]),
			middle_names: new FormControl('', Validators.pattern(nameRegex)),
			last_name: new FormControl('', [Validators.required, Validators.pattern(nameRegex)]),
			email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(emailPattern)]),
			mobile: new FormControl(''),
			shares: new FormControl(undefined, this.entity ? Validators.required : []),
			industry_type: new FormControl(undefined, [Validators.required]),
			products: new FormControl(undefined, [Validators.required]),
			crypto: new FormControl(undefined, [Validators.required]),
			note: new FormControl(undefined, [Validators.required])
		})

		this.generalFeesForm = new FormGroup({
			setup_fee: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(positiveNumber)]),
			management_fee: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(positiveNumber)]),
			min_transaction_fees: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(positiveNumber)]),
			general_exceptions: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(positiveNumber)]),
			fraud_claim_exceptions: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(positiveNumber)]),
			minimum_term: new FormControl('', [Validators.required, Validators.min(0), Validators.pattern(positiveNumber)])
		})

		this.checkForm = new FormGroup({
			confirmedFees: new FormControl(false, [Validators.required])
		})

		this.newAccountForm = new FormGroup({
			label: new FormControl(undefined),
			currency: new FormControl(undefined, [Validators.required]),
			effectiveDate: new FormControl(undefined, [Validators.required])
		})

		this.transactionalFeesForm = new FormGroup({
			valid: new FormControl(false, [Validators.requiredTrue])
		})
		this.accountFeesStateService.validPaymentRailContractFees.subscribe(valid => {
			this.transactionFeesValid.next(valid)
			if (valid === false) {
				this.transactionalFeesForm.controls.valid.setErrors({ valid } as ValidationErrors)
			} else {
				this.transactionalFeesForm.controls.valid.setValue(this.isTransactionFeesComplete())
			}
		})
	}

	ngOnDestroy(): void {
		this.accountFeesStateService.close()
	}

	getAccounts() {
		return this.accounts.filter(account => account.currency && account.effectiveDate)
	}

	getUniqueCurrencies(): string[] {
		const currencies = this.accountFeesStateService.accounts.value.map(accounts => accounts.currency)
		return [...new Set(currencies)]
	}

	checkedChanged(event: { checked: boolean }) {
		this.accountFeesStateService.updateFeesConfirmed(event.checked)
	}

	selectionChange(event): void {
		if (event.selectedIndex != 3 && this.checkForm.controls.confirmedFees.value) {
			this.checkForm.controls.confirmedFees.setValue(false)
			this.accountFeesStateService.updateFeesConfirmed(false)
		}
		if (event.selectedIndex === 3 || event.selectedIndex === 2) {
			this.generalFeesValid.next(this.generalFeesForm.valid)
			this.accountFeesStateService.updateDetailsSubmitted(true)
		}
		this.accounts = this.getAccounts()
	}

	isTransactionFeesComplete(): boolean {
		return this.generalFeesValid.value && this.transactionFeesValid.value
	}

	canSubmit(): boolean {
		const withAccounts = this.entityDetailsForm.valid && this.isTransactionFeesComplete() && this.checkForm.controls.confirmedFees.value === true
		const noAccounts = this.entityDetailsForm.valid
		return this.hasAccounts() ? withAccounts : noAccounts
	}

	hasAccounts() {
		return this.accounts.length > 0
	}

	disableAccountDeleteBtn() {
		return this.isAccountsSelected() && this.accounts.length === 1 && !this.accounts[0].effectiveDate && !this.accounts[0].currency
	}

	accountSelectedValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			return !this.accounts.length && control.value === '' ? { error: 'error' } : null
		}
	}

	isAccountsSelected(): boolean {
		if (this.entityDetailsForm.controls.products.value) {
			return this.accounts.length && this.entityDetailsForm.controls.products.value.includes('Accounts')
		} else {
			return false
		}
	}

	showSubmitButtons(step: number): boolean {
		return !this.isAccountsSelected() || (this.confirmedFees && step === 3)
	}

	onAccountTabChange(newTabIndex: number): void {
		const tabAccount: AccountDetail = this.accounts[newTabIndex]
		if (!!tabAccount && tabAccount.currency && tabAccount.effectiveDate) {
			// create/edit current account
			this.onSaveAccount()
			// populate/reset form for next account
			this.newAccountForm.controls.label.setValue(tabAccount.label)
			this.newAccountForm.controls.currency.setValue(`${tabAccount.currency}`)
			if (tabAccount.effectiveDate) {
				this.newAccountForm.controls.effectiveDate.patchValue(moment(tabAccount.effectiveDate, 'DD/MM/YYYY').toDate())
			}
		} else {
			this.newAccountForm.reset()
		}

		this.activeAccountTabIndex = newTabIndex
	}

	onSaveAccount(): void {
		const newAccountFormRaw: { [key: string]: string } = this.newAccountForm.getRawValue()

		const effectiveDate = newAccountFormRaw.effectiveDate ? moment(newAccountFormRaw.effectiveDate, 'DD/MM/YYY').toDate() : undefined
		const newAccount: AccountDetail = {
			label: `${newAccountFormRaw.currency} | ${this.activeAccountTabIndex}`,
			currency: newAccountFormRaw.currency,
			effectiveDate,
			canDelete: false
		}

		if (!this.isDeleteEvent) {
			if (this.hasAccounts()) {
				this.accountFeesStateService.addUpdateAccount(newAccount, this.activeAccountTabIndex)
			}
		}

		this.isDeleteEvent = false
	}

	onAddAccount() {
		if (this.hasAccounts()) {
			this.onSaveAccount()
		}

		if (!this.entityDetailsForm.get('products').value) {
			this.entityDetailsForm.get('products').setValue(['Accounts'])
		} else if (!this.entityDetailsForm.get('products').value.includes('Accounts')) {
			this.entityDetailsForm.get('products').setValue([...this.entityDetailsForm.get('products').value, 'Accounts'])
		}
		this.accounts.push({
			label: undefined,
			currency: undefined,
			effectiveDate: undefined,
			canDelete: false
		})
		this.activeAccountTabIndex = this.accounts.length - 1
		this.newAccountForm.reset()
	}

	onRemoveAccount() {
		this.accountFeesStateService.removeAccount(this.activeAccountTabIndex)
		this.isDeleteEvent = true
		this.newAccountForm.reset()
		this.activeAccountTabIndex = this.activeAccountTabIndex > this.accounts.length ? this.accounts.length - 1 : this.activeAccountTabIndex
		this.onAccountTabChange(this.activeAccountTabIndex)

		if (this.accounts.length < 1 && this.entityDetailsForm.get('products').value) {
			this.entityDetailsForm
				.get('products')
				.setValue(this.entityDetailsForm.get('products').value.filter((item: string) => item !== 'Accounts'))
		}
	}

	onProductSelect(value: Array<string>): void {
		if (value.includes('Accounts') && !this.accounts.length) {
			this.onAddAccount()
		} else if (!value.includes('Accounts') && this.accounts.length) {
			this.activeAccountTabIndex = 0
			this.newAccountForm.reset()
			for (let index = 0; index < this.accounts.length; index++) {
				this.accountFeesStateService.removeAccount(index)
			}
		}
	}

	onUpdateGeneralFees(entityId?: string) {
		this.accountFeesStateService.updateEntityGeneralFees(entityId, this.generalFeesForm)
	}

	onSubmit(manual: boolean): void {
		if (this.canSubmit()) {
			this.isManualCapture = manual
			// prevent submitting on continue
			if (this.isAccountsSelected() && !this.confirmedFees) {
				return
			}

			// Save details or remove account based on validity
			const accounts = this.accountFeesStateService.accounts.value
			if (accounts[this.activeAccountTabIndex] && this.newAccountForm.valid) {
				this.onSaveAccount()
			} else if (!this.entityDetailsForm.get('products').value.includes('Accounts')) {
				this.onRemoveAccount()
			}

			const canProceed =
				(!this.entityDetailsForm.get('products').value.includes('Accounts') && this.accounts.length < 1) ||
				(this.entityDetailsForm.get('products').value.includes('Accounts') &&
					this.hasAccounts() &&
					!this.accounts.filter(item => !item.currency).length)

			if (this.entityDetailsForm.valid && canProceed) {
				this.isLoading = true
				this.entityDetailsForm.disable()
				this.restApiService
					.addEntity(this.formatRequest())
					.pipe(
						first(data => !!data),
						tap(entity => {
							this.store.dispatch(new EntityCreatedAction(entity))
							this.responseStatus.show = true
							this.isLoading = true
							if (this.isManualCapture) {
								this.layoutUtilsService.showActionNotification(
									'Success, the new corporate has been added',
									MessageType.Read,
									5000,
									true,
									false
								)
							} else {
								this.layoutUtilsService.showActionNotification(
									'Success, the new corporate has been added, and an email has been send',
									MessageType.Read,
									5000,
									true,
									false
								)
							}
						}),
						switchMap(entity => {
							if (!this.isAccountsSelected()) return of([entity])
							this.onUpdateGeneralFees(entity.id)
							this.updatedPaymentRailFee.map(paymentRailFee => {
								paymentRailFee.entityId = entity.id
							})
							return combineLatest([
								of(entity),
								this.restApiService.addEntityGeneralFees(this.accountFeesStateService.entityGeneralFees.value).pipe(
									catchError(error => {
										this.restApiService.deleteEntity(entity.id)
										this.layoutUtilsService.showActionNotification(
											'Failed, General fees were not stored successfully and Entity was deleted. Try Again',
											MessageType.Read,
											5000,
											true,
											false
										)
										console.error(`CatchError: restApiService.setEntityGeneralFees`, error)
										return throwError(error)
									})
								),
								this.restApiService
									.addTransactionalFees(entity.id, this.accountFeesStateService.finalisedPaymentRailContractFees.value)
									.pipe(
										catchError(error => {
											this.restApiService.deleteEntity(entity.id)
											this.layoutUtilsService.showActionNotification(
												'Failed, Transaction fees were not stored successfully and Entity was deleted. Try Again',
												MessageType.Read,
												5000,
												true,
												false
											)
											console.error(`CatchError: restApiService.updateTransactionalFees`, error)
											return throwError(error)
										})
									)
							])
						}),
						tap(result => {
							console.table(result)
							this.activeModal.close()
							setTimeout(() => {
								this.router.navigateByUrl(`/entities/onboarding/corporate/${result[0].id}/profile`)
							}, 1000)
						}),
						catchError(error => {
							this.isLoading = false
							this.entityDetailsForm.enable()
							this.responseStatus.show = true
							this.responseStatus.isError = true
							if (!error.status) {
								this.responseStatus.message = 'No internet connection, please try again later'
							} else {
								this.responseStatus.message = error.message
							}
							return throwError(error)
						})
					)
					.subscribe()
			}
		}
	}

	validateHubSpotDealId() {
		// TODO: Reimplement once HubSpot API token is updated
		// const formField = this.entityDetailsForm.controls['hubspot_deal']
		// if (formField.value) {
		// 	this.restApiService.validateHubSpotDealId(formField.value).subscribe(
		// 		() => {
		// 			formField.setErrors(null)
		// 		},
		// 		() => {
		// 			formField.setErrors({ not_found: true })
		// 		}
		// 	)
		// }
	}

	dateFilter = (d: Moment | null): boolean => {
		const day = (d || moment()).day()
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6
	}

	removeInvalidCharacters(event) {
		const value = event.target.value
		if (event.key != '.') {
			event.target.value = value.replace(/[^0-9.]/g, '')
		}
		event.preventDefault()
	}

	removeTermInvalidCharacters(event) {
		const invalidChars = ['-', '+', 'e', ',', '.']
		if (invalidChars.includes(event.key)) {
			event.preventDefault()
		}
	}

	closeAccountModal(): void {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			data: { extraInfo: 'All captured data will be lost.', item: 'form', action: 'close', danger: false },
			width: '440px'
		})

		dialogRef
			.afterClosed()
			.pipe(first())
			.subscribe(data => {
				if (data) {
					this.activeModal.close()
				}
			})
	}

	private formatRequest(): Entity {
		const formData: Entity = {
			...this.entityDetailsForm.value,
			password: 'isrequired',
			type: 'corporate',
			is_manual_capture: this.isManualCapture
		}
		if (this.entity) {
			formData.parent_id = this.entity.id
			formData.type = 'sub-entity-corporate'
		}

		if (this.accounts.length === 0) {
			return {
				...formData,
				products: {
					products: [...this.entityDetailsForm.get('products').value]
				}
			}
		} else {
			return {
				...formData,
				products: {
					products: [...this.entityDetailsForm.get('products').value],
					business_accounts: this.accounts.map((account: AccountDetail) => {
						return {
							details: { ...account, status: 'incomplete' }
						}
					}),
					disclaimer: undefined
				}
			}
		}
	}

	private getGeneralFeeAmount(contains: string, fees: DefaultGeneralFees[]): number | undefined {
		const fee = fees.find(fee => fee.category.description.toLowerCase().includes(contains.toLowerCase()))
		if (fee) {
			return fee.amount
		}
	}

	private mapDefaultGeneralFees(fees: DefaultGeneralFees[]): void {
		const setupFee = this.getGeneralFeeAmount('Setup Fee', fees)
		const managementFee = this.getGeneralFeeAmount('Management Fee', fees)
		const minTransactionFees = this.getGeneralFeeAmount('Minimum Transaction Fees', fees)
		const generalExceptions = this.getGeneralFeeAmount('General Exceptions', fees)
		const fraudClaimExceptions = this.getGeneralFeeAmount('Fraud Claim Exceptions', fees)
		const minimumTerm = this.getGeneralFeeAmount('Minimum Term', fees)

		this.generalFeesForm.controls.setup_fee.setValue(setupFee)
		this.generalFeesForm.controls.management_fee.setValue(managementFee)
		this.generalFeesForm.controls.min_transaction_fees.setValue(minTransactionFees)
		this.generalFeesForm.controls.general_exceptions.setValue(generalExceptions)
		this.generalFeesForm.controls.fraud_claim_exceptions.setValue(fraudClaimExceptions)
		this.generalFeesForm.controls.minimum_term.setValue(minimumTerm)
	}

	private mapContractGeneralFees(fees: DefaultGeneralFees[]): void {
		const setupFee = this.getGeneralFeeAmount('Setup Fee', fees)
		const managementFee = this.getGeneralFeeAmount('Management Fee', fees)
		const minTransactionFees = this.getGeneralFeeAmount('Minimum Transaction Fees', fees)
		const generalExceptions = this.getGeneralFeeAmount('General Exceptions', fees)
		const fraudClaimExceptions = this.getGeneralFeeAmount('Fraud Claim Exceptions', fees)
		const minimumTerm = this.getGeneralFeeAmount('Minimum Term', fees)

		this.generalFeesForm.controls.setup_fee.setValue(setupFee)
		this.generalFeesForm.controls.management_fee.setValue(managementFee)
		this.generalFeesForm.controls.min_transaction_fees.setValue(minTransactionFees)
		this.generalFeesForm.controls.general_exceptions.setValue(generalExceptions)
		this.generalFeesForm.controls.fraud_claim_exceptions.setValue(fraudClaimExceptions)
		this.generalFeesForm.controls.minimum_term.setValue(minimumTerm)
	}
}

interface CurrencyGroup {
	disabled?: boolean
	name: string
	currency: string[]
}

interface IndustryType {
	id: string
	description: string
}

interface Product {
	id: string
	description: string
}
