import { Component } from '@angular/core'
import { PaymentRailContractFees } from '../transaction-fees-component/utils/payment-rail-contract.dto'
import { AccountFeesStateService } from '../utils/state/account-fees-state.service'
import { BehaviorSubject } from 'rxjs'

@Component({
	selector: 'kt-transactions-confirmation',
	templateUrl: './transactions-confirmation.component.html',
	styleUrls: ['./transactions-confirmation.component.scss']
})
export class TransactionsConfirmationComponent {
	displayedColumns: string[] = [
		'currencyCode',
		'paymentRail',
		'direction',
		'effectiveFromDate',
		'defFeeAbsAmount',
		'defFeeRelPercent',
		'defFeeFloorAmount',
		'defFeeCapAmount',
		'balanceFee'
	]
	paymentRailContractFees: BehaviorSubject<PaymentRailContractFees[]>

	constructor(private readonly accountFeesStateService: AccountFeesStateService) {
		this.paymentRailContractFees = this.accountFeesStateService.finalisedPaymentRailContractFees
	}
}
