// NGRX
import {EntityProfileModel} from '../../models/entity-profile';
import {EntityProfileActions, EntityProfilesActionTypes} from '../actions/entity-profiles';

export interface EntityProfilesState {
    entityProfiles: EntityProfileModel[];
    error: Error;
}


export const initialEntitiesState: EntityProfilesState = {
    entityProfiles: [],
    error: null
};

export function entitiesReducer(state = initialEntitiesState, action: EntityProfileActions): EntityProfilesState {
    switch (action.type) {
        // case EntityProfilesActionTypes.LOAD_PROFILES: {
        //     return {
        //       ...state,
        //       entityProfiles: [...action.payload]
        //     };
        // }
        case EntityProfilesActionTypes.GET_PROFILE_FAILURE: {
            return {
                ...state,
                error: action.payload
            };
        }
        case EntityProfilesActionTypes.ADD_PROFILE: {
            const addEntity = state.entityProfiles.slice(0);
            addEntity.unshift(action.payload);
            return {
                ...state,
                entityProfiles: addEntity
            };
        }
        case EntityProfilesActionTypes.ADD_DOCUMENT_TO_PROFILE: {
            const entities = state.entityProfiles.slice(0);
            const addEntities = entities.map(entity => {
                const entityCopy = {...entity};
                return Object.assign(entityCopy, {documents: action.payload[0].entity_id === entity.id ? entityCopy.documents.push(action.payload) : entityCopy.documents});
            });
            return {
                ...state,
                entityProfiles: [...addEntities]
            };
        }

        case EntityProfilesActionTypes.UPDATE_ENTITY_PROFILE: {
            const storeProfileCopy: EntityProfileModel[] = [...state.entityProfiles]
                .map((profile: EntityProfileModel) => {
                    let profileCopy: EntityProfileModel = {...profile};
                    if (profileCopy.id === action.payload.profileId) {
                        profileCopy = action.payload.entityProfile;
                    }

                    return profileCopy;
                });

            return {
                ...state,
                entityProfiles: storeProfileCopy
            };
        }

        default:
            return state;
    }
}
