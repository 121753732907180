import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dashboardStatus',
    pure: true
})
export class DashboardStatusPipe implements PipeTransform {
    transform(entity: any, args?: any): any {
        if (entity.entity_status === 'rejected') {
            return 'Rejected';
        }

        if (entity.entity_approved) {
            return 'Approved';
        }

        if (entity.entity_completed) {
            return 'Completed';
        }

        if (entity.entity_started) {
            return 'Started';
        }
        return 'Invited';
    }
}
