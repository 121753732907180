<!--begin::Body-->
<div class="kt-login__body">
    <!--begin::Signin-->
    <div class="kt-login__form">
        <div class="kt-login__title">
            <h3>{{ 'AUTH.CREATE_PASSWORD.TITLE' | translate }}</h3>
        </div>

        <kt-auth-notice></kt-auth-notice>

        <form class="kt-login__form kt-form" [formGroup]="createPasswordForm" autocomplete="off">
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('password','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword"
                           autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('confirmPassword','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                    <mat-error *ngIf="createPasswordForm.get('confirmPassword').errors">
                        <strong>Passsword and ConfirmPassword didn't match.</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <!--begin::Action-->
            <div class="kt-login__actions">

                <button (click)="submit()" type="submit" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary"
                        [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
            </div>
            <!--end::Action-->
        </form>
    </div>
</div>
