import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../../../services/auth.service';
import { ActivatedRoute, Params } from '@angular/router'
import { Subscription } from 'rxjs/internal/Subscription'

@Component({
  selector: 'kt-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

    subscriptions = new Subscription();

    constructor(public authenticationService: AuthenticationService, private readonly route: ActivatedRoute) { }

      ngOnInit() {
          this.subscriptions.add(this.route.queryParams.subscribe((params: Params) => {
              this.authenticationService.handleAuthCallback(params);
          }));
      }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
}
