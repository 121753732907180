import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
// NGRX
import { SystemUser } from './../../models/system-user';
import { SystemsActions, SystemsActionTypes } from './../actions/systems';

export interface SystemUserState extends EntityState<SystemUser> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<SystemUser> = createEntityAdapter<SystemUser>();

export const initialSystemState: SystemUserState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedUserId: undefined,
    showInitWaitingMessage: true
});

export function systemsReducer(state = initialSystemState, action: SystemsActions): SystemUserState {
    switch (action.type) {
        case SystemsActionTypes.LOAD_SYSTEM_USERS_PAGE: {
            return adapter.addMany(action.payload.users, {
                ...initialSystemState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        // case EntitiesActionTypes.ADD_DOCUMENT_TO_ENTITY: {
        //     const systems = state.onboarding.systems.slice(0);
        //     const addEntities = systems.map(entity => {
        //         return Object.assign({ ...entity }, { documents: action.payload[0].entity_id === entity.id ? action.payload : [] });
        //     });
        //     return {
        //         ...state,
        //         onboarding: {
        //             systems: [...addEntities]
        //         }
        //     };
        // }
        default: return state;
    }
}
