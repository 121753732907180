// NGRX
import {Entity} from '../../models/entity';
import {EntitiesActions, EntitiesActionTypes} from '../actions/entities';

export interface IEntityStateObj {
    entities: Entity[];
    count?: number;
}

export interface EntitiesState {
    onboarding: IEntityStateObj;
    individual: IEntityStateObj;
    corporate: IEntityStateObj;
    error: Error;
    isLoading: boolean;
}

export const initialEntitiesState: EntitiesState = {
    onboarding: {
        entities: [],
        count: 0
    },
    individual: {
        entities: [],
        count: 0
    },
    corporate: {
        entities: [],
        count: 0
    },
    error: null,
    isLoading: false
};

export function entitiesReducer(state = initialEntitiesState, action: EntitiesActions): EntitiesState {
    switch (action.type) {
        case EntitiesActionTypes.LOAD_ONBOARDING_ENTITIES: {
            const {entities, count} = action.payload;
            return {
                ...state,
                onboarding: {
                    entities: [...entities],
                    count
                }
            };
        }

        case EntitiesActionTypes.ENTITIES_TOGGLE_LOADING: {
            const {isLoading} = action.payload;
            return {
                ...state,
                isLoading
            };
        }

        case EntitiesActionTypes.LOAD_SUB_ENTITIES: {
            const addSubEntities = (allEntities: Entity[], entityPayload: { entity_id: string, subEntities: Entity[] }): Entity[] => {
                return allEntities.map(entity => {
                    let entityData;
                    if (entity.id === entityPayload.entity_id) {
                        entityData = {...entity, entities: entityPayload.subEntities};
                    } else {
                        entityData = {...entity};
                    }
                    return entityData;
                });
            };
            const onboardingEntitiesCopy = state.onboarding.entities.slice(0);
            const individualEntitiesCopy = state.individual.entities.slice(0);
            const corporatentitiesCopy = state.corporate.entities.slice(0);
            const corporateEntities = addSubEntities(corporatentitiesCopy, action.payload);
            const onboardingEntities = addSubEntities(onboardingEntitiesCopy, action.payload);
            const individualEntities = addSubEntities(individualEntitiesCopy, action.payload);

            return {
                ...state,
                corporate: {
                    entities: [...corporateEntities],
                    count: corporateEntities.length
                },
                individual: {
                    entities: [...individualEntities],
                    count: individualEntities.length
                },
                onboarding: {
                    entities: [...onboardingEntities],
                    count: onboardingEntities.length
                }
            };
        }

        case EntitiesActionTypes.LOAD_INDIVIDUAL_ENTITIES: {
            const {entities, count} = action.payload;
            return {
                ...state,
                individual: {
                    entities: [...entities],
                    count
                }
            };
        }
        case EntitiesActionTypes.LOAD_CORPORATE_ENTITIES: {
            const {entities, count} = action.payload;
            return {
                ...state,
                corporate: {
                    entities: [...entities],
                    count
                }
            };
        }
        case EntitiesActionTypes.GET_ENTITIES_FAILURE: {
            return {
                ...state,
                error: action.payload
            };
        }
        case EntitiesActionTypes.ADD_ENTITY: {
            const addEntity = state.onboarding.entities.slice(0);
            addEntity.unshift(action.payload);
            return {
                ...state,
                onboarding: {
                    entities: addEntity
                }
            };
        }
        case EntitiesActionTypes.ADD_DOCUMENT_TO_ENTITY: {
            const entities = state.onboarding.entities.slice(0);
            const addEntities = entities.map(entity => {
                return Object.assign({...entity}, {documents: action.payload[0].entity_id === entity.id ? action.payload : []});
            });
            return {
                ...state,
                onboarding: {
                    entities: [...addEntities]
                }
            };
        }

        case EntitiesActionTypes.UPDATE_ENTITY: {
            const updatedEntities: Entity[] = state[`${action.payload.entityType}`].entities;

            // Update edited entity
            updatedEntities.map((entity: Entity) => {
                if (entity.id === action.payload.entity.id) {
                    console.log('entity before', entity);
                    entity = action.payload.entity;
                    console.log('entity after', entity);
                }

                return entity;
            });

            return {
                ...state,
                [`${action.payload.entityType}`]: {
                    entities: updatedEntities
                }
            };
        }

        default:
            return state;
    }
}
