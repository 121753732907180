<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
        <div class="row" style="width:100%">
            <div class="col-md-8">
                <!-- <div class="ticker bcb-ticker">
                    <div class="ticker-item">
                        <img src="https://s2.coinmarketcap.com/static/img/coins/32x32/1.png" height="20">
                        <strong>BTC&nbsp;&nbsp;$7,999.50&nbsp;&nbsp;<span class="kt-font-danger">(<i class="fa fa-arrow-down kt-font-danger"></i>&nbsp;5.56%)</span></strong>
                    </div>
                    <div class="ticker-item">
                        <img src="https://s2.coinmarketcap.com/static/img/coins/32x32/1027.png" height="20">
                        <strong>ETH&nbsp;&nbsp;$250.50&nbsp;&nbsp;<span class="kt-font-success">(<i class="fa fa-arrow-up kt-font-success"></i>&nbsp;2.33%)</span></strong>
                    </div>
                    <div class="ticker-item">
                        <img src="https://s2.coinmarketcap.com/static/img/coins/32x32/52.png" height="20">
                        <strong>XRP&nbsp;&nbsp;$0.419105&nbsp;&nbsp;<span class="kt-font-danger">(<i class="fa fa-arrow-down kt-font-danger"></i>&nbsp;6.39%)</span></strong>
                    </div>
                </div> -->
            </div>
            <div class="col-md-4 text-right">
                {{today | date:'yyyy'}}&nbsp;&copy;&nbsp;<a href="https://bcbgroup.io" target="_blank" class="kt-link">BCB Group</a>
            </div>
        </div>
    </div>
</div>
<!-- end:: Footer -->
