import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LayoutUtilsService, MessageType } from '../../../../core/_base/crud';
import { Entity } from '../../../../models/entity';
import { RestApiService } from '../../../../services/rest-api.service';
import { emailPattern, nameRegex } from '../../../../shared/helpers';
import { EntityCreatedAction } from '../../../../store/actions/entities';
import { AppState } from '../../../../store/reducers/index';

interface IDocument {
    document: any;
    filename: string;
    content_type: string;
    type: string;
}

@Component({
    selector: 'kt-add-individual-form-modal',
    templateUrl: './add-individual-form-modal.component.html',
    styleUrls: ['./add-individual-form-modal.component.scss']
})
export class AddIndividualFormModalComponent implements OnInit {
    addIndividualForm: FormGroup;
    entity: Entity;
    isLoading = false;
    filename = {
        national_identity_card: 'Choose file',
        proofOAddressDocument: 'Choose file'
    };
    responseStatus = {
        show: false,
        isError: false,
        message: 'Success, an email has been triggered to start the onboarding'
    };
    isManualCapture = false;
    documentUpload: IDocument[] = [];

    constructor(public activeModal: NgbActiveModal, private layoutUtilsService: LayoutUtilsService, private store: Store<AppState>, private restApiService: RestApiService, private router: Router) {
    }

    ngOnInit() {
        this.addIndividualForm = new FormGroup({
            first_name: new FormControl('', [Validators.required, Validators.pattern(nameRegex)]),
            middle_names: new FormControl('', Validators.pattern(nameRegex)),
            last_name: new FormControl('', [Validators.required, Validators.pattern(nameRegex)]),
            title: new FormControl('', this.entity ? Validators.required : []),
            shares: new FormControl('', this.entity ? Validators.required : []),
            email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(emailPattern)]),
            mobile: new FormControl(''),
            note: new FormControl(undefined, [Validators.required]),
        });

        if (this.entity) {
            this.addIndividualForm.addControl(
                'documents',
                new FormGroup({
                    national_identity_card: new FormControl('', Validators.required),
                    proofOAddressDocument: new FormControl('', Validators.required)
                })
            );
        }
    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length > 0) {
            const filetype = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
            const file = event.target.files[0];
            this.filename[event.target.id] = file.name;
            if (filetype.includes(file.type)) {
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                const national_identity_card = event.target.id === 'national_identity_card' ? 'national_identity_card' : null;
                const proofOAddressDocument = event.target.id === 'proofOAddressDocument' ? 'proof_of_address' : null;

                this.documentUpload.push({
                    filename: file.name,
                    content_type: file.type,
                    document: reader.result,
                    type: national_identity_card || proofOAddressDocument
                });
            };
        }
    }

    onSubmit() {
        const formData = {
            ...this.addIndividualForm.value,
            password: 'isrequired',
            type: 'individual',
            is_manual_capture: this.isManualCapture
        };
        if (this.entity) {
            formData.parent_id = this.entity.id;
            formData.type = 'sub-entity-individual';
            delete formData.documents;
        }

        if (this.addIndividualForm.valid) {
            this.isLoading = true;
            this.addIndividualForm.disable();
            this.restApiService.addEntity(formData).subscribe(
                entity => {
                    this.store.dispatch(new EntityCreatedAction(entity));
                    this.responseStatus.show = true;
                    this.isLoading = true;
                    if ('parent_id' in formData) {
                        this.restApiService
                            .addEntityIndividualDocuments({
                                entity_id: entity.id,
                                documents: this.documentUpload
                            })
                            .subscribe(
                                documents => {
                                    this.responseStatus.show = true;
                                    this.isLoading = true;
                                    setTimeout(() => {
                                        this.router.navigateByUrl(`/entities/individual/${entity.id}/edit`);
                                        if (formData.is_manual_capture) {
                                            this.layoutUtilsService.showActionNotification('Success, the new individual has been added', MessageType.Read, 5000, true, false);
                                        } else {
                                            this.layoutUtilsService.showActionNotification('Success, the new individual has been added, and an email has been send', MessageType.Read, 5000, true, false);
                                        }
                                        this.activeModal.close();
                                    }, 1500);
                                },
                                error => {
                                    this.isLoading = false;
                                    this.addIndividualForm.enable();
                                    this.responseStatus.show = true;
                                    this.responseStatus.isError = true;
                                    if (!error.status) {
                                        this.responseStatus.message = 'No internet connection, please try again later';
                                    } else {
                                        this.responseStatus.message = error.message;
                                    }
                                }
                            );
                    } else {
                        this.router.navigateByUrl(`/entities/individual/${entity.id}/edit`);
                        this.layoutUtilsService.showActionNotification('Success, the individual has been update', MessageType.Read, 5000, true, false);
                        this.activeModal.close();
                    }
                },
                error => {
                    this.isLoading = false;
                    this.addIndividualForm.enable();
                    this.responseStatus.show = true;
                    this.responseStatus.isError = true;
                    if (!error.status) {
                        this.responseStatus.message = 'No internet connection, please try again later';
                    } else {
                        this.responseStatus.message = error.message;
                    }
                }
            );
        }
    }
}
