/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./menu-horizontal/menu-horizontal.component.ngfactory";
import * as i6 from "./menu-horizontal/menu-horizontal.component";
import * as i7 from "../html-class.service";
import * as i8 from "../../../core/_base/layout/services/menu-horizontal.service";
import * as i9 from "../../../core/_base/layout/services/menu-config.service";
import * as i10 from "../../../core/_base/layout/services/layout-config.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../core/_base/layout/directives/header.directive";
import * as i13 from "./topbar/topbar.component.ngfactory";
import * as i14 from "./topbar/topbar.component";
import * as i15 from "./header.component";
import * as i16 from "../../../core/_base/layout/services/layout-ref.service";
import * as i17 from "@ngx-loading-bar/core";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngb-progressbar", [["class", "kt-loading-bar"], ["height", "3px"]], null, null, null, i2.View_NgbProgressbar_0, i2.RenderType_NgbProgressbar)), i1.ɵdid(1, 49152, null, 0, i3.NgbProgressbar, [i3.NgbProgressbarConfig], { value: [0, "value"], height: [1, "height"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loader.progress$)); var currVal_1 = "3px"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-menu-horizontal", [], null, null, null, i5.View_MenuHorizontalComponent_0, i5.RenderType_MenuHorizontalComponent)), i1.ɵdid(1, 4308992, null, 0, i6.MenuHorizontalComponent, [i1.ElementRef, i7.HtmlClassService, i8.MenuHorizontalService, i9.MenuConfigService, i10.LayoutConfigService, i11.Router, i1.Renderer2, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { ktHeader: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["ktHeader", 1]], null, 12, "div", [["class", "kt-header kt-grid__item"], ["id", "kt_header"], ["ktHeader", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(4, 4210688, null, 0, i12.HeaderDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "kt-topbar", [], null, null, null, i13.View_TopbarComponent_0, i13.RenderType_TopbarComponent)), i1.ɵdid(13, 49152, null, 0, i14.TopbarComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-header kt-grid__item"; var currVal_1 = _co.htmlClassService.getClasses("header", true); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.loader.progress$)) > 0); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.menuHeaderDisplay; _ck(_v, 9, 0, currVal_3); var currVal_4 = !_co.menuHeaderDisplay; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 4308992, null, 0, i15.HeaderComponent, [i11.Router, i16.LayoutRefService, i10.LayoutConfigService, i17.LoadingBarService, i7.HtmlClassService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("kt-header", i15.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
