/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./transaction-fees-component.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../in-out-bound/in-out-bound.component.ngfactory";
import * as i3 from "../in-out-bound/in-out-bound.component";
import * as i4 from "../utils/state/account-fees-state.service";
import * as i5 from "../balance-fee/balance-fee.component.ngfactory";
import * as i6 from "../balance-fee/balance-fee.component";
import * as i7 from "@angular/common";
import * as i8 from "./transaction-fees-component.component";
import * as i9 from "../../../../../services/rest-api.service";
var styles_TransactionFeesComponentComponent = [i0.styles];
var RenderType_TransactionFeesComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TransactionFeesComponentComponent, data: {} });
export { RenderType_TransactionFeesComponentComponent as RenderType_TransactionFeesComponentComponent };
function View_TransactionFeesComponentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "transaction"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kt-in-out-bound", [], null, [[null, "valid"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valid" === en)) {
        var pd_0 = (_co.onValid($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_InOutBoundComponent_0, i2.RenderType_InOutBoundComponent)), i1.ɵdid(2, 114688, null, 0, i3.InOutBoundComponent, [i4.AccountFeesStateService], { paymentRail: [0, "paymentRail"], readOnly: [1, "readOnly"] }, { valid: "valid" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.readOnly; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TransactionFeesComponentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-balance-fee", [], null, [[null, "balanceOutput"], [null, "valid"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("balanceOutput" === en)) {
        var pd_0 = (_co.onBalanceOutput() !== false);
        ad = (pd_0 && ad);
    } if (("valid" === en)) {
        var pd_1 = (_co.onValid($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_BalanceFeeComponent_0, i5.RenderType_BalanceFeeComponent)), i1.ɵdid(1, 114688, null, 0, i6.BalanceFeeComponent, [i4.AccountFeesStateService], { currency: [0, "currency"], readOnly: [1, "readOnly"] }, { balanceOutput: "balanceOutput" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currency; var currVal_1 = _co.readOnly; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TransactionFeesComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TransactionFeesComponentComponent_1)), i1.ɵdid(1, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TransactionFeesComponentComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.paymentRails)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.balanceCurrency.includes(_co.currency); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TransactionFeesComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-transaction-fees-component", [], null, null, null, View_TransactionFeesComponentComponent_0, RenderType_TransactionFeesComponentComponent)), i1.ɵdid(1, 114688, null, 0, i8.TransactionFeesComponentComponent, [i9.RestApiService, i4.AccountFeesStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TransactionFeesComponentComponentNgFactory = i1.ɵccf("kt-transaction-fees-component", i8.TransactionFeesComponentComponent, View_TransactionFeesComponentComponent_Host_0, { currency: "currency", entityId: "entityId", readOnly: "readOnly", user: "user" }, { valid: "valid" }, []);
export { TransactionFeesComponentComponentNgFactory as TransactionFeesComponentComponentNgFactory };
