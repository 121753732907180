import { Entity } from './../../../../../models/entity';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { emailPattern, nameRegex, ukPostalCodePattern } from '../../../../../shared/helpers';
import { countries, states } from './../../../../../shared/countries';
import { MatCheckboxChange, MatSelectChange } from '@angular/material';
import { AppState } from '../../../../../store/reducers';
import { Store } from '@ngrx/store';
import { RestApiService } from '../../../../../services/rest-api.service';
import { Router } from '@angular/router';

interface IDocument {
    document: any;
    filename: string;
    content_type: string;
    type: string;
}
@Component({
    selector: 'kt-add-individual-shareholder-modal-form',
    templateUrl: './add-individual-shareholder-modal-form.component.html',
    styleUrls: ['./add-individual-shareholder-modal-form.component.scss']
})
export class AddIndividualShareholderModalFormComponent implements OnInit {
    addIndividualForm: FormGroup;
    addAddressForm: FormGroup;
    addShareholderDetailsForm: FormGroup;
    countries = countries;
    states = states;
    isLinear = true;
    entity: Entity;
    entityId: string;
    isLoading = false;
    hasFormErrors = false;
    errorMessage: string;
    responseStatus = {
        show: false,
        isError: false,
        message: 'Individual has been added successfully'
    };
    filename = {
        national_identity_card: 'Choose file',
        proofOAddressDocument: 'Choose file'
    };
    documentUpload: IDocument[] = [];
    constructor(
        public activeModal: NgbActiveModal,
        private store: Store<AppState>,
        private restApiService: RestApiService,
        private router: Router
    ) { }

    ngOnInit() {
        this.addIndividualForm = new FormGroup({
            first_name: new FormControl('', [
                Validators.required,
                Validators.pattern(nameRegex)
            ]),
            middle_names: new FormControl('', Validators.pattern(nameRegex)),
            last_name: new FormControl('', [
                Validators.required,
                Validators.pattern(nameRegex)
            ]),
            email: new FormControl('', [
                Validators.required,
                Validators.email,
                Validators.pattern(emailPattern)
            ]),
            type: new FormControl('', [Validators.required]),
            mobile: new FormControl('')
        });

        this.addAddressForm = new FormGroup({
            building_number: new FormControl(''),
            building_name: new FormControl(''),
            street: new FormControl('', [Validators.required]),
            sub_street: new FormControl(''),
            city: new FormControl(null, [Validators.required]),
            postcode: new FormControl(null, [Validators.required]),
            country: new FormControl(null, [Validators.required])
        });
        this.addShareholderDetailsForm = new FormGroup({
            individual: this.addIndividualForm,
            address: this.addAddressForm
        });
    }

    onTypeChange(event: MatSelectChange) {
        if (event.value === 'director') {
            this.addIndividualForm.addControl(
                'shareholder',
                new FormControl(false, [Validators.required]),
            );
        } else {
            this.addIndividualForm.removeControl('shares');
        }

        if (event.value === 'shareholder') {
            this.addIndividualForm.addControl(
                'shares',
                new FormControl('', [Validators.required, Validators.min(1)])
            );
        } else {
            this.addIndividualForm.removeControl('shares');
        }


    }

    onIsShareholder($event: MatCheckboxChange): void {
        if ($event.checked) {
            this.addIndividualForm.addControl(
                'shares',
                new FormControl('', [Validators.required, Validators.min(1)])
            );
        } else {
            this.addIndividualForm.removeControl('shares');
        }
    }

	/**
	 * Close Alert
	 *
	 * @param $event: Event
	 */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    onCountryChange(selected: MatSelectChange) {
        switch (selected.value) {
            case 'GB':
                this.addAddressForm.controls.postcode.setValidators(
                    Validators.pattern(ukPostalCodePattern)
                );
                this.addAddressForm.controls.street.setValidators(
                    Validators.maxLength(32)
                );
                this.addAddressForm.controls.postcode.updateValueAndValidity();
                this.addAddressForm.removeControl('state');
                break;
            case 'US':
                this.addAddressForm.addControl(
                    'state',
                    new FormControl(null, Validators.required)
                );
                this.addAddressForm.controls.postcode.setValidators(
                    Validators.required
                );
                this.addAddressForm.controls.street.setValidators(
                    Validators.required
                );
                this.addAddressForm.controls.postcode.updateValueAndValidity();
                this.addAddressForm.controls.street.updateValueAndValidity();
                break;
            default:
                this.addAddressForm.controls.postcode.clearValidators();
                this.addAddressForm.controls.street.clearValidators();
                this.addAddressForm.removeControl('state');
                this.addAddressForm.controls.postcode.setValidators(
                    Validators.required
                );
                this.addAddressForm.controls.street.setValidators(
                    Validators.required
                );
                this.addAddressForm.controls.postcode.updateValueAndValidity();
                this.addAddressForm.controls.street.updateValueAndValidity();
                break;
        }
    }

    onFileChange(event) {
        const reader = new FileReader();

        if (event.target.files && event.target.files.length > 0) {
            const filetype = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
            const file = event.target.files[0];
            this.filename[event.target.id] = file.name;
            if (filetype.includes(file.type)) {
            }
            reader.readAsDataURL(file);
            reader.onload = () => {
                const national_identity_card = event.target.id === 'national_identity_card' ? 'national_identity_card' : null;
                const proofOAddressDocument = event.target.id === 'proofOAddressDocument' ? 'proof_of_address' : null;

                this.documentUpload.push({
                    filename: file.name,
                    content_type: file.type,
                    document: reader.result,
                    type: national_identity_card || proofOAddressDocument
                });
            };
        }
    }

    onSubmit() {
        if (this.addShareholderDetailsForm.valid) {
            this.isLoading = true;
            this.addShareholderDetailsForm.disable();
            this.restApiService.createSubIndividualEntity({
                ...this.addShareholderDetailsForm.value,
                parent_id: this.entity.id,
            })
                .subscribe(
                    entity => {
                        if (this.documentUpload.length) {
                            this.restApiService
                                .addEntityIndividualDocuments({
                                    entity_id: entity.id,
                                    documents: this.documentUpload
                                })
                                .subscribe(
                                    documents => {
                                        this.responseStatus.show = true;
                                        this.isLoading = true;
                                        setTimeout(() => {
                                            this.router.navigateByUrl(`/entities/individual/${entity.id}/edit`);
                                            this.activeModal.close(entity);
                                        }, 1500);
                                    },
                                    error => {
                                        this.isLoading = false;
                                        this.addIndividualForm.enable();
                                        this.responseStatus.show = true;
                                        this.responseStatus.isError = true;
                                        if (!error.status) {
                                            this.responseStatus.message = 'No internet connection, please try again later';
                                        } else {
                                            this.responseStatus.message = error.message;
                                        }
                                    }
                                );
                        } else {
                            this.router.navigateByUrl(`/entities/individual/${entity.id}/edit`);
                            this.activeModal.close(entity);
                        }
                    },
                    error => {
                        this.isLoading = false;
                        this.addShareholderDetailsForm.enable();
                        if (!error.status) {
                            this.responseStatus.message =
                                'No internet connection, please try again later';
                        } else {
                            this.responseStatus.message = error.error.message;
                        }
                        this.responseStatus.isError = true;
                        this.responseStatus.show = true;
                    }
                );
        }
    }
}
