import { Component, Inject, OnInit } from "@angular/core";
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {Entity} from '../../../../../models/entity';
import {Accounts as LegacyAccounts} from '../../../../../models/legacy-accounts';
import {RestApiService} from '../../../../../services/rest-api.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {emailPattern} from "../../../../../shared/helpers";
import {catchError, finalize, map, switchMap, tap} from "rxjs/operators";
import {EmployeeContact} from "./interfaces/employee-contact.dto";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'kt-welcome-pack-modal',
    templateUrl: './welcome-pack-modal.component.html',
    styleUrls: ['./welcome-pack-modal.component.scss']
})
export class WelcomePackModalComponent implements OnInit {

    data: Entity;
    accountsList: Array<LegacyAccounts> = [];
    selectedAccounts: Set<LegacyAccounts> = new Set<LegacyAccounts>();
    isUpdatedPack = undefined;
    loading = true;
    currentStep: number = 0;
    maxStepIndex: number = 2;
    noDedicatedContacts: boolean = false;
    contactsFormGroup: FormGroup
    contactGroupings: Array<{ key: string, label: string }> = []
    contactsList: Array<EmployeeContact> = []
    emailControl: FormControl
    emailBodyControl: FormControl
    emailsList: Array<string> = []
    customEmailBody: boolean = false

    constructor(
      public activeModal: NgbActiveModal,
                private readonly restApiService: RestApiService) {
        this.contactsFormGroup = new FormGroup({})
        this.emailControl = new FormControl(undefined, Validators.pattern(emailPattern))
        this.emailBodyControl = new FormControl(undefined)
    }

    ngOnInit() {
        this.restApiService.getAllEntitiesAccount(this.data.id)
            .pipe(
                tap((accounts) => this.accountsList = accounts.filter((account) => account.status === 'ACTIVE'
                  && account.ccy !== 'USD')),
                switchMap(() => this.restApiService.getAllEmployeeContacts()),
                tap((contacts: Array<EmployeeContact>) => {
                    this.contactsList = contacts.sort(this.sortContactGroupings)
                    this.contactGroupings = Array.from(
                        new Set(contacts.map(contact => contact.grouping))
                    )
                      .map((grouping) => {
                        const _grouping = {
                            key: grouping,
                            label: grouping.replace(/_/g, ' ')
                                .toLowerCase()
                                .replace(/\b\w/g, match => match.toUpperCase())
                        }

                        this.contactsFormGroup.addControl(_grouping.key, new FormControl(undefined, Validators.required));
                        return _grouping
                    })
                }),
                catchError(() => {
                    this.loading = false;
                    return [];
                }),
                finalize(() => this.loading = false)
            )
            .subscribe();
    }

    onContactCheckboxChange(checked: boolean): void {
        if (checked) {
            this.contactsFormGroup.disable()
        } else {
            this.contactsFormGroup.enable()
        }
        this.noDedicatedContacts = checked
    }

    onSelectAllAccounts(): void {
        if (this.selectedAccounts.size === this.accountsList.length) {
            this.selectedAccounts.clear()
        } else {
            this.accountsList.forEach((account) => this.onCheckChanged(account, true));
        }
    }

    onCheckChanged(account: any, checked: any): void {
        if (checked) {
            this.selectedAccounts.add(account);
        } else {
            this.selectedAccounts.delete(account);
        }
    }

    getModalTitle(): string {
        switch (this.currentStep) {
            case 0:
                return 'Select contacts';
            case 1:
                return 'Select accounts';
            case 2:
                return 'Send email';
        }
    }

    canProceed(step: number = this.currentStep): boolean {
        if(this.loading) {
            return false
        }

        switch (step) {
            case 0:
                return this.contactsFormGroup.disabled
                    || this.contactsFormGroup.valid;
            case 1:
                return this.selectedAccounts.size > 0;
            case 2:
                return this.canProceed(0) &&
                    this.canProceed(1)
                    && this.isUpdatedPack !== undefined
                    && this.emailsList.length > 0
                    && (!this.customEmailBody || String(this.emailBodyControl.value).length > 0 );
            default:
                return true;
        }
    }

    onNextClicked(): void {
        if (this.currentStep === this.maxStepIndex) {
            this.activeModal.close({
                selectedAccounts: Array.from(this.selectedAccounts),
                isUpdatedPack: this.isUpdatedPack,
                contacts: this.getContacts(),
                noDedicatedContacts: this.noDedicatedContacts,
                emails: this.emailsList,
                emailBody: this.customEmailBody ? this.emailBodyControl.value : undefined,
            });
        } else {
            this.currentStep++;
        }
    }

    onBackClicked(): void {
        if (this.currentStep > 0)
            this.currentStep--;
    }

    getContactsByRelationship(grouping: string): Array<EmployeeContact> {
        return this.contactsList.filter((contact) => contact.grouping === grouping);
    }

    onAddEmail(): void {
        const value = this.emailControl.value.trim();

        this.emailControl.markAsTouched()
        if (this.emailControl.valid) {
            const _emailsList = new Set(this.emailsList);
            _emailsList.add(value);
            this.emailControl.reset()

            this.emailsList = Array.from(_emailsList);
        }

    }
    onRemoveEmail(email: string): void {
        const _emailsList = new Set(this.emailsList);
        _emailsList.delete(email);

        this.emailsList = Array.from(_emailsList);
    }

    onCustomEmailBodyChange(value: boolean): void {
        this.customEmailBody = value;
        if(!this.emailBodyControl.dirty) {
            const latestAccount = Array.from(this.selectedAccounts).sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))[0]

            console.log({
                latestAccount,
                entity: this.data
            });

            // NB: Don't change the formatting of this string, it's displayed as is in the email body
            const defaultBody = `Hi ${this.data.first_name} ${this.data.last_name}

Please find attached a copy of your ${this.isUpdatedPack ? "updated " : ''}welcome pack for ${this.data.entity_name} with your new ${latestAccount.ccy} account.

We would also like to create a chat group for you on Slack, Telegram or WhatsApp to communicate with us.

Please let me know which one you would prefer.

Kind Regards
CS Onboarding Team`;

            this.emailBodyControl.setValue(defaultBody)
        }
    }

    private getContacts(): Record<string, any> {
        if (this.contactsFormGroup.disabled) {
            return undefined
        }

        const contacts = this.contactsFormGroup.value
        const _contactGroupings = Object.keys(contacts).reduce((acc, grouping) => {
            if (contacts[grouping] !== 'no_value') {
                acc.push(contacts[grouping])
            }
            return acc
        }, [])

        if(!_contactGroupings.length) {
            this.onContactCheckboxChange(true)
            return undefined
        }

        return _contactGroupings
    }

    private sortContactGroupings(a: EmployeeContact, b: EmployeeContact): number {
        enum order {
            SENIOR_RELATIONSHIP_MANAGEMENT = 1,
            RELATIONSHIP_MANAGEMENT = 2,
            IMPLEMENTATIONS_TEAM = 3,
            CUSTOMER_SUCCESS_TEAM = 4,
        }
        const _a = order[a.grouping] || 999;
        const _b = order[b.grouping] || 999;
        return _a - _b;
    }
}
