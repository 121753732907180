<form [formGroup]='entityDetailsForm'
      (validSubmit)='onSubmit(isManualCapture)'>
    <div class='modal-header'>
        <h4 class='modal-title'>Add Corporate {{ entity ? "to " + entity.entity_name : "" }}</h4>
        <button type='button' class='close' aria-label='Close' (click)="activeModal.close('Cross click')">
            <span aria-hidden='true'>&times;</span>
        </button>
    </div>
    <div class='modal-body'>
        <div class='kt-form kt-form--label-right'>
            <div class='kt-form__body'>
                <div
                    class='alert alert-bold'
                    [ngClass]="{ 'alert-solid-success': !responseStatus.isError, 'alert-solid-danger': responseStatus.isError }"
                    *ngIf='responseStatus.show'
                    id='thanksalot'
                >
                    {{ responseStatus.message }}
                </div>
                <div class='kt-section kt-section--first'>
                    <div class='kt-section__body'>
                        <mat-vertical-stepper [linear]='true' (selectionChange)='selectionChange($event)' #stepper>
                            <mat-step [stepControl]='entityDetailsForm' errorMessage='Fill all required fields.'>
                                <ng-template matStepLabel>Entity Details</ng-template>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Entity Name</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <input
                                            class='form-control'
                                            [ngClass]="{
                                        'is-invalid':
                                            entityDetailsForm.get('entity_name').invalid &&
                                            (entityDetailsForm.get('entity_name').dirty || entityDetailsForm.get('entity_name').touched),
                                        'is-valid': entityDetailsForm.get('entity_name').valid,
                                        '': false
                                    }"
                                            type='text'
                                            formControlName='entity_name'
                                            placeholder='Entity Name'
                                        />
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Deal ID</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <input
                                            class='form-control'
                                            [ngClass]="{
                                        'is-invalid':
                                            entityDetailsForm.get('hubspot_deal').invalid &&
                                            (entityDetailsForm.get('hubspot_deal').dirty || entityDetailsForm.get('hubspot_deal').touched),
                                        'is-valid': entityDetailsForm.get('hubspot_deal').valid,
                                        '': false
                                    }"
                                            type='text'
                                            formControlName='hubspot_deal'
                                            placeholder='Hubspot deal id'
                                            (blur)='validateHubSpotDealId()'
                                        />
                                        <div class='invalid-feedback'
                                             *ngIf="entityDetailsForm.controls['hubspot_deal'].hasError('not_found')">
                                            Deal Id is not valid
                                        </div>
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='col-10 offset-1 mb-4'>
                                        <h5 class='modal-title'>Contact Details</h5>
                                        <hr />
                                    </div>
                                </div>

                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>First Name</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <input
                                            class='form-control'
                                            [ngClass]="{
                                        'is-invalid':
                                            entityDetailsForm.get('first_name').invalid &&
                                            (entityDetailsForm.get('first_name').dirty || entityDetailsForm.get('first_name').touched),
                                        'is-valid': entityDetailsForm.get('first_name').valid,
                                        '': false
                                    }"
                                            type='text'
                                            formControlName='first_name'
                                            placeholder='First Name'
                                        />
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Last Name</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <input
                                            class='form-control'
                                            [ngClass]="{
                                        'is-invalid':
                                            entityDetailsForm.get('last_name').invalid &&
                                            (entityDetailsForm.get('last_name').dirty || entityDetailsForm.get('last_name').touched),
                                        'is-valid': entityDetailsForm.get('last_name').valid,
                                        '': false
                                    }"
                                            type='text'
                                            formControlName='last_name'
                                            placeholder='Last Name'
                                        />
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Mobile</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <div class='input-group'>
                                            <international-phone-input
                                                name='mobile'
                                                class='form-control'
                                                placeholder='Phone'
                                                [preferredCountries]="['gb', 'ch', 'us']"
                                                thousandSeparator=' '
                                                formControlName='mobile'
                                            >
                                            </international-phone-input>
                                        </div>
                                        <bfv-messages></bfv-messages>
                                        <mat-error *ngIf="entityDetailsForm.controls['mobile']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
                                    </div>
                                </div>
                                <div class='form-group row' *ngIf='entity'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>% Shares</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <input class='form-control' type='text' mask='percent' suffix='%' formControlName='shares' placeholder='00%' />
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Email</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <input
                                            class='form-control'
                                            [ngClass]="{
                                        'is-invalid':
                                            entityDetailsForm.get('email').invalid &&
                                            (entityDetailsForm.get('email').dirty || entityDetailsForm.get('email').touched),
                                        'is-valid': entityDetailsForm.get('email').valid,
                                        '': false
                                    }"
                                            type='email'
                                            formControlName='email'
                                            placeholder='Email'
                                        />
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='col-10 offset-1'>
                                        <h5 class='modal-title'>Corporate Details</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Industry Type</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <mat-form-field class='w-100' appearance='outline'>
                                            <mat-select formControlName='industry_type'>
                                                <mat-option [value]='' disabled>- select -</mat-option>
                                                <mat-option *ngFor='let industry of industryTypes' [value]='industry.id'>
                                                    {{ industry.description }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Products</label>
                                    <div class='col-lg-9 col-xl-6'>
                                        <mat-form-field class='w-100' appearance='outline'>
                                            <mat-select (selectionChange)='onProductSelect($event.value)' formControlName='products' multiple>
                                                <mat-option [value]='' disabled>- select -</mat-option>
                                                <mat-option *ngFor='let product of products' [value]='product.id'>
                                                    {{ product.description }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-5 col-lg-5 col-form-label'>Is this company a crypto company?</label>
                                    <div class='col-lg-6 col-xl-6 d-flex align-items-center'>
                                        <mat-radio-group formControlName='crypto' aria-label='Select an option'>
                                            <mat-radio-button [value]='true'>Yes</mat-radio-button>
                                            <mat-radio-button [value]='false'>No</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                                <div class='form-group row'>
                                    <label class='col-xl-3 col-lg-3 col-form-label'>Notes</label>
                                    <div class='col-lg-9 col-xl-6'>
                                <textarea
                                    class='form-control'
                                    rows='5'
                                    [ngClass]="{
                                        'is-invalid':
                                            entityDetailsForm.get('note').invalid &&
                                            (entityDetailsForm.get('note').dirty || entityDetailsForm.get('note').touched),
                                        'is-valid': entityDetailsForm.get('note').valid,
                                        '': false
                                    }"
                                    type='text'
                                    formControlName='note'
                                ></textarea>
                                        <bfv-messages></bfv-messages>
                                    </div>
                                </div>

                                <div class='row'>
                                    <div class='col-10 offset-1'>
                                        <h5 class='modal-title'>Accounts</h5>
                                        <hr />
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class='col-10 offset-1'>
                                        <div *ngIf='accounts.length === 0; else accountTabs'>
                                            <p class='d-flex align-items-center justify-content-center p-pointer' (click)='onAddAccount()'>Click here to add an
                                                account</p>
                                        </div>
                                        <ng-template #accountTabs>
                                            <mat-tab-group [selectedIndex]='activeAccountTabIndex' (selectedIndexChange)='onAccountTabChange($event)'>
                                                <mat-tab *ngFor='let account of accounts; let i = index'
                                                         [label]="account.currency ? account.currency + ' | ' + i : 'New account'">
                                                    <form [formGroup]='newAccountForm'>
                                                        <div class='row'>
                                                            <div class='col-md-6'>
                                                                <div class='form-group mt-2'>
                                                                    <mat-label>Currency</mat-label>
                                                                    <mat-form-field class='w-100' appearance='outline'>
                                                                        <mat-select formControlName='currency'>
                                                                            <mat-option [value]='' disabled>- select -</mat-option>
                                                                            <mat-optgroup *ngFor='let group of currencyGroup' [label]='group.name'
                                                                                          [disabled]='group.disabled'>
                                                                                <mat-option *ngFor='let currency of group.currency' [value]='currency'>
                                                                                    {{currency}}
                                                                                </mat-option>
                                                                            </mat-optgroup>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                            <div class='col-md-6'>
                                                                <div class='form-group mt-2'>
                                                                    <mat-label>Effective Date</mat-label>
                                                                    <mat-form-field class='w-100'
                                                                                    appearance='outline'>
                                                                        <input [min]='startDate'
                                                                               (click)='picker.open()'
                                                                               (focus)='picker.open()'
                                                                               [matDatepickerFilter]='dateFilter' formControlName='effectiveDate' matInput
                                                                               readonly
                                                                               [matDatepicker]='picker'>
                                                                        <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
                                                                        <mat-datepicker #picker startView='year'></mat-datepicker>
                                                                    </mat-form-field>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class='row'>
                                                            <div class='col'>
                                                                <button type='button'
                                                                        class='mr-2'
                                                                        mat-stroked-button
                                                                        [disabled]='disableAccountDeleteBtn()'
                                                                        (click)='onRemoveAccount()'>Delete
                                                                </button>
                                                                <button [disabled]='newAccountForm.invalid'
                                                                        type='button'
                                                                        mat-stroked-button
                                                                        color='primary'
                                                                        (click)='onSaveAccount()'>Save account
                                                                </button>
                                                            </div>
                                                            <div class='col'>
                                                                <button [disabled]='newAccountForm.invalid'
                                                                        type='button'
                                                                        class='pull-right'
                                                                        mat-stroked-button color='primary'
                                                                        (click)='onAddAccount()'>Add account
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </mat-tab>
                                            </mat-tab-group>
                                        </ng-template>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step *ngIf='isAccountsSelected()'
                                      [completed]="generalFeesValid | async"
                                      [stepControl]='generalFeesForm'
                                      errorMessage='Fill all required fields.'>
                                <ng-template matStepLabel>General Fees</ng-template>
                                <div class='row'>
                                    <div class='col-11 offset-0.5'>
                                        <div class='accounts_scope'>
                                            <div *ngIf='accounts.length'
                                                 class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> Accounts in Scope</div>
                                                </div>
                                                <div class='col'>
                                                    <div *ngFor='let account of getAccounts()'
                                                         class='row account_row'>
                                                        <div class='col-5 currency'>{{account.currency}}</div>
                                                        <div class='col effectiveDate'>
                                                            <div class='row'>
                                                                <div class='col description'>Effective Date</div>
                                                                <div class='col date'>{{ account.effectiveDate | formatDate }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form [formGroup]='generalFeesForm' class='general_form'>
                                            <div class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> Setup Fee</div>
                                                </div>
                                                <div class='col'>
                                                    <div class='row account_row'>
                                                        <div class='col-4 amount'><input (keyup)='removeInvalidCharacters($event)'
                                                                   (blur)='onUpdateGeneralFees()'
                                                                   class='form-control fixed'
                                                                   formControlName='setup_fee'
                                                                   type='number'
                                                                   min='0'
                                                                   /></div>
                                                        <div class='col effective_period'>
                                                            {{generalFees[0]?.effectivePeriod}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> Management Fee</div>
                                                </div>
                                                <div class='col'>
                                                    <div class='row account_row'>
                                                        <div class='col-4 amount'><input (keyup)='removeInvalidCharacters($event)'
                                                                   (blur)='onUpdateGeneralFees()'
                                                                   class='form-control fixed'
                                                                                         formControlName='management_fee'
                                                                                         type='number' min='0' /></div>
                                                        <div class='col effective_period'>
                                                            {{generalFees[1]?.effectivePeriod}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> Minimum Transaction Fees</div>
                                                </div>
                                                <div class='col'>
                                                    <div class='row account_row'>
                                                        <div class='col amount'><input (keyup)='removeInvalidCharacters($event)'
                                                                   (blur)='onUpdateGeneralFees()'
                                                                   class='form-control fixed'
                                                                                       formControlName='min_transaction_fees'
                                                                                       type='number' min='0' /></div>
                                                        <div class='col effective_period'>
                                                            {{generalFees[2]?.effectivePeriod}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> General Exceptions</div>
                                                </div>
                                                <div class='col'>
                                                    <div class='row account_row'>
                                                        <div class='col-6 amount'>
                                                            <div class='row small'>
                                                                <input (keyup)='removeInvalidCharacters($event)'
                                                                       (blur)='onUpdateGeneralFees()'
                                                                       class='col form-control variable'
                                                                       formControlName='general_exceptions' type='number'
                                                                       value='75' />
                                                                <div class='effective_hours'>per Hour</div>
                                                            </div>
                                                        </div>
                                                        <div class='col effective_period'>
                                                            {{generalFees[3]?.effectivePeriod}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> Fraud Claim Exceptions</div>
                                                </div>
                                                <div class='col'>
                                                    <div class='row account_row'>
                                                        <div class='col-6 amount'>
                                                            <div class='row small'>
                                                                <input (keyup)='removeInvalidCharacters($event)'
                                                                       (blur)='onUpdateGeneralFees()'
                                                                       class='col form-control variable'
                                                                       formControlName='fraud_claim_exceptions' type='number'
                                                                       min='0' />
                                                                <div class='effective_hours'>per Hour</div>
                                                            </div>
                                                        </div>
                                                        <div class='col effective_period'>
                                                            {{generalFees[4]?.effectivePeriod}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class='row'>
                                                <div class='col-4'>
                                                    <div class='section_name'> Minimum term</div>
                                                </div>
                                                <div class='col'>
                                                    <div class='row account_row'>
                                                        <div class='col-6 amount'>
                                                            <div class='row small'>
                                                                <input (keydown)='removeTermInvalidCharacters($event)'
                                                                       class='col form-control variable'
                                                                       formControlName='minimum_term' type='number' min='0' max='12'/>
                                                                <div class='effective_hours'>Months</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step *ngIf='isAccountsSelected()'
                                      [completed]="isTransactionFeesComplete()"
                                      [stepControl]='transactionalFeesForm'
                                      errorMessage='Validation failed'>
                                <ng-template matStepLabel>Transaction Fees</ng-template>
                                <div class='row'>
                                    <div class='col-11 offset-0.5'>
                                        <div *ngFor='let currency of getUniqueCurrencies()'
                                             class='payrail row'>
                                            <div class='col-1 account_ccy'>{{ currency }}</div>
                                            <kt-transaction-fees-component class='col'
                                                                           [currency]='currency'></kt-transaction-fees-component>
                                        </div>
                                    </div>
                                </div>
                            </mat-step>
                            <mat-step *ngIf='isAccountsSelected()'
                                      [completed]="canSubmit()"
                                      [stepControl]='checkForm'
                                      errorMessage='Fill all required fields.'>
                                <ng-template matStepLabel>Confirm Fees</ng-template>
                                <form [formGroup]='checkForm'>
                                    <div class='confirm-section row'>General Fees</div>
                                    <div class='row'>
                                        <div>Setup Fees:</div>
                                        <div class='fee-value'>£{{generalFeesForm.controls.setup_fee.value}} Once off</div>
                                    </div>
                                    <div class='row'>
                                        <div>Management Fee:</div>
                                        <div class='fee-value'>£{{generalFeesForm.controls.management_fee.value}} Per month</div>
                                    </div>
                                    <div class='row'>
                                        <div>Minimum Transaction Fee:</div>
                                        <div class='fee-value'>£{{generalFeesForm.controls.min_transaction_fees.value}} per Transaction</div>
                                    </div>
                                    <div class='row'>
                                        <div>General Exception Fee</div>
                                        <div class='fee-value'>£{{generalFeesForm.controls.general_exceptions.value}} Hourly</div>
                                    </div>
                                    <div class='row'>
                                        <div>Fraud Claim Exceptions Fee:</div>
                                        <div class='fee-value'>£{{generalFeesForm.controls.fraud_claim_exceptions.value}} Hourly</div>
                                    </div>
                                    <div class='row'>
                                        <div>Minimum Term:</div>
                                        <div class='fee-value'>{{generalFeesForm.controls.minimum_term.value}} Months</div>
                                    </div>
                                    <div class='confirm-section row'>Transaction Fees</div>
                                    <br />
                                    <kt-transactions-confirmation class='col'></kt-transactions-confirmation>
                                    <mat-checkbox (change)='checkedChanged($event)'
                                                  value='false'
                                                  checked='false'
                                                  formControlName='confirmedFees'
                                                  class='row confirm-fees'>Confirm
                                    </mat-checkbox>
                                </form>
                            </mat-step>

                        </mat-vertical-stepper>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class='modal-footer'>
        <button type='button' class='btn btn-secondary mr-auto' (click)="closeAccountModal()">Close</button>
        <div class='stepper_buttons'>
            <button class='pull-right' style='margin-left: 16px' *ngIf='isAccountsSelected() && stepper.selectedIndex < 3' mat-flat-button
                    (click)='stepper.next()' color='accent'>Continue
            </button>
            <button type='button' class='pull-right' *ngIf='isAccountsSelected() && stepper.selectedIndex > 0' mat-button mat-flat-button
                    (click)='stepper.previous()'>Back
            </button>
        </div>
        <div *ngIf='showSubmitButtons(stepper.selectedIndex)'
             class='final_buttons'>
            <button type='submit'
                    class='btn btn-outline-primary'
                    [disabled]='!canSubmit()'
                    (click)='onSubmit(true)'
                    *ngIf='!isLoading'>Manually Onboard</button>
            <button type='submit'
                    class='btn btn-primary'
                    [disabled]='!canSubmit()'
                    (click)='onSubmit(false)'
                    *ngIf='!isLoading'>Send Invitation</button>
        </div>
        <button *ngIf='isLoading' type='button' class='btn btn-primary' disabled>
            <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
            Loading...
        </button>
    </div>
</form>
