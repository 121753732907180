<div class="modal-header">
    <h4 class="modal-title">Add individual</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="kt-form kt-form--label-right">
        <div class="kt-form__body">
            <div class="alert alert-bold"
                [ngClass]="{ 'alert-solid-success': !responseStatus.isError, 'alert-solid-danger': responseStatus.isError }"
                *ngIf="responseStatus.show" id="thanksalot">
                {{ responseStatus.message }}
            </div>
        </div>
        <mat-horizontal-stepper [linear]="isLinear" #stepper>
            <mat-step [stepControl]="addIndividualForm">
                <ng-template matStepLabel>Individual shareholder Details</ng-template>
                <form [formGroup]="addIndividualForm">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10">
                            <h3 class="kt-heading kt-heading--md">Enter your Individual shareholder Details</h3>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>First name</mat-label>
                                <input formControlName="first_name" matInput placeholder="John">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->

                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Middle Name(s)</mat-label>
                                <input formControlName="middle_names" matInput placeholder="Mike">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Last Name</mat-label>
                                <input formControlName="last_name" matInput placeholder="Doe">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input formControlName="email" matInput placeholder="name@company.com">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Mobile</mat-label>
                                <!-- <div class="input-group">

                            </div> -->
                                <international-phone-input matInput name="mobile" placeholder="Phone"
                                    [preferredCountries]="['gb', 'ch', 'us']" thousandSeparator=" "
                                    formControlName="mobile">
                                </international-phone-input>
                                <mat-error *ngIf="addIndividualForm.controls['mobile']?.errors?.validatePhoneNumber">
                                    Invalid Number</mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Type</mat-label>
                                <mat-select (selectionChange)="onTypeChange($event)" formControlName="type">
                                    <mat-option value="director">
                                        Director
                                    </mat-option>
                                    <mat-option value="shareholder">
                                        Shareholder
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <input matInput placeholder="Input" style="display: none">
                            <mat-checkbox class="d-inline-block mb-3" *ngIf="addIndividualForm.get('shareholder')" formControlName="shareholder"
                                (change)="onIsShareholder($event)">Is this director a shareholder?</mat-checkbox>
                            <mat-form-field class="w-100" appearance="outline" *ngIf="addIndividualForm.get('shares')">
                                <mat-label>% Shares</mat-label>
                                <input formControlName="email" mask="percent" suffix="%" formControlName="shares"
                                    placeholder="00%" matInput>
                                <i matSuffix style="font-size: 1.5rem;" class="fa fa-percent"></i>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-10 offset-md-1 mb-4">
                            <h5>Verification Documents</h5>
                            <hr />
                        </div>
                        <div class="col-12 col-lg-10 col-xl-10">
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label" for="national_identity_card">ID
                                    Document</label>
                                <div class="col-lg-9">
                                    <div class=" custom-file">
                                        <input type="file" class="custom-file-input" (change)="onFileChange($event)"
                                            id="national_identity_card" />
                                        <label class="custom-file-label text-left" for="national_identity_card">{{ filename.national_identity_card }}</label>
                                    </div>
                                    <bfv-messages></bfv-messages>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-form-label" for="proofOAddressDocument">Proof of
                                    Address</label>
                                <div class="col-lg-9">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" (change)="onFileChange($event)"
                                            id="proofOAddressDocument" />
                                        <label class="custom-file-label text-left" for="proofOAddressDocument">{{ filename.proofOAddressDocument }}</label>
                                    </div>
                                    <bfv-messages></bfv-messages>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="text-right">
                        <button type="button"
                            class="btn btn-label-danger btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                            (click)="activeModal.close('Close click')">Cancel</button>
                        <button type="button"
                            class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                            matStepperNext>Next</button>
                    </div>
                </form>

            </mat-step>
            <mat-step [stepControl]="addAddressForm">
                <!-- <div formGroupName="address"> -->
                <ng-template matStepLabel>Fill out your address</ng-template>
                <form [formGroup]="addAddressForm" (submit)="onSubmit()">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10 col-xl-10">
                            <h3 class="kt-heading kt-heading--md">Enter your Individual shareholder Details</h3>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Building number</mat-label>
                                <input formControlName="building_number" matInput placeholder="123">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Building name</mat-label>
                                <input formControlName="building_name" matInput placeholder="The Shard">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Address Line 1</mat-label>
                                <input formControlName="street" matInput placeholder="Letsby Avenue">
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Address Line 2</mat-label>
                                <input formControlName="sub_street" matInput>
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>

                            <mat-form-field *ngIf="addAddressForm.get('state')" class="w-100" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select formControlName="state">
                                    <mat-option *ngFor="let state of states" [value]="state.code">
                                        {{ state.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>City</mat-label>
                                <input formControlName="city" matInput>
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Postcode</mat-label>
                                <input formControlName="postcode" matInput>
                                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                            </mat-form-field>

                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select formControlName="country" (selectionChange)=onCountryChange($event)>
                                    <mat-option *ngFor="let country of countries" [value]="country.code">
                                        {{ country.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                    </div>
                    <hr>
                    <div class="text-right">
                        <button type="button"
                            class="btn btn-label-danger btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                            (click)="activeModal.close('Close click')">Cancel</button>
                        <button type="button"
                            class="btn btn-label-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                            matStepperPrevious>Back</button>
                        <button type="submit"
                            class="btn btn-primary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                            *ngIf="!isLoading">Add individual</button>
                        <button *ngIf="isLoading" type="button" class="btn btn-primary" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
                <!-- </div> -->
            </mat-step>
            <!-- </form> -->
        </mat-horizontal-stepper>
    </div>
</div>
