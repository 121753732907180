import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SendEmailModalData} from '../../../../../interface/send-email-modal-data';

@Component({
    selector: 'kt-send-email-modal',
    templateUrl: './send-email-modal.component.html',
    styleUrls: ['./send-email-modal.component.scss']
})
export class SendEmailModalComponent implements OnInit {

    data: SendEmailModalData;
    emailForm: FormGroup;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.data.subject = this.substituteText('__company_name__', this.data.entity.entity_name, this.data.subject);
        this.data.subject = this.substituteText('__account_ccy__', this.data.entity.entity_name, this.data.subject);

        this.emailForm = new FormGroup({
            body: new FormControl(this.data.body || '', Validators.required),
            toEmail: new FormControl(this.data.recipients.to || undefined, [Validators.email]),
            fromEmail: new FormControl(this.data.recipients.from || '', [Validators.required, Validators.email]),
            fromAlias: new FormControl(this.data.recipients.fromAlias || '', [Validators.required])
        });

        this.updateBody();
    }

    onAddEmailClick(email: string): void {
        this.data.recipients.to.push(email);
        this.emailForm.controls.toEmail.reset();
        this.emailForm.controls.toEmail.clearValidators();
    }

    onRemoveEmailClick(id: number): void {
        this.data.recipients.to.splice(id, 1);
    }

    substituteText(key: string, value: string | number, src: string): string {
        const regexp = new RegExp(key, 'g');
        return src.replace(regexp, String(value));
    }

    stringToHTML(src: string): string {
        const regexp = new RegExp('\\n', 'g');
        return src.replace(regexp, '<br>');
    }

    updateBody(): void {
        let bodyText = String(this.data.body);
        bodyText = this.substituteText('__company_name__', this.data.entity.entity_name, bodyText);
        bodyText = this.substituteText('__account_ccy__', this.data.newestAccount.ccy, bodyText);
        bodyText = this.substituteText('__sender_name__', this.emailForm.controls.fromAlias.value, bodyText);
        bodyText = this.substituteText('__contact_person__', this.data.entity.first_name, bodyText);

        this.emailForm.controls.body.reset();
        this.emailForm.controls.body.clearValidators();
        this.emailForm.controls.body.setValue(bodyText);
    }

    onSendClick(): void {
        const response: SendEmailModalData = {
            ...this.data,
            body: `<div class="body-text">${this.stringToHTML(this.emailForm.controls.body.value)}</div>`
        };
        this.activeModal.close(response);
    }

}
