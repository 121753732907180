// Angular
import { ModuleWithProviders } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthGuard } from './../../../guards/auth.guard';
import { AuthenticationService } from './../../../services/auth.service';
// Module components
import { AuthComponent } from './auth.component';
// Auth
import { CallbackComponent } from './callback/callback.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
const ɵ0 = { returnUrl: window.location.pathname };
const routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: LoginComponent,
                data: ɵ0
            },
            {
                path: 'register',
                component: RegisterComponent
            },
            {
                path: 'create-password/:token/:userId',
                component: CreatePasswordComponent,
            }
        ]
    },
    {
        path: 'callback',
        component: CallbackComponent
    }
];
export class AuthModule {
    static forRoot() {
        return {
            ngModule: AuthModule,
            providers: [
                AuthenticationService,
                AuthGuard
            ]
        };
    }
}
export { ɵ0 };
