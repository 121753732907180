import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
// NGRX
import { Action } from '@ngrx/store';
import { Accounts } from '../../models/accounts';
import { AccountQueryParams } from '../../models/account-query';


export enum AccountsActionTypes {
    LOAD_ACCOUNTS = '[ACCOUNTS] Load Accounts',
    GET_ACCOUNTS = '[ACCOUNTS] Get Accounts',
    GET_ACCOUNTS_FAILURE = '[ACCOUNTS] Get Accounts failure',
    GET_ACCOUNTS_SUCCESS = '[ACCOUNTS] Get Accounts Success',
    ADD_ACCOUNT = '[ACCOUNTS] Add Account',
    LOAD_ACCOUNTS_PAGE = '[ACCOUNTS] Accounts Loaded',
    ADD_ACCOUNT_SUCCESS = '[ACCOUNTS] Add Account Success',
    ADD_ACCOUNT_FAILURE = '[ACCOUNTS] Add Account Failure',
    EDIT_ACCOUNT = '[ACCOUNTS] Edit Account',
    EDIT_ACCOUNT_SUCCESS = '[ACCOUNTS] Account Edited',
    EDIT_ACCOUNT_FAIL = '[ACCOUNTS] Account Failed to Edit',
    DELETE_ACCOUNT_SUCCESS = '[ACCOUNTS] Account Deleted',
    DELETE_ACCOUNT_FAIL = '[ACCOUNTS] Account Failed to Delete',
    ACCOUNT_PAGE_TOGGLE_LOADING = '[ACCOUNTS] Accounts Page Toggle Loading',
    ACCOUNT_ACTION_TOGGLE_LOADING = '[ACCOUNTS] Accounts Action Toggle Loading'
}


export class LoadAccountsAction implements Action {
    readonly type = AccountsActionTypes.LOAD_ACCOUNTS;
    constructor(public payload: Accounts[]) { }
}

export class AccountsPageRequestedAction implements Action {
    readonly type = AccountsActionTypes.GET_ACCOUNTS;
    constructor(public payload: { entityId, page: QueryParamsModel }) { }
}

export class AccountsPageToggleLoading implements Action {
    readonly type = AccountsActionTypes.ACCOUNT_PAGE_TOGGLE_LOADING;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AccountsActionToggleLoading implements Action {
    readonly type = AccountsActionTypes.ACCOUNT_ACTION_TOGGLE_LOADING;
    constructor(public payload: { isLoading: boolean }) { }
}

export class AccountsPageLoadedAction implements Action {
    readonly type = AccountsActionTypes.LOAD_ACCOUNTS_PAGE;
    constructor(public payload: { accounts: Accounts[], totalCount: number, page: QueryParamsModel }) { }
}

export class GetAccountsFailureAction implements Action {
    readonly type = AccountsActionTypes.GET_ACCOUNTS_FAILURE;
    constructor(public payload: string) { }
}

export class GetAccountsSuccessAction implements Action {
    readonly type = AccountsActionTypes.GET_ACCOUNTS_SUCCESS;
    constructor(public payload: string) { }
}

export class AddAccountAction implements Action {
    readonly type = AccountsActionTypes.ADD_ACCOUNT;
    constructor(public payload: Accounts) { }
}

export class AddAccountSuccessAction implements Action {
    readonly type = AccountsActionTypes.ADD_ACCOUNT_SUCCESS;
    constructor(public payload: string) { }
}

export class AddAccountFailureAction implements Action {
    readonly type = AccountsActionTypes.ADD_ACCOUNT_FAILURE;
    constructor(public payload: string) { }
}

export class EditAccountAction implements Action {
    readonly type = AccountsActionTypes.EDIT_ACCOUNT;
    constructor(public payload: Accounts) { }
}

export type AccountsActions
    = LoadAccountsAction
    | AccountsPageRequestedAction
    | GetAccountsFailureAction
    | GetAccountsSuccessAction
    | AddAccountAction
    | AddAccountSuccessAction
    | AddAccountFailureAction
    | AccountsPageLoadedAction
    | AccountsPageToggleLoading
    | AccountsActionToggleLoading
    | EditAccountAction;
