import { Component, ComponentRef, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BalanceFeeDto } from '../transaction-fees-component/utils/balance-fee.dto'
import { PaymentRailDefaultFees } from '../transaction-fees-component/utils/payment-rail-default.dto'

import { tap } from 'rxjs/operators'
import { AccountFeesStateService } from '../utils/state/account-fees-state.service'

@Component({
	selector: 'kt-balance-fee',
	templateUrl: './balance-fee.component.html',
	styleUrls: ['./balance-fee.component.scss']
})
export class BalanceFeeComponent implements OnInit {
	inboundPaymentRailFee: BehaviorSubject<PaymentRailDefaultFees> = new BehaviorSubject<PaymentRailDefaultFees>(null)
	outboundPaymentRailFee: BehaviorSubject<PaymentRailDefaultFees> = new BehaviorSubject<PaymentRailDefaultFees>(null)
	balanceFeeText: string = ''
	balance: BalanceFeeDto[] = []
	disableForm: boolean

	amount: number

	@Input()
	currency: string

	@Input()
	readOnly?: boolean

	@Output()
	balanceOutput: EventEmitter<BalanceFeeDto[]> = new EventEmitter<BalanceFeeDto[]>()

	constructor(private readonly accountFeesStateService: AccountFeesStateService) {}

	removeInvalidCharacters(event) {
		const invalidChars = ['-', '+', 'e']
		if (invalidChars.includes(event.key)) {
			event.preventDefault()
		}
	}

	onChanged() {
		this.accountFeesStateService.updatePaymentRailBalanceFee({ amount: this.amount, currency: this.currency })
		this.accountFeesStateService.detailsSubmitted.next(true)
	}

	ngOnInit() {
		this.accountFeesStateService.paymentRailContractFees
			.pipe(
				tap(fees => {
					if (fees) {
						const balanceFeesMap: Map<string, number> = new Map(fees.map(fee => [fee.currencyCode, fee.balanceFee]))
						const amount = balanceFeesMap.get(this.currency)
						if (this.amount != amount) {
							this.amount = amount
							this.accountFeesStateService.updatePaymentRailBalanceFee({ amount: this.amount, currency: this.currency })
							this.accountFeesStateService.detailsSubmitted.next(true)
						}
					} else {
						this.amount = 0
					}
				})
			)
			.subscribe()

		switch (this.currency) {
			case 'EUR':
				this.balanceFeeText = 'ECB-based balance fee'
				break
			case 'CHF':
				this.balanceFeeText = 'SNB-based balance fee'
				break
			default:
				this.balanceFeeText = 'Balance fee'
				break
		}
	}
}
