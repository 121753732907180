import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
import { AccountsActions, AccountsActionTypes } from '../actions/accounts';
import { Accounts } from '../../models/accounts';
// NGRX

export interface AccountState extends EntityState<Accounts> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedAccountId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<Accounts> = createEntityAdapter<Accounts>();

export const initialAccountState: AccountState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedAccountId: undefined,
    showInitWaitingMessage: true
});

export function accountsReducer(state = initialAccountState, action: AccountsActions): AccountState {
    switch (action.type) {

        case AccountsActionTypes.ACCOUNT_PAGE_TOGGLE_LOADING: return {
            ...state, listLoading: action.payload.isLoading, lastCreatedAccountId: undefined
        };

        case AccountsActionTypes.LOAD_ACCOUNTS_PAGE: {
            return adapter.addMany(action.payload.accounts, {
                ...initialAccountState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }

        default: return state;
    }
}
