import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EntitiesActionTypes, EntitiesToggleLoadingAction } from '../actions/entities';
export class EntitiesEffects {
    constructor(actions$, store, restApiService, router) {
        this.actions$ = actions$;
        this.store = store;
        this.restApiService = restApiService;
        this.router = router;
        this.showEntitiesLoadingDispatcher = new EntitiesToggleLoadingAction({ isLoading: true });
        this.hideEntitiesLoadingDispatcher = new EntitiesToggleLoadingAction({ isLoading: false });
        this.getEntity$ = this.actions$
            .pipe(ofType(EntitiesActionTypes.GET_ENTITIES), map((action) => action.payload), switchMap((payload) => {
            return this.getEntitiesByType(payload);
        }));
        this.getSubEntities$ = this.actions$
            .pipe(ofType(EntitiesActionTypes.GET_SUB_ENTITIES), map((action) => action.payload), mergeMap(payload => {
            console.log('RUNING');
            this.store.dispatch(this.showEntitiesLoadingDispatcher);
            return this.restApiService.getSubEntities(payload)
                .pipe(map(subEntities => {
                this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                return {
                    type: EntitiesActionTypes.LOAD_SUB_ENTITIES,
                    payload: {
                        entity_id: payload,
                        subEntities
                    }
                };
            }), catchError(error => {
                this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                return of({
                    type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                    payload: error.error
                });
            }));
        }));
        this.addEntity$ = this.actions$
            .pipe(ofType(EntitiesActionTypes.CREATE_ENTITY), map((action) => action.payload), switchMap(payload => this.restApiService.addEntity(payload)
            .pipe(map(entity => ({
            type: EntitiesActionTypes.ADD_ENTITY,
            payload: entity
        })), catchError(error => of({
            type: EntitiesActionTypes.ADD_ENTITY_FAILURE,
            payload: error.error
        })))));
    }
    dispatchEntiies() {
    }
    getEntitiesByType(options) {
        if (!options) {
            options = { type: '' };
        }
        this.store.dispatch(this.showEntitiesLoadingDispatcher);
        switch (options.type) {
            case 'individual':
            case 'sub-entity-individual':
                return this.restApiService.getIndividualsEntities(options.query)
                    .pipe(map((res) => {
                    this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                    return {
                        type: EntitiesActionTypes.LOAD_INDIVIDUAL_ENTITIES,
                        payload: res
                    };
                }), catchError(error => of({
                    type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                    payload: error.error
                })));
            case 'corporate':
            case 'sub-entity-corporate':
                return this.restApiService.getCorporateEntities(options.query)
                    .pipe(map((res) => {
                    this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                    return {
                        type: EntitiesActionTypes.LOAD_CORPORATE_ENTITIES,
                        payload: res
                    };
                }), catchError(error => of({
                    type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                    payload: error.error
                })));
            default:
                return this.restApiService.getEntities(options.query)
                    .pipe(map((res) => {
                    this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                    return {
                        type: EntitiesActionTypes.LOAD_ONBOARDING_ENTITIES,
                        payload: res
                    };
                }), catchError(error => of({
                    type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                    payload: error.error
                })));
        }
    }
}
tslib_1.__decorate([
    Effect()
], EntitiesEffects.prototype, "getEntity$", void 0);
tslib_1.__decorate([
    Effect()
], EntitiesEffects.prototype, "getSubEntities$", void 0);
tslib_1.__decorate([
    Effect()
], EntitiesEffects.prototype, "addEntity$", void 0);
