import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AccountDetail } from '../../../../../../models/account-info.dto'
import { EntityGeneralFees } from '../../../../../../models/entity_general_fees.dto'
import moment from 'moment'
import { SystemUser } from '../../../../../../models/system-user'
import { DefaultGeneralFees } from '../../../../../../models/default-general-fees.dto'
import { PaymentRailConfig } from '../../transaction-fees-component/utils/payment-rail-config.dto'
import { PaymentRailContractFees } from '../../transaction-fees-component/utils/payment-rail-contract.dto'
import { RestApiService } from '../../../../../../services/rest-api.service'
import { PaymentRailDefaultFees } from '../../transaction-fees-component/utils/payment-rail-default.dto'
import { ValueType } from './value-type.enum'
import { CHARGE_CURRENCY } from './constants'
import { FormGroup } from '@angular/forms'
@Injectable({
	providedIn: 'root'
})
export class AccountFeesStateService {
	readonly accounts: BehaviorSubject<AccountDetail[]> = new BehaviorSubject<AccountDetail[]>([])
	readonly generalFees: BehaviorSubject<DefaultGeneralFees[]> = new BehaviorSubject<DefaultGeneralFees[]>([])
	readonly entityGeneralFees: BehaviorSubject<EntityGeneralFees[]> = new BehaviorSubject<EntityGeneralFees[]>(undefined)
	readonly paymentRails: BehaviorSubject<PaymentRailConfig[]> = new BehaviorSubject<PaymentRailConfig[]>(undefined)
	readonly paymentRailContractFees: BehaviorSubject<PaymentRailContractFees[]> = new BehaviorSubject<PaymentRailContractFees[]>(undefined)
	readonly finalisedPaymentRailContractFees: BehaviorSubject<PaymentRailContractFees[]> = new BehaviorSubject<PaymentRailContractFees[]>(undefined)
	readonly validPaymentRailContractFees: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	readonly feesConfirmed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	readonly detailsSubmitted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	private readonly currentUser: BehaviorSubject<SystemUser> = new BehaviorSubject<SystemUser>(undefined)
	private readonly shouldClose: Subject<boolean> = new Subject<boolean>()
	readonly transactionsFormDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
	readonly currentEntityId: BehaviorSubject<string> = new BehaviorSubject<string>(null)
	readonly formType: BehaviorSubject<string> = new BehaviorSubject<string>('default')
	readonly newCurrency: BehaviorSubject<string> = new BehaviorSubject<string>('')

	constructor(private readonly restApiService: RestApiService) {
		this.accounts.pipe(takeUntil(this.shouldClose))
		this.generalFees.pipe(takeUntil(this.shouldClose))
		this.entityGeneralFees.pipe(takeUntil(this.shouldClose))
		this.detailsSubmitted.pipe(takeUntil(this.shouldClose))
		this.feesConfirmed.pipe(takeUntil(this.shouldClose))
		this.paymentRails.pipe(takeUntil(this.shouldClose))
		this.paymentRailContractFees.pipe(takeUntil(this.shouldClose))
		this.finalisedPaymentRailContractFees.pipe(takeUntil(this.shouldClose))
		this.validPaymentRailContractFees.pipe(takeUntil(this.shouldClose))
		this.currentUser.pipe(takeUntil(this.shouldClose))
		this.transactionsFormDisabled.pipe(takeUntil(this.shouldClose))
		this.currentEntityId.pipe(takeUntil(this.shouldClose))
		this.formType.pipe(takeUntil(this.shouldClose))
		this.newCurrency.pipe(takeUntil(this.shouldClose))
	}

	resetAll() {
		this.accounts.next([])
		this.generalFees.next([])
		this.entityGeneralFees.next(undefined)
		this.paymentRails.next(undefined)
		this.paymentRailContractFees.next(undefined)
		this.finalisedPaymentRailContractFees.next(undefined)
		this.newCurrency.next('')

		this.validPaymentRailContractFees.next(false)
		this.feesConfirmed.next(false)
		this.detailsSubmitted.next(false)
		this.shouldClose.next()
		this.transactionsFormDisabled.next(false)
		this.formType.next('default')
	}

	setNewCurrency(ccy: string) {
		this.newCurrency.next(ccy)
	}

	setFormType(type: string) {
		this.formType.next(type)
	}

	disableTransactionsForm(value: boolean) {
		this.transactionsFormDisabled.next(value)
	}

	// Form State
	updateDetailsSubmitted(value: boolean): void {
		this.detailsSubmitted.next(value)
	}

	updateFeesConfirmed(value: boolean): void {
		this.detailsSubmitted.next(value)
		this.feesConfirmed.next(value)
	}

	setCurrentEntityId(entityId: string) {
		this.currentEntityId.next(entityId)
	}

	getCurrentEntityId() {
		return this.currentEntityId.value
	}

	setCurrentUser(user: SystemUser): void {
		this.currentUser.next(user)
	}

	getCurrentUser(): SystemUser {
		return this.currentUser.value
	}

	//Sends a notification to close all watched subscription
	close(): void {
		this.shouldClose.next(true)
	}

	//
	//Accounts State
	//
	addUpdateAccount(account: AccountDetail, index = -1): void {
		const _accounts = this.accounts.value
		if (index > -1) {
			_accounts[index] = account
		} else {
			_accounts.push(account)
		}

		this.accounts.next(_accounts.filter(account => account.currency && account.effectiveDate))
	}

	clearAccounts() {
		this.accounts.next([])
	}

	removeAccount(index: number): void {
		const _accounts = this.accounts.value
		const account = _accounts[index]
		const paymentRails = this.paymentRails.value
		const paymentRailContractFees = this.paymentRailContractFees.value

		if (paymentRails && account.currency) {
			const updatedPaymentRails = paymentRails.filter(rail => rail.fromCcy != account.currency)
			this.paymentRails.next(updatedPaymentRails)
		}

		if (paymentRailContractFees && account.currency) {
			const updatedPaymentRailContractFees = paymentRailContractFees.filter(fee => fee.paymentRail.fromCcy != account.currency)
			this.paymentRailContractFees.next(updatedPaymentRailContractFees)
			this.finalisePaymentRails()
		}

		_accounts.splice(index, 1)
		this.accounts.next(_accounts)
	}

	//
	// General Fees State
	//
	updateGeneralFees(data: DefaultGeneralFees[]): void {
		this.generalFees.next(data)
	}

	updateEntityGeneralFees(entityId: string, form: FormGroup): void {
		const currentUser = this.getCurrentUser()
		const _entityGeneralFees: EntityGeneralFees[] = this.generalFees.value.map(generalFee => {
			generalFee.createdBy = currentUser.id || undefined
			generalFee.effectiveDate = moment(this.accounts.value[0] ? this.accounts.value[0].effectiveDate : new Date(), 'DD/MM/YYYY').toDate()
			generalFee.createdAt = new Date()

			let amount: number
			const feeCategory = generalFee.category.description.toLowerCase()
			if (feeCategory.includes('setup fee')) {
				amount = Number(form.get('setup_fee').value)
			} else if (feeCategory.includes('management fee')) {
				amount = Number(form.get('management_fee').value)
			} else if (feeCategory.includes('minimum transaction fees')) {
				amount = Number(form.get('min_transaction_fees').value)
			} else if (feeCategory.includes('general exceptions')) {
				amount = Number(form.get('general_exceptions').value)
			} else if (feeCategory.includes('fraud claim exceptions')) {
				amount = Number(form.get('fraud_claim_exceptions').value)
			} else if (feeCategory.includes('minimum term')) {
				amount = Number(form.get('minimum_term').value)
			}

			return {
				entityId: entityId,
				accountCurrency: CHARGE_CURRENCY,
				...generalFee,
				amount
			}
		})

		this.entityGeneralFees.next(_entityGeneralFees)
	}

	//
	// Payment rail fees state
	//
	mapDefaultPaymentRailsFees(data: PaymentRailConfig[]): void {
		this.populateDefaultFeesForPaymentRails(data).then(paymentRailConfigs => {
			const rails = new Map<string, PaymentRailConfig>()
			const oldRails = this.paymentRails.value || []
			const combineRails = [...oldRails, ...paymentRailConfigs]
			combineRails.map(rail => rails.set(`${rail.id} ${rail.fromCcy}`, rail))
			this.paymentRails.next([...rails.values()])
		})
	}

	mapContractPaymentRailsFees(entityId: string, data: PaymentRailConfig[]): void {
		this.populateContractFeesForPaymentRails(entityId, data).then(paymentRailConfigs => {
			const rails = new Map<string, PaymentRailConfig>()
			const oldRails = this.paymentRails.value || []
			const combineRails = [...oldRails, ...paymentRailConfigs]
			combineRails.map(rail => rails.set(`${rail.id} ${rail.fromCcy}`, rail))
			this.paymentRails.next([...rails.values()])
		})
	}

	updatePaymentRail(id: string, currency: string, data: PaymentRailContractFees[]): void {
		let _paymentRails = this.paymentRails.value
		_paymentRails = _paymentRails.map(rail => {
			if (rail.id === id && rail.fromCcy === currency) rail.paymentRailFees = data
			return rail
		})
		this.paymentRails.next(_paymentRails)
	}

	updatePaymentRailBalanceFee(data: { amount: number; currency: string }) {
		let _paymentRails = this.paymentRails.value
		_paymentRails = _paymentRails.map(rail => {
			if (rail.fromCcy === data.currency) {
				rail.paymentRailFees.map(fees => {
					fees.balanceFee = data.amount
				})
			}
			return rail
		})
		this.paymentRails.next(_paymentRails)
	}

	updatePaymentRailContractFees(data: PaymentRailContractFees[]): void {
		if (!data) return this.paymentRailContractFees.next(null)
		const _paymentRailContractFees = data.map(paymentRailFee => {
			const updatedFee = paymentRailFee
			updatedFee.effectiveFromDate = this.getEffectiveDate(paymentRailFee.currencyCode)
			return updatedFee
		})
		this.checkValidFees(_paymentRailContractFees)
		this.paymentRailContractFees.next(_paymentRailContractFees)
	}

	/**
	 * Process the paymentRails value and set the finalisedPaymentRailContractFees
	 */
	finalisePaymentRails(): void {
		if (!this.paymentRails.value) {
			console.error(`Payment rails are not defined`)
			this.finalisedPaymentRailContractFees.next([])
			return
		}

		const paymentRailFees = this.paymentRails.value.map(rail => rail.paymentRailFees)
		const paymentRailContractFees: PaymentRailContractFees[] = paymentRailFees.concat.apply([], paymentRailFees)
		const uniqueFees = [...new Map(paymentRailContractFees.map(item => [btoa(JSON.stringify(item)), item])).values()]

		this.finalisedPaymentRailContractFees.next(uniqueFees)
	}

	private getEffectiveDate(ccy: string): Date {
		const account = this.accounts.value.find(account => account.currency == ccy)
		if (account) {
			return moment(account.effectiveDate, 'DD/MM/YYYY').toDate()
		} else {
			return new Date()
		}
	}

	private async populateDefaultFeesForPaymentRails(paymentRails: PaymentRailConfig[]): Promise<PaymentRailConfig[]> {
		if (!paymentRails) return null
		const paymentRailConfigs = []
		for (const paymentRail of paymentRails) {
			try {
				const res = await this.restApiService.getTransactionalDefaultFees(paymentRail.id).toPromise()
				paymentRailConfigs.push(this.setDefaultFees(paymentRail, res))
			} catch (error) {
				console.error(`CatchError: getPaymentRailDefaultFees: `, error)
			}
		}
		return paymentRailConfigs
	}

	private async populateContractFeesForPaymentRails(entityId: string, paymentRails: PaymentRailConfig[]): Promise<PaymentRailConfig[]> {
		const paymentRailConfigs = []
		for (const paymentRail of paymentRails) {
			try {
				const res = await this.restApiService.getTransactionalContractFees(entityId, paymentRail.fromCcy, paymentRail.id).toPromise()
				paymentRailConfigs.push(this.setDefaultFees(paymentRail, res))
			} catch (error) {
				console.error(`CatchError: getPaymentRailDefaultFees: `, error)
			}
		}
		return paymentRailConfigs
	}

	private setDefaultFees(paymentRailConfig: PaymentRailConfig, feeData: PaymentRailDefaultFees[]): PaymentRailConfig {
		const updatedConfig = paymentRailConfig
		const currentUser = this.getCurrentUser()

		updatedConfig.paymentRailFees = feeData.map(fee => {
			const updatedFee = new PaymentRailContractFees()
			updatedFee.type = fee.defFeeRelPercent ? ValueType.REL : ValueType.ABS
			updatedFee.createdAt = moment(moment.now()).format()
			updatedFee.paymentRailId = paymentRailConfig.id
			updatedFee.createdBy = fee.createdBy
			updatedFee.chargedCcyCode = CHARGE_CURRENCY
			updatedFee.currencyCode = paymentRailConfig.fromCcy
			updatedFee.direction = fee.direction
			updatedFee.amount = Number(fee.defFeeAbsAmount) || undefined
			updatedFee.defFeeAbsAmount = Number(fee.defFeeAbsAmount) || undefined
			updatedFee.defFeeCapAmount = Number(fee.defFeeCapAmount) || undefined
			updatedFee.defFeeFloorAmount = Number(fee.defFeeFloorAmount) || undefined
			updatedFee.defFeeRelPercent = Number(fee.defFeeRelPercent) || undefined
			updatedFee.balanceFee = Number(fee.balanceFee) || undefined
			updatedFee.paymentRail = fee.paymentRail
			return updatedFee
		})

		return updatedConfig
	}

	private checkValidFees(paymentRailContractFees: PaymentRailContractFees[]): void {
		const invalid = paymentRailContractFees.filter(fee => fee.valid === false)
		const validFees: boolean = invalid.length === 0
		this.validPaymentRailContractFees.next(validFees)
	}
}
