import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { MaterialModule } from './../../../shared/material.module';

import { RouterModule } from '@angular/router';
import { PartialsModule } from '../../partials/partials.module';
import { CoreModule } from '../../../core/core.module';
import { DashboardStatusPipe } from '../../../pipes/dasboard-status.pipe';

@NgModule({
    declarations: [DashboardComponent, DashboardStatusPipe],
    imports: [
        CommonModule,
        PartialsModule,
        MaterialModule,
        CoreModule,
        RouterModule.forChild([
            {
                path: '',
                component: DashboardComponent
            },
        ]),
    ]
})
export class DashboardModule {
}
