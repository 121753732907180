import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayIndividual } from './interfaces/display-individual.interface';
import { IndividualActions } from './interfaces/individual-actions.enum';

@Component({
	selector: 'identity-verification-form-modal',
	templateUrl: './identity-verification-form-modal.component.html',
	styleUrls: ['./identity-verification-form-modal.component.scss']
})
export class IndividualFormModalComponent implements OnInit {
	@Input()
	data: DisplayIndividual
	@Input()
	action: IndividualActions

	individualForm: FormGroup

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit() {
		this.individualForm = new FormGroup({
			id: new FormControl(),
			email: new FormControl({ value: '', disabled: this.action != IndividualActions.ADD }, [Validators.email]),
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			verified: new FormControl(),
			status: new FormControl(),
			code: new FormControl(),
			entityId: new FormControl()
		})
		if (this.data) {
			this.individualForm.patchValue({
				id: this.data.id,
				email: this.data.email,
				firstName: this.data.firstName,
				lastName: this.data.lastName,
				verified: this.data.verified,
				status: this.data.status,
				code: this.data.code,
				entityId: this.data.entityId
			})
		}
	}

	onSubmit() {
		if (this.individualForm.valid) {
			const individual = this.mapDisplayIndividual<{ [key: string]: any }>(this.individualForm.getRawValue())
			this.activeModal.close([this.action, individual])
		}
	}

	onCancel(): void {
		this.individualForm.reset()
		this.activeModal.close()
	}

	private mapDisplayIndividual<T>(formValues: T): DisplayIndividual {
		let individual: DisplayIndividual = {
			entityId: formValues['entityId'],
			id: formValues['id'] === null ? undefined : formValues['id'],
			firstName: formValues['firstName'],
			lastName: formValues['lastName'],
			email: formValues['email'],
			verified: formValues['verified'] === null ? undefined : formValues['verified'],
			status: formValues['status'] === null ? undefined : formValues['status'],
			code: formValues['code']
		}
		return individual
	}
}
