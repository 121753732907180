
<table mat-table
       [dataSource]="paymentRailContractFees| async"
       class="mat-elevation-z8">

    <ng-container matColumnDef="currencyCode">
        <th mat-header-cell *matHeaderCellDef> Currency </th>
        <td mat-cell style="font-weight: bold;" *matCellDef="let element"> {{element.currencyCode}} </td>
      </ng-container>

      <ng-container matColumnDef="paymentRail">
        <th mat-header-cell *matHeaderCellDef> Zone </th>
        <td mat-cell *matCellDef="let element"> {{element.paymentRail.zone}} </td>
      </ng-container>

    <ng-container matColumnDef="direction">
      <th mat-header-cell *matHeaderCellDef> Bound </th>
      <td mat-cell *matCellDef="let element"> {{element.direction}} </td>
    </ng-container>

    <ng-container matColumnDef="effectiveFromDate">
      <th mat-header-cell *matHeaderCellDef> Effective Date </th>
      <td mat-cell *matCellDef="let element"> {{element.effectiveFromDate | date:'MM/dd/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="defFeeAbsAmount">
      <th mat-header-cell *matHeaderCellDef> Absolute </th>
      <td mat-cell *matCellDef="let element"> {{element.defFeeAbsAmount}} </td>
    </ng-container>

    <ng-container matColumnDef="defFeeRelPercent">
        <th mat-header-cell *matHeaderCellDef> Relative </th>
        <td mat-cell *matCellDef="let element"> {{element.defFeeRelPercent}} </td>
      </ng-container>

      <ng-container matColumnDef="defFeeFloorAmount">
        <th mat-header-cell *matHeaderCellDef> Min </th>
        <td mat-cell *matCellDef="let element"> {{element.defFeeFloorAmount}} </td>
      </ng-container>

      <ng-container matColumnDef="defFeeCapAmount">
        <th mat-header-cell *matHeaderCellDef> Max </th>
        <td mat-cell *matCellDef="let element"> {{element.defFeeCapAmount}} </td>
      </ng-container>

      <ng-container matColumnDef="balanceFee">
        <th mat-header-cell *matHeaderCellDef> Balance </th>
        <td mat-cell *matCellDef="let element"> {{element.balanceFee}} </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

