// NGRX
import {Action} from '@ngrx/store';
import {DocumentModel} from '../../models/document';
import {Entity} from '../../models/entity';
import {EntityProfileModel} from '../../models/entity-profile';
import {UpdateEntity} from './entities';

export enum EntityProfilesActionTypes {
    LOAD_PROFILES = '[Profiles] Load Profile',
    GET_PROFILE = '[Profiles] Get Profile',
    GET_SUB_PROFILE = '[Profiles] Get Sub Profile',
    GET_PROFILE_FAILURE = '[Profiles] Get Profile Failure',
    CREATE_PROFILE = '[Profiles] Create Profile',
    ADD_PROFILE = '[Profiles] Add Profile',
    ADD_PROFILE_SUCCESS = '[Profiles] Add Profile Success',
    ADD_PROFILE_FAILURE = '[Profiles] Add Profile Failure',
    EDIT_PROFILE = '[Profiles] Edit Profile',
    EDIT_PROFILE_SUCCESS = '[Profiles] Profile Edited',
    EDIT_PROFILE_FAIL = '[Profiles] Profile Failed to Edit',
    DELETE_PROFILE = '[Profiles] Delete Profile',
    DELETE_PROFILE_SUCCESS = '[Profiles] Profile Deleted',
    DELETE_PROFILE_FAIL = '[Profiles] Profile Failed to Delete',
    ADD_DOCUMENT_TO_PROFILE = '[Profiles] Add Document to Profile',
    ADD_DOCUMENT_TO_SUCCESS = '[Profiles] Add Document to Profile Success',
    ADD_DOCUMENT_TO_FAILURE = '[Profiles] Add Document to Profile Failure',
    UPDATE_ENTITY_PROFILE = '[Profiles] Update Entity'
}

export class LoadEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.LOAD_PROFILES;

    constructor(public payload: string) {
    }
}

export class GetEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.GET_PROFILE;

    constructor(public payload: string) {
    }
}


export class GetSubEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.GET_SUB_PROFILE;

    constructor(public payload: string) {
    }
}


export class GetEntityProfileActionFailure implements Action {
    readonly type = EntityProfilesActionTypes.GET_PROFILE_FAILURE;

    constructor(public payload: Error) {
    }
}

export class EntityProfileCreatedAction implements Action {
    readonly type = EntityProfilesActionTypes.ADD_PROFILE;

    constructor(public payload: EntityProfileModel) {
    }
}

export class CreateEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.CREATE_PROFILE;

    constructor(public payload: EntityProfileModel) {
    }
}

export class EditEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.EDIT_PROFILE;

    constructor(public payload: EntityProfileModel) {
    }
}

export class DeleteEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.DELETE_PROFILE;

    constructor(public payload: EntityProfileModel) {
    }
}

export class AddDocumentToEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.ADD_DOCUMENT_TO_PROFILE;

    constructor(public payload: DocumentModel) {
    }
}

export class UpdateEntityProfileAction implements Action {
    readonly type = EntityProfilesActionTypes.UPDATE_ENTITY_PROFILE;

    constructor(public payload: { entityProfile: EntityProfileModel, profileId: string }) {
    }
}

export type EntityProfileActions
    = EditEntityProfileAction
    | EntityProfileCreatedAction
    | LoadEntityProfileAction
    | GetEntityProfileAction
    | DeleteEntityProfileAction
    | CreateEntityProfileAction
    | GetEntityProfileActionFailure
    | AddDocumentToEntityProfileAction
    | UpdateEntityProfileAction;
