import { ValueType } from '../../utils/state/value-type.enum'
import { PaymentRailConfig } from './payment-rail-config.dto'

export class PaymentRailDefaultFees {
	id: string
	paymentRailId: string
	paymentRail: PaymentRailConfig
	chargedCcyCode: string
	direction: string
	defFeeAbsAmount: number
	defFeeRelPercent: number
	defFeeCapAmount: number
	defFeeFloorAmount: number
	balanceFee: number
	createdAt: string
	createdBy: string
	reviewedAt: string
	reviewedBy: string
	type?: ValueType
}
