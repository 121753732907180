<!--begin::Body-->
<div class="kt-login__body">
    <!--begin::Signin-->
    <div class="kt-login__form">
        <div class="kt-login__title">
            <h3>{{ 'AUTH.REGISTER.TITLE' | translate }}</h3>
        </div>

        <kt-auth-notice></kt-auth-notice>

        <form class="kt-login__form kt-form" [formGroup]="registerForm" autocomplete="off">
            <div class="form-group">
                <mat-form-field>
                    <mat-label>Fullname</mat-label>
                    <input matInput type="text" placeholder="Fullname" formControlName="first_name"/>
                    <mat-error *ngIf="isControlHasError('first_name','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('first_name','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('first_name','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
                    <input matInput type="email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}" formControlName="email" autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('email','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','email')">
                        <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('email','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <input matInput type="text" placeholder="Username" formControlName="username"/>
                    <mat-error *ngIf="isControlHasError('username','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('username','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('username','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('password','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('password','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field>
                    <mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
                    <input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="confirmPassword"
                           autocomplete="off"/>
                    <mat-error *ngIf="isControlHasError('confirmPassword','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                    </mat-error>
                    <mat-error *ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
                        <strong>Passsword and ConfirmPassword didn't match.</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-checkbox name="agree" formControlName="agree">
                    I agree the <a href="javascript:;">terms & conditions</a>
                </mat-checkbox>
            </div>

            <!--begin::Action-->
            <div class="kt-login__actions">
                <a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
                    {{ 'AUTH.GENERAL.FORGOT_BUTTON' | translate }}
                </a>
                <button routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel"
                        class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}</button>
                <button (click)="submit()" id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary"
                        [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
            </div>
            <!--end::Action-->
        </form>
    </div>
</div>
