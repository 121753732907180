import { NoteModel } from './../../../../../models/note';
import { Entity } from './../../../../../models/entity';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from '../../../../../services/rest-api.service';
import { AuthenticationService } from '../../../../../services/auth.service';

@Component({
    selector: "kt-add-note",
    templateUrl: "./add-note.component.html",
    styleUrls: ["./add-note.component.scss"]
})
export class AddNoteComponent implements OnInit {
    @Input() entity: Entity;
    addNoteForm: FormGroup;
    system_account_id;
    notes: NoteModel[] = [];
    isLoading = false;
    responseStatus = {
        show: false,
        isError: false,
        message: "Your note has been created. Thanks!"
    };

    constructor(public activeModal: NgbActiveModal, private restApiService: RestApiService, private authentication: AuthenticationService) {}

    ngOnInit() {
        this.authentication.userProfile$.subscribe(systemUser => {
            this.system_account_id = systemUser.id;
        });
        this.restApiService.getNotes(this.entity.id).subscribe(notes => {
            this.notes = notes;
        });
        this.addNoteForm = new FormGroup({
            note: new FormControl("", [Validators.required]),
            type: new FormControl("", [Validators.required]),
            notify_others: new FormControl(false)
        });
    }

    onSubmit() {
        if (this.addNoteForm.valid) {
            this.isLoading = true;
            this.addNoteForm.disable();
            this.restApiService.addNote({
                ...this.addNoteForm.value,
                entity_id: this.entity.id,
                system_account_id: this.system_account_id
            }).subscribe(
                note => {
                    this.notes.push(note);
                    this.responseStatus.show = true;
                    this.isLoading = false;
                    setTimeout(() => {
                        this.responseStatus.show = false;
                    }, 5000);
                },
                error => {
                    this.isLoading = false;
                    this.responseStatus.show = true;
                    this.responseStatus.isError = true;
                    this.addNoteForm.enable();
                    setTimeout(() => {
                        this.responseStatus.show = false;
                    }, 5000);
                    if (!error.status) {
                        this.responseStatus.message = "No internet connection, please try again later";
                    } else {
                        this.responseStatus.message = error.error.message;
                    }
                },
                () => {
                    this.addNoteForm.reset();
                    this.addNoteForm.enable();
                }
            );
        }
    }
}
