import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
export class AuthGuard {
    constructor(authenticationService) {
        this.authenticationService = authenticationService;
    }
    canActivate(next, state) {
        return this.authenticationService.isAuthenticated$.pipe(tap(loggedIn => {
            if (!loggedIn) {
                this.authenticationService.login(state.url);
            }
        }));
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthenticationService)); }, token: AuthGuard, providedIn: "root" });
