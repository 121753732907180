import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api.service';
import {CreateEntityAction, EntitiesActionTypes, EntitiesToggleLoadingAction, GetEntitiesAction} from '../actions/entities';
import { GetSubEntitiesAction } from './../actions/entities';
import {AppState} from "../reducers";


@Injectable()
export class EntitiesEffects {
    showEntitiesLoadingDispatcher = new EntitiesToggleLoadingAction({ isLoading: true });
    hideEntitiesLoadingDispatcher = new EntitiesToggleLoadingAction({ isLoading: false });
    @Effect()
    getEntity$: Observable<Action> = this.actions$
        .pipe(ofType(EntitiesActionTypes.GET_ENTITIES),
            map((action: GetEntitiesAction) => action.payload),
            switchMap((payload) => {
                return this.getEntitiesByType(payload);
            }));

    @Effect()
    getSubEntities$: Observable<Action> = this.actions$
        .pipe(ofType(EntitiesActionTypes.GET_SUB_ENTITIES),
            map((action: GetSubEntitiesAction) => action.payload),
            mergeMap(payload => {
                console.log('RUNING')
                this.store.dispatch(this.showEntitiesLoadingDispatcher);
                return this.restApiService.getSubEntities(payload)
                    .pipe(
                        map(subEntities => {
                            this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                            return {
                                type: EntitiesActionTypes.LOAD_SUB_ENTITIES,
                                payload: {
                                    entity_id: payload,
                                    subEntities
                                }
                            };
                        }),
                        catchError(error => {
                            this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                            return of({
                                type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                                payload: error.error
                            })
                        }));
            }));

    @Effect()
    addEntity$: Observable<Action> = this.actions$
        .pipe(ofType(EntitiesActionTypes.CREATE_ENTITY),
            map((action: CreateEntityAction) => action.payload),
            switchMap(payload =>
                this.restApiService.addEntity(payload)
                .pipe(
                    map(entity => ({
                        type: EntitiesActionTypes.ADD_ENTITY,
                        payload: entity
                    })),
                    catchError(error => of({
                        type: EntitiesActionTypes.ADD_ENTITY_FAILURE,
                        payload: error.error
                    }))
                )
            ));

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private restApiService: RestApiService,
        private router: Router,
    ) { }

    private dispatchEntiies() {

    }
    private getEntitiesByType(options) {
        if (!options) {
            options =  {type: ''};
        }

        this.store.dispatch(this.showEntitiesLoadingDispatcher);
        switch (options.type) {
            case 'individual':
            case 'sub-entity-individual':
               return this.restApiService.getIndividualsEntities(options.query)
                .pipe(
                    map((res) => {
                        this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                        return {
                            type: EntitiesActionTypes.LOAD_INDIVIDUAL_ENTITIES,
                            payload: res
                        };
                    }),
                    catchError(error => of({
                        type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                        payload: error.error
                    })));

            case 'corporate':
            case 'sub-entity-corporate':
               return this.restApiService.getCorporateEntities(options.query)
                .pipe(
                    map((res) => {
                        this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                        return {
                            type: EntitiesActionTypes.LOAD_CORPORATE_ENTITIES,
                            payload: res
                        };
                    }),
                    catchError(error => of({
                        type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                        payload: error.error
                    })));

            default:
               return this.restApiService.getEntities(options.query)
                .pipe(
                    map((res) => {
                        this.store.dispatch(this.hideEntitiesLoadingDispatcher);
                        return {
                            type: EntitiesActionTypes.LOAD_ONBOARDING_ENTITIES,
                            payload: res
                        };
                    }),
                    catchError(error => of({
                        type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
                        payload: error.error
                    })));
         }
    }
}
