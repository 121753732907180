export class PageConfig {
    constructor() {}
    public defaults: any = {
        dashboard: {
            page: {
                title: 'Dashboard',
                desc: ''
            },
        },
        entities: {
            onboarding: {
                page: { title: 'Onboarding Entities', desc: '' }
            },
            corporate: {
                page: { title: 'Corporate Entities', desc: '' }
            },
            individual: {
                page: { title: 'Individual Entities', desc: '' }
            }
            // page: {
            //     title: 'Entities',
            //     desc: ''
            // },
        },
        error: {
            404: {
                page: {title: '404 Not Found', desc: '', subheader: false}
            },
            403: {
                page: {title: '403 Access Forbidden', desc: '', subheader: false}
            }
        }
    };

    public get configs(): any {
        return this.defaults;
    }
}
