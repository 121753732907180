<div class="modal-header">
    <h5 class="modal-title">Note</h5>
    <button type="button" class="close" (click)="activeModal.close('Close click')">
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-6">
            <div class="kt-portlet shadow-none kt-portlet--height-fluid">
                <perfect-scrollbar class="kt-portlet__body body-scroller">
                    <div class="kt-widget3">
                        <div class="kt-widget3__item" *ngFor="let note of notes">
                            <div class="kt-widget3__header">
                                <div class="kt-widget4__pic kt-widget4__pic--pic">
                                    <span class="kt-badge kt-badge--username kt-badge--unified-warning kt-badge--lg kt-badge--rounded kt-badge--bold">{{note.created_by.first_name + ' ' + note.created_by.last_name|firstLetter}}</span>
                                </div>
                                <div class="kt-widget3__info">
                                    <a href="#" class="kt-widget3__username">
                                        {{ note.created_by.first_name}}
                                    </a><br>
                                    <span class="kt-widget3__time">
                                        {{ note.created_at | date : 'MMM d, y, H:mm' }}
                                    </span>
                                </div>
                                <span class="kt-widget3__status kt-font-success">
                                    {{ note.type }}
                                </span>
                            </div>
                            <div class="kt-widget3__body">
                                <p class="kt-widget3__text show-whitespace">
                                    {{ note.note }}
                                </p>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
        <div class="col-6">
            <h5 class="modal-title">Create a new note</h5>
            <form [formGroup]="addNoteForm" (validSubmit)="onSubmit()">
                <p>Add note to this customer</p>
                    <div
                        class="alert alert-bold"
                        [ngClass]="{ 'alert-solid-success': !responseStatus.isError, 'alert-solid-danger': responseStatus.isError }"
                        *ngIf="responseStatus.show"
                        id="thanksalot"
                    >
                        {{ responseStatus.message }}
                    </div>
                <div class="form-group">
                    <label for="recipient-name" class="form-control-label">Note:</label>
                    <textarea rows="5" class="form-control" formControlName="note"></textarea>
                </div>
                <div class="form-group">
                    <label for="somesome" class="form-control-label">Type of comment:</label>
                    <select class="form-control" formControlName="type">
                        <option value="" disabled> - select -</option>
                        <option value="Compliance">Compliance</option>
                        <option value="Documents">Documents</option>
                        <option value="General">General</option>
                        <option value="Onboarding">Onboarding</option>
                        <option value="Sales">Sales</option>
                    </select>
                </div>
<!--                <hr />-->
<!--                <div class="kt-checkbox-inline">-->
<!--                    <label class="kt-checkbox">-->
<!--                        <input type="checkbox" formControlName="notify_others"> I want to notify other team members-->
<!--                        <span></span>-->
<!--                    </label>-->
<!--                </div>-->
<!--                <div class="kt-padding-t-10 kt-hidden" id="show-emails">-->
<!--                    <label class="kt-checkbox">-->
<!--                        <input type="checkbox"> mike@bcbgroup.io-->
<!--                        <span></span>-->
<!--                    </label>-->
<!--                    <br />-->
<!--                    <label class="kt-checkbox">-->
<!--                        <input type="checkbox"> oliver@bcbgroup.io-->
<!--                        <span></span>-->
<!--                    </label>-->
<!--                    <br />-->
<!--                    <label class="kt-checkbox">-->
<!--                        <input type="checkbox"> ben@bcbgroup.io-->
<!--                        <span></span>-->
<!--                    </label>-->
<!--                </div>-->
                <div class="kt-padding-t-10 kt-padding-b-10">
                    <button type="submit" class="btn btn-sm btn-brand btn-bold create-note">Create note</button>
                </div>
            </form>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>
