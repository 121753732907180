import { ErrorHandler } from '@angular/core';
// Hammer JS
import 'hammerjs';
import * as json from 'highlight.js/lib/languages/json';
import * as scss from 'highlight.js/lib/languages/scss';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as xml from 'highlight.js/lib/languages/xml';
// Highlight JS
import { HighlightLanguage } from 'ngx-highlightjs';
import { IConfig } from 'ngx-mask';
// Perfect Scroll bar
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// Env
import { environment } from '../environments/environment';
// Layout Services
import { LayoutConfigService } from './core/_base/layout';
// Config
import { LayoutConfig } from './core/_config/layout.config';
export const options = {};
import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: environment.sentryDSN
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        console.error(error);
        Sentry.captureException(error.originalError || error);
        // const eventId = Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({ eventId });
    }
}
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};
export function initializeLayoutConfig(appConfig) {
    // initialize app by loading default demo layout config
    return () => {
        const appVersion = localStorage.getItem('app_version');
        if (!appVersion || appVersion !== environment.appVersion) {
            // reset layout config and store version
            localStorage.clear();
            appConfig.resetConfig();
            localStorage.setItem('app_version', environment.appVersion);
        }
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}
export function hljsLanguages() {
    return [
        { name: 'typescript', func: typescript },
        { name: 'scss', func: scss },
        { name: 'xml', func: xml },
        { name: 'json', func: json }
    ];
}
const ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ1 = { languages: hljsLanguages };
export class AppModule {
}
export { ɵ0, ɵ1 };
