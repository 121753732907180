import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class TranslationService {
    /**
     * Service Constructor
     *
     * @param translate: TranslateService
     */
    constructor(translate) {
        this.translate = translate;
        // Private properties
        this.langIds = [];
        // add new langIds to the list
        this.translate.addLangs(['en']);
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
    }
    /**
     * Load Translation
     *
     * @param args: Locale[]
     */
    loadTranslations(...args) {
        const locales = [...args];
        locales.forEach(locale => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translate.setTranslation(locale.lang, locale.data, true);
            this.langIds.push(locale.lang);
        });
        // add new languages to the list
        this.translate.addLangs(this.langIds);
    }
    /**
     * Setup language
     *
     * @param lang: any
     */
    setLanguage(lang) {
        if (lang) {
            this.translate.use(this.translate.getDefaultLang());
            this.translate.use(lang);
            localStorage.setItem('language', lang);
        }
    }
    /**
     * Returns selected language
     */
    getSelectedLanguage() {
        return localStorage.getItem('language') || this.translate.getDefaultLang();
    }
}
TranslationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.ɵɵinject(i1.TranslateService)); }, token: TranslationService, providedIn: "root" });
