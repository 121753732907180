// NGRX
import {Action} from '@ngrx/store';
import {DocumentModel} from '../../models/document';
import {Entity, EntityResponse, EntityType} from '../../models/entity';


export enum EntitiesActionTypes {
    LOAD_ONBOARDING_ENTITIES = '[ONBOARDING ENTITIES] Load Onboarding Entities',
    LOAD_CORPORATE_ENTITIES = '[CORPORATE ENTITIES] Load Corporate Entities',
    LOAD_INDIVIDUAL_ENTITIES = '[INDIVIDUAL ENTITIES] Load Individual Entities',
    GET_ENTITIES = '[Entities] Get Entities',
    GET_ENTITIES_FAILURE = '[Entities] Get Entities Failure',
    GET_SUB_ENTITIES = '[SUB ENTITIES] Get Sub Entities',
    LOAD_SUB_ENTITIES = '[SUB ENTITIES] Load Sub Entities',
    CREATE_ENTITY = '[Entities] Create Entity',
    ADD_ENTITY = '[Entities] Add Entity',
    ADD_ENTITY_SUCCESS = '[Entities] Add Entity Success',
    ADD_ENTITY_FAILURE = '[Entities] Add Entity Failure',
    EDIT_ENTITY = '[Entities] Edit Entity',
    EDIT_ENTITY_SUCCESS = '[Entities] Entity Edited',
    EDIT_ENTITY_FAIL = '[Entities] Entity Failed to Edit',
    DELETE_ENTITY = '[Entities] Delete Entity',
    DELETE_ENTITY_SUCCESS = '[Entities] Entity Deleted',
    DELETE_ENTITY_FAIL = '[Entities] Entity Failed to Delete',
    ADD_DOCUMENT_TO_ENTITY = '[Entities] Add Document to Entity',
    ADD_DOCUMENT_TO_SUCCESS = '[Entities] Add Document to Entity Success',
    ADD_DOCUMENT_TO_FAILURE = '[Entities] Add Document to Entity Failure',
    ENTITIES_TOGGLE_LOADING = '[Entities] Toggle Loading',
    UPDATE_ENTITY = '[Entities] Update Corporate Entity',
    UPDATE_INDIVIDUAL_ENTITY = '[Entities] Update Individual Entity'
}


export class LoadOnboardingEntitiesAction implements Action {
    readonly type = EntitiesActionTypes.LOAD_ONBOARDING_ENTITIES;

    constructor(public payload: EntityResponse) {
    }
}

export class LoadCorporateEntitiesAction implements Action {
    readonly type = EntitiesActionTypes.LOAD_CORPORATE_ENTITIES;

    constructor(public payload: EntityResponse) {
    }
}

export class LoadIndivitualEntitiesAction implements Action {
    readonly type = EntitiesActionTypes.LOAD_INDIVIDUAL_ENTITIES;

    constructor(public payload: EntityResponse) {
    }
}

export class LoadSubEntitiesAction implements Action {
    readonly type = EntitiesActionTypes.LOAD_SUB_ENTITIES;

    constructor(public payload: { entity_id: string, subEntities: Entity[] }) {
    }
}

export class EntitiesToggleLoadingAction implements Action {
    readonly type = EntitiesActionTypes.ENTITIES_TOGGLE_LOADING;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class GetEntitiesAction implements Action {
    readonly type = EntitiesActionTypes.GET_ENTITIES;

    constructor(public payload?: { query: any, type?: string }) {
    }
}

export class GetSubEntitiesAction implements Action {
    readonly type = EntitiesActionTypes.GET_SUB_ENTITIES;

    constructor(public payload: string) {
    }
}

export class GetEntitiesActionFailure implements Action {
    readonly type = EntitiesActionTypes.GET_ENTITIES_FAILURE;

    constructor(public payload: Error) {
    }
}

export class EntityCreatedAction implements Action {
    readonly type = EntitiesActionTypes.ADD_ENTITY;

    constructor(public payload: Entity) {
    }
}

export class CreateEntityAction implements Action {
    readonly type = EntitiesActionTypes.CREATE_ENTITY;

    constructor(public payload: Entity) {
    }
}

export class EditEntityAction implements Action {
    readonly type = EntitiesActionTypes.EDIT_ENTITY;

    constructor(public payload: Entity) {
    }
}

export class DeleteEntityAction implements Action {
    readonly type = EntitiesActionTypes.DELETE_ENTITY;

    constructor(public payload: Entity) {
    }
}

export class AddDocumentToEntityAction implements Action {
    readonly type = EntitiesActionTypes.ADD_DOCUMENT_TO_ENTITY;

    constructor(public payload: DocumentModel) {
    }
}

export class UpdateEntity implements Action {
    readonly type = EntitiesActionTypes.UPDATE_ENTITY;

    constructor(public payload: { entity: Entity,  entityType: string}) {
    }
}

export type EntitiesActions
    = EditEntityAction
    | EntityCreatedAction
    | EntitiesToggleLoadingAction
    | LoadOnboardingEntitiesAction
    | LoadCorporateEntitiesAction
    | LoadIndivitualEntitiesAction
    | LoadSubEntitiesAction
    | GetEntitiesAction
    | GetSubEntitiesAction
    | DeleteEntityAction
    | CreateEntityAction
    | GetEntitiesActionFailure
    | AddDocumentToEntityAction
    | UpdateEntity;
