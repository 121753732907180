<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
    <!--begin: Search -->
    <!--<kt-search-dropdown [icon]="'./assets/media/icons/svg/General/Search.svg'" [useSVG]="true"></kt-search-dropdown>-->
    <!--end: Search -->
    <!--begin: Notifications -->
    <!--<kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'" [icon]="'./assets/media/icons/svg/Code/Compiling.svg'"
                     [useSVG]="true"></kt-notification>-->
    <!--end: Notifications -->
    <!--begin: Quick actions -->
    <!--<kt-quick-action [bgImage]="'./assets/media/misc/bg-2.jpg'" [skin]="'dark'" [icon]="'./assets/media/icons/svg/Media/Equalizer.svg'"
                     [useSVG]="true"></kt-quick-action>-->
    <!--end: Quick actions -->
    <!--begin: My Cart -->
    <!--<kt-cart [bgImage]="'./assets/media/misc/bg-1.jpg'" [icon]="'./assets/media/icons/svg/Shopping/Cart%233.svg'" [useSVG]="true"></kt-cart>-->
    <!--end: My Cart -->
    <!--begin: Quick panel toggler -->
    <!--
    <div class="kt-header__topbar-item kt-header__topbar-item--quick-panel" data-placement="bottom" ngbTooltip="Quick panel">
		<span class="kt-header__topbar-icon" id="kt_quick_panel_toggler_btn">
			<span class="kt-svg-icon" [inlineSVG]="'./assets/media/icons/svg/Layout/Layout-4-blocks.svg'"></span>
		</span>
    </div>
    -->
    <!--end: Quick panel toggler -->
    <!--begin: Language bar -->
    <!--<kt-language-selector></kt-language-selector>-->
    <!--end: Language bar -->
    <!--begin: User bar -->
    <kt-user-profile></kt-user-profile>
    <!--end: User bar -->
</div>
<!-- end:: Header Topbar -->
