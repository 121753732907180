import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import * as moment from 'moment'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'
import { RestApiService } from './rest-api.service'
import { User } from '../models/user'
import { SystemUser } from '../models/system-user'

@Injectable()
export class AuthenticationService {
    public readonly accessToken$: Observable<string> = of(localStorage.getItem('accessToken'))
    public readonly isAuthenticated$: Observable<boolean> = of(this.isLoggedIn())
    public readonly hasUser$: Observable<boolean> = of(this.hasUser())
    private currentUserSubject: BehaviorSubject<User>;
    private currentTokenSubject: BehaviorSubject<any>;
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    private currentTokenSubject$ = new BehaviorSubject<any>(null);
    userToken$ = this.currentTokenSubject$.asObservable();
    userProfile$ = this.userProfileSubject$.asObservable();

    public currentUser: Observable<User>;
    loggedIn: boolean = null;

    constructor(private http: HttpClient, private router: Router, private restApiService: RestApiService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    handleAuthCallback(authResult) {

        const token: JwtPayload = jwt_decode(authResult.accessToken)
        this.loggedIn = false
        if (!token) {
            console.warn('Error Token invalid.')
        }

        if (token.exp && token.exp.toString() === authResult.expiresAt) {
            localStorage.setItem('accessToken', authResult.accessToken)
            localStorage.setItem('accessTokenExpiresAt', authResult.expiresAt)
            this.loggedIn = true
            this.currentTokenSubject$.next(authResult.accessToken)
        } else {
            console.warn('Error Token expiry manipulation.')
        }
        if(this.loggedIn) {
            window.location.href = '/dashboard'
        } else {
            window.location.href = '/error/401'
        }
    }

    login(url?) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('accessTokenExpiresAt')
        localStorage.removeItem('currentUser')
        setTimeout(() => {
            window.location.href = environment.guardian.loginUrl
            // window.location.href = this.configService.get('guardian.loginUrl')
        }, 1000)
    }

    localAuthSetup() {
        // This should only be called on app initialization
        // Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return this.getUser$();
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
            // If authenticated, response will be user object
            // If not authenticated, response will be 'false'
            this.loggedIn = !!response;
        });
    }

    logout() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('accessTokenExpiresAt')
        localStorage.removeItem('currentUser')
        window.location.href = '/'
    }

    public isLoggedIn(): boolean {
        if (localStorage.getItem('accessToken') && localStorage.getItem('accessTokenExpiresAt')) {
            const loggedIn = moment().isBefore(this.getExpiration())
            if (!loggedIn) {
                console.warn('Error Token expired, redirecting to login.')
                this.logout()
            }
            return loggedIn
        }
        return false
    }

     public getUser$(options?): Observable<any> {
         const cachedUser = localStorage.getItem('currentUser')
         if (cachedUser) {
             return of(JSON.parse(cachedUser))
         }

         const accessToken = localStorage.getItem('accessToken')
         const token = accessToken ? jwt_decode(accessToken) : undefined
         const email = token ? token['email'] : undefined
         if (!email) {
             console.error('No authorised user email found in token.')
             return of(null)
         }

         return this.http.get<SystemUser>(`${environment.authUrl}/admin/validate`).pipe(
             tap(user => {
                 localStorage.setItem('currentUser', JSON.stringify(user))
                 this.userProfileSubject$.next(user)
                 return of(user)
             }),
             catchError(error => {
                 console.error(`Failed to retrieve user profile: ${error.message}`)
                 return of(null)
             })
         )
     }

    public hasUser(): boolean {
        return localStorage.getItem('currentUser') ? true : false
    }

    isLoggedOut() {
        return !this.isLoggedIn()
    }

    getExpiration() {
        const expiration = localStorage.getItem('accessTokenExpiresAt')
        const expiresAt = parseInt(expiration)
        if (expiresAt) {
            return moment.unix(expiresAt)
        }
        return moment()
    }
}
