import { BehaviorSubject, Observable } from "rxjs";
import { Component, OnInit, SecurityContext } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { DomSanitizer } from '@angular/platform-browser'
import { RestApiService } from 'src/app/services/rest-api.service'
import { map } from "rxjs/operators";

@Component({
	selector: 'kt-document-viewer-modal',
	templateUrl: './document-viewer-modal.component.html',
	styleUrls: ['./document-viewer-modal.component.scss']
})
export class DocumentViewerModalComponent implements OnInit {
	document
	isLoading$: boolean = true
	fileUrl: BehaviorSubject<string> = new BehaviorSubject('')
	constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer, private restApiService: RestApiService) {}

	ngOnInit() {
		if (this.document.document && this.document.document.includes('base64,')) {
			this.fileUrl.next(this.sanitize(this.document.document))
		} else {
			this.getDocumentUrl(this.document).subscribe(res => {
				this.fileUrl.next(this.sanitize(res))
			})
		}
	}

	isImage(filename: string): boolean {
		const type = filename.split('.').pop()
		return ['jpg', 'gif', 'png', 'apng', 'bmp', 'jpeg', 'webp', 'svg', 'pjp', 'pjpeg', 'jfif'].includes(type)
	}

	onDownloadFile(file) {
		if (file.document && file.document.includes('base64,')) {
			this.downloadFile(file.document)
		} else {
			this.restApiService.downloadDocument(file).subscribe((res) => {
				const blob = new Blob([res], { type: file.mime_type })
				this.downloadFile(URL.createObjectURL(blob))
			})
		}
	}

	private sanitize(url) {
		return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(url))
	}

	private getDocumentUrl(doc): Observable<string> {
		return this.restApiService.findDocumentGenerateSignedUrl(doc).pipe(map(res => res.url));
	}

	private downloadFile(data: string): void {
		const a = document.createElement('a')
		a.href = data
		a.download = this.document.filename
		a.click()
	}
}
