import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EntityProfilesActionTypes, GetEntityProfileAction } from '../actions/entity-profiles';
export class EntityProfileEffects {
    constructor(actions$, restApiService, router) {
        this.actions$ = actions$;
        this.restApiService = restApiService;
        this.router = router;
        // @Effect()
        // getEntity$: Observable<Action> = this.actions$
        //     .pipe(ofType(EntityProfilesActionTypes.GET_PROFILE),
        //         mergeMap(() =>
        //             this.restApiService.getEntities()
        //                 .pipe(
        //                     map((entities) => {
        //                         const newEntities = entities.map(entity => ({...entity, documents: []}));
        //                         return {
        //                             type: EntityProfilesActionTypes.LOAD_ONBOARDING_ENTITIES,
        //                             payload: newEntities
        //                         };
        //                     }),
        //                     catchError(error => of({
        //                         type: EntitiesActionTypes.GET_ENTITIES_FAILURE,
        //                         payload: error.error
        //                     })))
        //         ));
        this.getEntityProfile$ = this.actions$
            .pipe(ofType(EntityProfilesActionTypes.GET_PROFILE), map((action) => action.payload), switchMap(payload => {
            return this.restApiService.getEntityProfile(payload)
                .pipe(map(entity => ({
                type: EntityProfilesActionTypes.ADD_PROFILE,
                payload: entity
            })), catchError(error => of({
                type: EntityProfilesActionTypes.ADD_PROFILE_FAILURE,
                payload: error.error
            })));
        }));
        this.getSubEntityProfile$ = this.actions$
            .pipe(ofType(EntityProfilesActionTypes.GET_SUB_PROFILE), map((action) => action.payload), switchMap(payload => {
            return this.restApiService.getEntityProfile(payload)
                .pipe(map(entity => ({
                type: EntityProfilesActionTypes.ADD_PROFILE,
                payload: entity
            })), catchError(error => of({
                type: EntityProfilesActionTypes.ADD_PROFILE_FAILURE,
                payload: error.error
            })));
        }));
    }
}
tslib_1.__decorate([
    Effect()
], EntityProfileEffects.prototype, "getEntityProfile$", void 0);
tslib_1.__decorate([
    Effect()
], EntityProfileEffects.prototype, "getSubEntityProfile$", void 0);
