/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./footer.component";
import * as i3 from "../../../core/_base/layout/services/layout-config.service";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "kt-container"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(5, { "kt-container--fluid": 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "row"], ["style", "width:100%"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "col-md-4 text-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", "\u00A0\u00A9\u00A0"])), i0.ɵppd(10, 2), (_l()(), i0.ɵeld(11, 0, null, null, 1, "a", [["class", "kt-link"], ["href", "https://bcbgroup.io"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["BCB Group"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "kt-container"; var currVal_1 = _ck(_v, 5, 0, _co.fluid); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), _co.today, "yyyy")); _ck(_v, 9, 0, currVal_2); }); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "kt-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i2.FooterComponent, [i3.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i0.ɵccf("kt-footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
