<div class="modal-header">
    <h5 class="modal-title">{{getModalTitle()}}</h5>
    <button type="button" class="close" (click)="activeModal.close()">
    </button>
</div>
<div class="modal-body">
    <mat-tab-group [(selectedIndex)]="currentStep">
        <mat-tab label="Contacts">
            <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
            <ng-container *ngIf="!loading">
                <mat-checkbox class="px-3" [checked]="noDedicatedContacts"
                              (change)="onContactCheckboxChange($event.checked)">
                    This client does not have dedicated contacts
                </mat-checkbox>
                <form [formGroup]="contactsFormGroup" class="d-flex flex-column px-3 pt-3">
                    <mat-form-field appearance="outline" *ngFor="let grouping of contactGroupings">
                        <mat-label>{{grouping.label}}</mat-label>
                        <mat-select [formControlName]="grouping.key">
                            <mat-option *ngFor="let contact of getContactsByRelationship(grouping.key)" [value]="contact">
                                {{contact.fullName}}
                            </mat-option>
                            <mat-option value="no_value">
                                None
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </ng-container>
        </mat-tab>
        <mat-tab [disabled]="!canProceed(0)" label="Accounts">
            <table class="table">
                <thead>
                <tr>
                    <td>
                        <mat-checkbox
                                [indeterminate]="selectedAccounts.size > 0 && selectedAccounts.size !== accountsList.length"
                                [checked]="selectedAccounts.size === accountsList.length"
                                (change)="onSelectAllAccounts()"></mat-checkbox>
                    <td>Account label</td>
                    <td>CCY</td>
                    <td>Bank</td>
                    <td>IBAN</td>
                </tr>
                </thead>
                <tbody>
                <tr class="cursor-pointer" *ngFor="let account of accountsList"
                    (click)="onCheckChanged(account, !selectedAccounts.has(account))">
                    <td>
                        <mat-checkbox class="no-event" [checked]="selectedAccounts.has(account)"></mat-checkbox>
                    </td>
                    <td>{{account.account_label}}</td>
                    <td>{{account.ccy}}</td>
                    <td>{{(account.source | titlecase) || 'Unknown'}}</td>
                    <td>{{account.iban || 'Not found'}}</td>
                </tr>
                </tbody>
            </table>
        </mat-tab>
        <mat-tab [disabled]="!canProceed(1)" label="Email">
            <div class="d-flex flex-column align-items-stretch">
                <label>Type of welcome pack:</label>
                <mat-radio-group (change)="isUpdatedPack = $event.value; customEmailBody ? onCustomEmailBodyChange(customEmailBody) : undefined">
                    <mat-radio-button [value]="false">New client</mat-radio-button>
                    <mat-radio-button [value]="true">Update for existing client</mat-radio-button>
                </mat-radio-group>
                <p class="mb-0 mt-3" *ngIf="emailsList.length">Send to:</p>
                <div class="chip-list mt-0 pt-1">
                    <ng-container *ngFor="let email of emailsList"
                                  [ngTemplateOutlet]="emailChip"
                                  [ngTemplateOutletContext]="{email: email}"></ng-container>
                </div>
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Email address</mat-label>
                    <input matInput
                           placeholder="Email address"
                           [formControl]="emailControl"
                           (keydown.enter)="onAddEmail()">
                    <button *ngIf="emailControl.value && emailControl.valid"
                            mat-icon-button
                            matSuffix
                            [attr.aria-label]="'add email'"
                            (click)="onAddEmail()">
                        Add
                    </button>
                    <mat-error *ngIf="emailControl.invalid">Invalid email</mat-error>
                </mat-form-field>
                <mat-checkbox (change)="onCustomEmailBodyChange($event.checked)">
                    Override the default email body for {{isUpdatedPack ? 'updated' : 'new'}} welcome packs:
                </mat-checkbox>
                <mat-form-field *ngIf="customEmailBody" class="w-100" appearance="outline">
                    <mat-label>Email body</mat-label>
                    <textarea matInput [formControl]="emailBodyControl"
                              placeholder="Provide a custom body for the {{isUpdatedPack ? 'updated' : 'new'}} welcome packs email"
                              rows="10"
                    ></textarea>
                    <mat-error *ngIf="emailControl.invalid">Invalid email</mat-error>
                </mat-form-field>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="modal-footer d-flex align-items-center justify-content-between">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Close</button>
    <div>
        <button type="button" class="btn btn-outline-primary" *ngIf="currentStep > 0" (click)="onBackClicked()">Back
        </button>
        <button type="button" class="btn btn-primary" [disabled]="!canProceed()"
                (click)="onNextClicked()">{{ currentStep < maxStepIndex ? 'Next' : 'Send'}}</button>
    </div>
</div>

<ng-template #emailChip let-email="email">
    <div class="email-chip">
        <span class="text">{{email}}</span>
        <button mat-icon-button (click)="onRemoveEmail(email)">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</ng-template>