// Angular
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
	MAT_DATE_LOCALE,
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatSelectModule,
	MatSnackBarModule,
	MatSortModule,
	MatStepperModule,
	MatTableModule,
	MatTabsModule,
	MatTooltipModule
} from '@angular/material'
import { RouterModule } from '@angular/router'
// NgBootstrap
import { NgbDropdownModule, NgbModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg'
import { NgxMaskModule } from 'ngx-mask'
// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
// Core module
import { CoreModule } from '../../core/core.module'
// CRUD Partials
import {
	ActionNotificationComponent,
	AlertComponent,
	DeleteEntityDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from './content/crud'
// Errpr
import { ErrorComponent } from './content/general/error/error.component'
// General
import { NoticeComponent } from './content/general/notice/notice.component'
import { PortletModule } from './content/general/portlet/portlet.module'
// Extra module
import { WidgetModule } from './content/widgets/widget.module'
// Layout partials
import {
	AddCorporateFormModalComponent,
	AddIndividualFormModalComponent,
	ContextMenu2Component,
	ContextMenuComponent,
	LanguageSelectorComponent,
	NotificationComponent,
	QuickActionComponent,
	QuickPanelComponent,
	ScrollTopComponent,
	SearchDefaultComponent,
	SearchDropdownComponent,
	SearchResultComponent,
	SplashScreenComponent,
	StickyToolbarComponent,
	Subheader1Component,
	Subheader2Component,
	Subheader3Component,
	Subheader4Component,
	Subheader5Component,
	SubheaderSearchComponent,
	UserProfile2Component,
	UserProfile3Component,
	UserProfileComponent
} from './layout'
import { CartComponent } from './layout/topbar/cart/cart.component'
import { ConfirmationDialogComponent } from './layout/confirmation-dialog/confirmation-dialog.component'
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation'
import { InternationalPhoneInputModule } from '../../components/mat-intl-phone/international-phone-input.module'
import { InOutBoundComponent } from './layout/add-corporate-form-modal/in-out-bound/in-out-bound.component'
import { TransactionFeesComponentComponent } from './layout/add-corporate-form-modal/transaction-fees-component/transaction-fees-component.component'
import { TransactionsConfirmationComponent } from './layout/add-corporate-form-modal/transactions-confirmation/transactions-confirmation.component'
import { BalanceFeeComponent } from './layout/add-corporate-form-modal/balance-fee/balance-fee.component'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { MatMomentDateModule } from '@angular/material-moment-adapter'
import { FormatDatePipe } from './layout/add-corporate-form-modal/utils/entity-creation-service/format-date.pipe'

@NgModule({
	declarations: [
		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,

		AddCorporateFormModalComponent,
		AddIndividualFormModalComponent,
		// topbar components
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		StickyToolbarComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		SubheaderSearchComponent,
		LanguageSelectorComponent,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		UserProfile2Component,
		UserProfile3Component,
		CartComponent,
		ErrorComponent,
		ConfirmationDialogComponent,
		InOutBoundComponent,
		TransactionFeesComponentComponent,
		BalanceFeeComponent,
		TransactionsConfirmationComponent,
		FormatDatePipe
	],
	exports: [
		WidgetModule,
		PortletModule,

		ScrollTopComponent,
		NoticeComponent,
		ActionNotificationComponent,
		DeleteEntityDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,
		AlertComponent,

		// topbar components
		ContextMenu2Component,
		ContextMenuComponent,
		QuickPanelComponent,
		ScrollTopComponent,
		SearchResultComponent,
		SplashScreenComponent,
		StickyToolbarComponent,
		Subheader1Component,
		Subheader2Component,
		Subheader3Component,
		Subheader4Component,
		Subheader5Component,
		SubheaderSearchComponent,
		LanguageSelectorComponent,
		NotificationComponent,
		QuickActionComponent,
		SearchDefaultComponent,
		SearchDropdownComponent,
		UserProfileComponent,
		UserProfile2Component,
		UserProfile3Component,
		CartComponent,
		AddCorporateFormModalComponent,
		AddIndividualFormModalComponent,
		ErrorComponent,
		ConfirmationDialogComponent,
		TransactionFeesComponentComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		InlineSVGModule,
		CoreModule,
		PortletModule,
		WidgetModule,
		NgbModule,
		// angular material modules
		MatButtonModule,
		MatMenuModule,
		MatSelectModule,
		MatInputModule,
		MatTableModule,
		MatAutocompleteModule,
		MatRadioModule,
		MatIconModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatCardModule,
		MatPaginatorModule,
		MatSortModule,
		MatCheckboxModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatTabsModule,
		MatTooltipModule,
		MatDialogModule,
		MatStepperModule,
		NgxMaskModule.forChild(),
		MatDividerModule,
		MatGridListModule,
		// ng-bootstrap modules
		NgbDropdownModule,
		NgbTabsetModule,
		NgbTooltipModule,
		NgBootstrapFormValidationModule.forRoot(),
		InternationalPhoneInputModule,
		MatMomentDateModule
	],
	entryComponents: [
		AddIndividualFormModalComponent,
		AddCorporateFormModalComponent,
		ConfirmationDialogComponent,
		ActionNotificationComponent,
		FetchEntityDialogComponent,
		DeleteEntityDialogComponent
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
	]
})
export class PartialsModule {}
