import * as tslib_1 from "tslib";
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { PaymentRailContractFees } from '../../transaction-fees-component/utils/payment-rail-contract.dto';
import { ValueType } from './value-type.enum';
import { CHARGE_CURRENCY } from './constants';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../services/rest-api.service";
export class AccountFeesStateService {
    constructor(restApiService) {
        this.restApiService = restApiService;
        this.accounts = new BehaviorSubject([]);
        this.generalFees = new BehaviorSubject([]);
        this.entityGeneralFees = new BehaviorSubject(undefined);
        this.paymentRails = new BehaviorSubject(undefined);
        this.paymentRailContractFees = new BehaviorSubject(undefined);
        this.finalisedPaymentRailContractFees = new BehaviorSubject(undefined);
        this.validPaymentRailContractFees = new BehaviorSubject(false);
        this.feesConfirmed = new BehaviorSubject(false);
        this.detailsSubmitted = new BehaviorSubject(false);
        this.currentUser = new BehaviorSubject(undefined);
        this.shouldClose = new Subject();
        this.transactionsFormDisabled = new BehaviorSubject(false);
        this.currentEntityId = new BehaviorSubject(null);
        this.formType = new BehaviorSubject('default');
        this.newCurrency = new BehaviorSubject('');
        this.accounts.pipe(takeUntil(this.shouldClose));
        this.generalFees.pipe(takeUntil(this.shouldClose));
        this.entityGeneralFees.pipe(takeUntil(this.shouldClose));
        this.detailsSubmitted.pipe(takeUntil(this.shouldClose));
        this.feesConfirmed.pipe(takeUntil(this.shouldClose));
        this.paymentRails.pipe(takeUntil(this.shouldClose));
        this.paymentRailContractFees.pipe(takeUntil(this.shouldClose));
        this.finalisedPaymentRailContractFees.pipe(takeUntil(this.shouldClose));
        this.validPaymentRailContractFees.pipe(takeUntil(this.shouldClose));
        this.currentUser.pipe(takeUntil(this.shouldClose));
        this.transactionsFormDisabled.pipe(takeUntil(this.shouldClose));
        this.currentEntityId.pipe(takeUntil(this.shouldClose));
        this.formType.pipe(takeUntil(this.shouldClose));
        this.newCurrency.pipe(takeUntil(this.shouldClose));
    }
    resetAll() {
        this.accounts.next([]);
        this.generalFees.next([]);
        this.entityGeneralFees.next(undefined);
        this.paymentRails.next(undefined);
        this.paymentRailContractFees.next(undefined);
        this.finalisedPaymentRailContractFees.next(undefined);
        this.newCurrency.next('');
        this.validPaymentRailContractFees.next(false);
        this.feesConfirmed.next(false);
        this.detailsSubmitted.next(false);
        this.shouldClose.next();
        this.transactionsFormDisabled.next(false);
        this.formType.next('default');
    }
    setNewCurrency(ccy) {
        this.newCurrency.next(ccy);
    }
    setFormType(type) {
        this.formType.next(type);
    }
    disableTransactionsForm(value) {
        this.transactionsFormDisabled.next(value);
    }
    // Form State
    updateDetailsSubmitted(value) {
        this.detailsSubmitted.next(value);
    }
    updateFeesConfirmed(value) {
        this.detailsSubmitted.next(value);
        this.feesConfirmed.next(value);
    }
    setCurrentEntityId(entityId) {
        this.currentEntityId.next(entityId);
    }
    getCurrentEntityId() {
        return this.currentEntityId.value;
    }
    setCurrentUser(user) {
        this.currentUser.next(user);
    }
    getCurrentUser() {
        return this.currentUser.value;
    }
    //Sends a notification to close all watched subscription
    close() {
        this.shouldClose.next(true);
    }
    //
    //Accounts State
    //
    addUpdateAccount(account, index = -1) {
        const _accounts = this.accounts.value;
        if (index > -1) {
            _accounts[index] = account;
        }
        else {
            _accounts.push(account);
        }
        this.accounts.next(_accounts.filter(account => account.currency && account.effectiveDate));
    }
    clearAccounts() {
        this.accounts.next([]);
    }
    removeAccount(index) {
        const _accounts = this.accounts.value;
        const account = _accounts[index];
        const paymentRails = this.paymentRails.value;
        const paymentRailContractFees = this.paymentRailContractFees.value;
        if (paymentRails && account.currency) {
            const updatedPaymentRails = paymentRails.filter(rail => rail.fromCcy != account.currency);
            this.paymentRails.next(updatedPaymentRails);
        }
        if (paymentRailContractFees && account.currency) {
            const updatedPaymentRailContractFees = paymentRailContractFees.filter(fee => fee.paymentRail.fromCcy != account.currency);
            this.paymentRailContractFees.next(updatedPaymentRailContractFees);
            this.finalisePaymentRails();
        }
        _accounts.splice(index, 1);
        this.accounts.next(_accounts);
    }
    //
    // General Fees State
    //
    updateGeneralFees(data) {
        this.generalFees.next(data);
    }
    updateEntityGeneralFees(entityId, form) {
        const currentUser = this.getCurrentUser();
        const _entityGeneralFees = this.generalFees.value.map(generalFee => {
            generalFee.createdBy = currentUser.id || undefined;
            generalFee.effectiveDate = moment(this.accounts.value[0] ? this.accounts.value[0].effectiveDate : new Date(), 'DD/MM/YYYY').toDate();
            generalFee.createdAt = new Date();
            let amount;
            const feeCategory = generalFee.category.description.toLowerCase();
            if (feeCategory.includes('setup fee')) {
                amount = Number(form.get('setup_fee').value);
            }
            else if (feeCategory.includes('management fee')) {
                amount = Number(form.get('management_fee').value);
            }
            else if (feeCategory.includes('minimum transaction fees')) {
                amount = Number(form.get('min_transaction_fees').value);
            }
            else if (feeCategory.includes('general exceptions')) {
                amount = Number(form.get('general_exceptions').value);
            }
            else if (feeCategory.includes('fraud claim exceptions')) {
                amount = Number(form.get('fraud_claim_exceptions').value);
            }
            else if (feeCategory.includes('minimum term')) {
                amount = Number(form.get('minimum_term').value);
            }
            return Object.assign({ entityId: entityId, accountCurrency: CHARGE_CURRENCY }, generalFee, { amount });
        });
        this.entityGeneralFees.next(_entityGeneralFees);
    }
    //
    // Payment rail fees state
    //
    mapDefaultPaymentRailsFees(data) {
        this.populateDefaultFeesForPaymentRails(data).then(paymentRailConfigs => {
            const rails = new Map();
            const oldRails = this.paymentRails.value || [];
            const combineRails = [...oldRails, ...paymentRailConfigs];
            combineRails.map(rail => rails.set(`${rail.id} ${rail.fromCcy}`, rail));
            this.paymentRails.next([...rails.values()]);
        });
    }
    mapContractPaymentRailsFees(entityId, data) {
        this.populateContractFeesForPaymentRails(entityId, data).then(paymentRailConfigs => {
            const rails = new Map();
            const oldRails = this.paymentRails.value || [];
            const combineRails = [...oldRails, ...paymentRailConfigs];
            combineRails.map(rail => rails.set(`${rail.id} ${rail.fromCcy}`, rail));
            this.paymentRails.next([...rails.values()]);
        });
    }
    updatePaymentRail(id, currency, data) {
        let _paymentRails = this.paymentRails.value;
        _paymentRails = _paymentRails.map(rail => {
            if (rail.id === id && rail.fromCcy === currency)
                rail.paymentRailFees = data;
            return rail;
        });
        this.paymentRails.next(_paymentRails);
    }
    updatePaymentRailBalanceFee(data) {
        let _paymentRails = this.paymentRails.value;
        _paymentRails = _paymentRails.map(rail => {
            if (rail.fromCcy === data.currency) {
                rail.paymentRailFees.map(fees => {
                    fees.balanceFee = data.amount;
                });
            }
            return rail;
        });
        this.paymentRails.next(_paymentRails);
    }
    updatePaymentRailContractFees(data) {
        if (!data)
            return this.paymentRailContractFees.next(null);
        const _paymentRailContractFees = data.map(paymentRailFee => {
            const updatedFee = paymentRailFee;
            updatedFee.effectiveFromDate = this.getEffectiveDate(paymentRailFee.currencyCode);
            return updatedFee;
        });
        this.checkValidFees(_paymentRailContractFees);
        this.paymentRailContractFees.next(_paymentRailContractFees);
    }
    /**
     * Process the paymentRails value and set the finalisedPaymentRailContractFees
     */
    finalisePaymentRails() {
        if (!this.paymentRails.value) {
            console.error(`Payment rails are not defined`);
            this.finalisedPaymentRailContractFees.next([]);
            return;
        }
        const paymentRailFees = this.paymentRails.value.map(rail => rail.paymentRailFees);
        const paymentRailContractFees = paymentRailFees.concat.apply([], paymentRailFees);
        const uniqueFees = [...new Map(paymentRailContractFees.map(item => [btoa(JSON.stringify(item)), item])).values()];
        this.finalisedPaymentRailContractFees.next(uniqueFees);
    }
    getEffectiveDate(ccy) {
        const account = this.accounts.value.find(account => account.currency == ccy);
        if (account) {
            return moment(account.effectiveDate, 'DD/MM/YYYY').toDate();
        }
        else {
            return new Date();
        }
    }
    populateDefaultFeesForPaymentRails(paymentRails) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!paymentRails)
                return null;
            const paymentRailConfigs = [];
            for (const paymentRail of paymentRails) {
                try {
                    const res = yield this.restApiService.getTransactionalDefaultFees(paymentRail.id).toPromise();
                    paymentRailConfigs.push(this.setDefaultFees(paymentRail, res));
                }
                catch (error) {
                    console.error(`CatchError: getPaymentRailDefaultFees: `, error);
                }
            }
            return paymentRailConfigs;
        });
    }
    populateContractFeesForPaymentRails(entityId, paymentRails) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const paymentRailConfigs = [];
            for (const paymentRail of paymentRails) {
                try {
                    const res = yield this.restApiService.getTransactionalContractFees(entityId, paymentRail.fromCcy, paymentRail.id).toPromise();
                    paymentRailConfigs.push(this.setDefaultFees(paymentRail, res));
                }
                catch (error) {
                    console.error(`CatchError: getPaymentRailDefaultFees: `, error);
                }
            }
            return paymentRailConfigs;
        });
    }
    setDefaultFees(paymentRailConfig, feeData) {
        const updatedConfig = paymentRailConfig;
        const currentUser = this.getCurrentUser();
        updatedConfig.paymentRailFees = feeData.map(fee => {
            const updatedFee = new PaymentRailContractFees();
            updatedFee.type = fee.defFeeRelPercent ? ValueType.REL : ValueType.ABS;
            updatedFee.createdAt = moment(moment.now()).format();
            updatedFee.paymentRailId = paymentRailConfig.id;
            updatedFee.createdBy = fee.createdBy;
            updatedFee.chargedCcyCode = CHARGE_CURRENCY;
            updatedFee.currencyCode = paymentRailConfig.fromCcy;
            updatedFee.direction = fee.direction;
            updatedFee.amount = Number(fee.defFeeAbsAmount) || undefined;
            updatedFee.defFeeAbsAmount = Number(fee.defFeeAbsAmount) || undefined;
            updatedFee.defFeeCapAmount = Number(fee.defFeeCapAmount) || undefined;
            updatedFee.defFeeFloorAmount = Number(fee.defFeeFloorAmount) || undefined;
            updatedFee.defFeeRelPercent = Number(fee.defFeeRelPercent) || undefined;
            updatedFee.balanceFee = Number(fee.balanceFee) || undefined;
            updatedFee.paymentRail = fee.paymentRail;
            return updatedFee;
        });
        return updatedConfig;
    }
    checkValidFees(paymentRailContractFees) {
        const invalid = paymentRailContractFees.filter(fee => fee.valid === false);
        const validFees = invalid.length === 0;
        this.validPaymentRailContractFees.next(validFees);
    }
}
AccountFeesStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountFeesStateService_Factory() { return new AccountFeesStateService(i0.ɵɵinject(i1.RestApiService)); }, token: AccountFeesStateService, providedIn: "root" });
