<mat-card>
    <div class='card-header bg-transparent'>
        <div class='card-heading'>KYC Reports</div>
        <span>
        <button type='button' mat-flat-button color='primary' [disabled]='isSubmittingForKyc' (click)='onKycCheck()'>
            Run KYC Spider Check
        </button>
        </span>
    </div>
    <div>
        <ngb-alert *ngIf='tempError' type='danger'>{{tempError?.error?.statusCode}} - {{tempError?.error?.message}}</ngb-alert>
    </div>
    <mat-card-content>
        <div class='table-container'>
            <div class='loading-shade'
                 *ngIf='isLoadingResults || error'>
                <mat-spinner *ngIf='isLoadingResults' size='ms'></mat-spinner>
                <div class='error' *ngIf='error'>
                    <p>{{error?.error?.statusCode}} - {{error?.error?.message}}</p>
                </div>
            </div>
            <table mat-table [dataSource]='kycReports'>

                <!-- Index Column -->
                <ng-container matColumnDef='index'>
                    <th mat-header-cell *matHeaderCellDef> No.</th>
                    <td mat-cell *matCellDef='let i = index'> {{paginator.pageIndex * paginator.pageSize + i + 1}} </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef='status'>
                    <th mat-header-cell *matHeaderCellDef> Status</th>
                    <td mat-cell *matCellDef='let report'> {{report.status | titlecase}} </td>
                </ng-container>

                <!-- Result Column -->
                <ng-container matColumnDef='result'>
                    <th mat-header-cell *matHeaderCellDef> Result</th>
                    <td mat-cell *matCellDef='let report'> {{report.result | titlecase}} </td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef='date'>
                    <th mat-header-cell *matHeaderCellDef> Date</th>
                    <td mat-cell *matCellDef='let report'> {{report.checkedAt | date:'yyyy/MM/dd HH:mm:ss' }} </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef='actions'>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef='let report'>
                        <button mat-icon-button>
                            <mat-icon *ngIf='report.documentName' (click)='onViewKycReport(viewDocumentModal, report.documentName)'>file_download</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef='displayedColumns; sticky: true'></tr>
                <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
            </table>
            <table>
                <tr *ngIf='!isLoadingResults && totalResults < 1'>
                    <td style='text-align: center;' [attr.colspan]='displayedColumns.length'>
                        No Records Found!
                    </td>
                </tr>
            </table>
            <table>
                <tr *ngIf='isLoadingResults'>
                    <td style='text-align: center;' [attr.colspan]='displayedColumns.length'>
                        Loading results...
                    </td>
                </tr>
            </table>

        </div>
        <mat-paginator [hidden]='totalResults < pageSize' [length]='totalResults' [pageSize]='pageSize' [pageSizeOptions]='[10 ,20, 30, 50, 100]'
                       showFirstLastButtons></mat-paginator>
    </mat-card-content>
</mat-card>

<ng-template #viewDocumentModal let-modal>
    <div class='modal-header'>
        <h4 class='modal-title'>View KYC report</h4>
    </div>
    <div class='modal-body'>
        <object id='viewDocumentModalData' type='application/pdf' style='width:100%;height:70vh;'></object>
    </div>
    <div class='modal-footer'>
        <button class='btn btn-primary float-right' (click)='dismissModal()'>Close</button>
    </div>
</ng-template>
