<div class="modal-header">
    <h4 class="modal-title">{{action}} Individual</h4>
</div>
<div class="modal-body">
    <form [formGroup]="individualForm" (validSubmit)="onSubmit()">
        <div class="kt-form kt-form--label-right">
            <div class="modal-body">
                <div class="kt-section kt-section--first">
                    <div class="kt-section__body">

                        <input type="hidden" formControlName="id">
                        <input type="hidden" formControlName="verified">
                        <input type="hidden" formControlName="status">
                        <input type="hidden" formControlName="code">
                        <input type="hidden" formControlName="entityId">

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">First name</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="text" formControlName="firstName">
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Last name</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="text" formControlName="lastName">
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label">Email</label>
                            <div class="col-lg-9 col-xl-6">
                                <input class="form-control" type="email" formControlName="email">
                                <bfv-messages></bfv-messages>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="onCancel()">Cancel</button>
    <button type="submit" class="btn btn-primary btn-bold nav-tab" (click)="onSubmit()">
        {{action}}</button>
</div>