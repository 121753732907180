export const environment = {
	appVersion: '1.7.0',
	production: false,
	baseUrl: 'https://services.sandbox.bcb.group',
	apiUrl: 'https://services.sandbox.bcb.group/admin',
	onfidoUrl: 'https://services.sandbox.bcb.group/onfido',
	kycSpiderUrl: 'https://kyc-spider.sandbox.bcb.group',
	identityVerificationApiUrl: 'https://identity-verification.sandbox.bcb.group',
	authUrl: 'https://services.sandbox.bcb.group/auth',
	ellipticUrl: 'https://elliptic.sandbox.bcb.group',
	authTokenKey: 'e566f251fae62cccde40e0e58b0edb6ff23fcf23',
	auth0Domain: 'bcb.eu.auth0.com',
	auth0ClientId: '5wo4rtxnTvkfUMTe2XoTb1r3UuLDfr84',
	auth0RedirectUri: `${window.location.origin}/auth/callback`,
	sentryDSN: 'https://8f0516112f0e47b39ba48ae0df53f6c2@o266742.ingest.sentry.io/5238650',
	guardian: {
		url: "https://guardian.sandbox.bcb.systems/v1",
		loginUrl: "https://guardian.sandbox.bcb.systems/v1/auth/sso/saml/login/admin-console"
	},
}
