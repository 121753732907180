import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Params } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { of } from 'rxjs'
import { Subscription } from 'rxjs/internal/Subscription'
import { catchError, first, map, startWith, switchMap } from 'rxjs/operators'
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud'
import { RestApiService } from '../../../../../services/rest-api.service'
import { KycReportModel } from './models/kyc-report.model'
import { ReportResponseModel } from './models/report-response.model'

@Component({
	selector: 'kt-kyc-reports',
	templateUrl: './kyc-reports.component.html',
	styleUrls: ['./kyc-reports.component.scss']
})
export class KycReportsComponent implements OnInit, AfterViewInit, OnDestroy {
	displayedColumns: string[] = ['index', 'status', 'result', 'date', 'actions']
	kycReports: Array<KycReportModel> = []
	totalResults = 0
	pageSize = 20
	entityId: string
	error: any
	tempError: any = undefined
	isLoadingResults = true
	isSubmittingForKyc = false
	subscriptions: Subscription = new Subscription()
	dataSource: MatTableDataSource<KycReportModel>
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
	private requestId: string

	constructor(
		public dialog: MatDialog,
		private readonly modalService: NgbModal,
		private readonly restApiService: RestApiService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly layoutUtilsService: LayoutUtilsService
	) {
		this.dataSource = new MatTableDataSource(this.kycReports)
	}

	ngOnInit() {
		this.dataSource.paginator = this.paginator
		this.subscriptions.add(
			this.activatedRoute.pathFromRoot[3].params.subscribe((params: Params) => {
				this.entityId = params.entityId
			})
		)

		this.getKycResults()
	}

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator
		this.subscriptions.add(
			this.paginator.page
				.pipe(
					startWith({}),
					switchMap(() => {
						this.isLoadingResults = true
						return this.restApiService.getKycReports(this.entityId, this.paginator.pageIndex, this.paginator.pageSize).pipe(
							catchError(err => {
								this.error = err
								return of(null)
							})
						)
					}),
					map((data: ReportResponseModel) => {
						this.isLoadingResults = false

						if (data === null) {
							return []
						}
						return data.checks
					})
				)
				.subscribe(data => (this.kycReports = data))
		)
	}

	onKycCheck(): void {
		this.isSubmittingForKyc = true
		this.layoutUtilsService.showActionNotification('KYC check requested.', MessageType.Update, 5000, true, false)
		this.restApiService
			.requestKycCheck(this.entityId)
			.pipe(first(data => !!data))
			.subscribe(
				() => {
					this.layoutUtilsService.showActionNotification('KYC request submitted successfully.', MessageType.Update, 5000, true, false)
					this.paginator.pageIndex = 0
					this.isSubmittingForKyc = false
					this.getKycResults()
				},
				() => {
					this.layoutUtilsService.showActionNotification('Error submitting KYC check.', MessageType.Update, 5000, true, false)
					this.isSubmittingForKyc = false
				}
			)
	}

	onViewKycReport(modal: any, documentName: string): void {
		this.restApiService.getReportUrl(this.entityId, documentName).subscribe(
			res => {
				this.modalService.open(modal, {
					size: 'xl',
					backdrop: 'static',
					keyboard: false
				})

				const element = document.getElementById('viewDocumentModalData')
				element.setAttribute('data', res.url)
			},
			err => {
				this.tempError = { ...err, error: { ...err.error, message: 'Error downloading document' } }
				window.setTimeout(() => {
					this.tempError = undefined
				}, 5000)
			}
		)
	}

	dismissModal(): void {
		// this.viewingDocumentUrl = undefined;
		this.modalService.dismissAll()
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe()
	}

	private getKycResults(): void {
		this.restApiService
			.getKycReports(this.entityId, 0, this.pageSize)
			.pipe(first(data => !!data))
			.subscribe(
				res => {
					this.isLoadingResults = false
					this.kycReports = res.checks
					this.totalResults = res.metadata.totalResults

					this.requestId = res.checks[0].id
				},
				err => {
					this.isLoadingResults = false
					this.error = err
					this.totalResults = undefined
				}
			)
	}
}
