/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./document-viewer-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ngx-doc-viewer/ngx-doc-viewer.ngfactory";
import * as i3 from "ngx-doc-viewer";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./document-viewer-modal.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../../services/rest-api.service";
var styles_DocumentViewerModalComponent = [i0.styles];
var RenderType_DocumentViewerModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentViewerModalComponent, data: {} });
export { RenderType_DocumentViewerModalComponent as RenderType_DocumentViewerModalComponent };
function View_DocumentViewerModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-doc-viewer", [["style", "width:100%;height:70vh;"], ["viewer", "google"]], null, null, null, i2.View_NgxDocViewerComponent_0, i2.RenderType_NgxDocViewerComponent)), i1.ɵdid(1, 704512, null, 0, i3.NgxDocViewerComponent, [i4.DomSanitizer, i1.NgZone], { url: [0, "url"], viewer: [1, "viewer"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.fileUrl)); var currVal_1 = "google"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DocumentViewerModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["alt", ""], ["class", "d-inline-block w-75"]], [[8, "src", 4]], null, null, null, null)), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.fileUrl)); _ck(_v, 1, 0, currVal_0); }); }
export function View_DocumentViewerModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.TitleCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 0, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentViewerModalComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentViewerModalComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close("Close click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-bold btn-info"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_co.document) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isImage(_co.document.filename); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.isImage(_co.document.filename); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.document.filename)); _ck(_v, 3, 0, currVal_0); }); }
export function View_DocumentViewerModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-document-viewer-modal", [], null, null, null, View_DocumentViewerModalComponent_0, RenderType_DocumentViewerModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.DocumentViewerModalComponent, [i7.NgbActiveModal, i4.DomSanitizer, i8.RestApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentViewerModalComponentNgFactory = i1.ɵccf("kt-document-viewer-modal", i6.DocumentViewerModalComponent, View_DocumentViewerModalComponent_Host_0, {}, {}, []);
export { DocumentViewerModalComponentNgFactory as DocumentViewerModalComponentNgFactory };
