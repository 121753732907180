import { DoCheck, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CountryCode, Examples } from './data/country-code';
import { phoneNumberValidator } from './international-phone-input.validator';
import { getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { MatFormFieldControl } from '@angular/material';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
const PLUS = '+';
const ɵ0 = phoneNumberValidator;
export class InternationalPhoneInputComponent {
    constructor(countryCodeData, fm, elRef, ngControl) {
        this.countryCodeData = countryCodeData;
        this.fm = fm;
        this.elRef = elRef;
        this.ngControl = ngControl;
        this.preferredCountries = [];
        this.enablePlaceholder = true;
        this.onlyCountries = [];
        this.enableSearch = false;
        // tslint:disable-next-line:variable-name
        this._required = false;
        // tslint:disable-next-line:variable-name
        this._disabled = false;
        this.stateChanges = new Subject();
        this.focused = false;
        this.errorState = false;
        this.id = `international-phone-input-${InternationalPhoneInputComponent.nextId++}`;
        this.describedBy = '';
        this.phoneNumber = '';
        this.allCountries = [];
        this.preferredCountriesInDropDown = [];
        this.countryChanged = new EventEmitter();
        this.onTouched = () => {
        };
        this.propagateChange = (_) => {
        };
        fm.monitor(elRef, true).subscribe(origin => {
            if (this.focused && !origin) {
                this.onTouched();
            }
            this.focused = !!origin;
            this.stateChanges.next();
        });
        this.fetchCountryData();
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }
    static getPhoneNumberPlaceHolder(countryISOCode) {
        try {
            return getExampleNumber(countryISOCode, Examples).number.toString();
        }
        catch (e) {
            return e;
        }
    }
    static startsWithPlus(text) {
        return text.startsWith(PLUS);
    }
    static reducePrefixes(foundPrefixes) {
        return foundPrefixes.reduce((first, second) => first.dialCode.length > second.dialCode.length
            ? first
            : second);
    }
    findPrefix(prefix) {
        const foundPrefixes = this.allCountries.filter((country) => prefix.startsWith(country.dialCode));
        if (!_.isEmpty(foundPrefixes)) {
            this.selectedCountry = InternationalPhoneInputComponent.reducePrefixes(foundPrefixes);
            if (this.phoneNumber.trim().indexOf(' ') !== -1) {
                this.phoneNumber = this.phoneNumber.trim().replace(/^(\+\d*)/, '');
            }
        }
    }
    _getFullNumber() {
        const val = this.phoneNumber.trim();
        const dialCode = this.selectedCountry.dialCode;
        let prefix;
        const numericVal = val.replace(/\D/g, '');
        // normalized means ensure starts with a 1, so we can match against the full dial code
        const normalizedVal = numericVal.charAt(0) === '1' ? numericVal : '1'.concat(numericVal);
        if (val.charAt(0) !== '+') {
            // when using separateDialCode, it is visible so is effectively part of the typed number
            prefix = '+'.concat(dialCode);
        }
        else if (val && val.charAt(0) !== '+' && val.charAt(0) !== '1' && dialCode && dialCode.charAt(0) === '1'
            && dialCode.length === 4 && dialCode !== normalizedVal.substr(0, 4)) {
            // ensure national NANP numbers contain the area code
            prefix = dialCode.substr(1);
        }
        else {
            prefix = '';
        }
        return prefix + numericVal;
    }
    ngOnInit() {
        if (this.preferredCountries.length) {
            this.preferredCountries.forEach(iso2 => {
                const preferredCountry = this.allCountries.filter((c) => {
                    return c.iso2 === iso2;
                });
                this.preferredCountriesInDropDown.push(preferredCountry[0]);
            });
        }
        if (this.onlyCountries.length) {
            this.allCountries = this.allCountries.filter(c => this.onlyCountries.includes(c.iso2));
        }
        if (this.numberInstance && this.numberInstance.country) {
            // If an existing number is present, we use it to determine selectedCountry
            this.selectedCountry = this.allCountries.find(c => c.iso2 === this.numberInstance.country.toLowerCase());
        }
        else {
            if (this.preferredCountriesInDropDown.length) {
                this.selectedCountry = this.preferredCountriesInDropDown[0];
            }
            else {
                this.selectedCountry = this.allCountries[0];
            }
        }
        this.countryChanged.emit(this.selectedCountry);
    }
    ngDoCheck() {
        if (this.ngControl) {
            this.errorState = this.ngControl.invalid && this.ngControl.touched;
            this.stateChanges.next();
        }
    }
    onPhoneNumberChange() {
        try {
            if (InternationalPhoneInputComponent.startsWithPlus(this.phoneNumber.trim())) {
                this.findPrefix(this.phoneNumber.trim().split(PLUS)[1]);
            }
            ;
            this.numberInstance = parsePhoneNumberFromString(this._getFullNumber());
            this.value = this.numberInstance.number;
            if (this.numberInstance && this.numberInstance.isValid()) {
                this.phoneNumber = this.numberInstance.formatNational();
            }
        }
        catch (e) {
            // if no possible numbers are there,
            // then the full number is passed so that validator could be triggered and proper error could be shown
            this.value = this._getFullNumber();
        }
        this.propagateChange(this.value);
    }
    onCountrySelect(country, el) {
        this.selectedCountry = country;
        this.countryChanged.emit(this.selectedCountry);
        this.onPhoneNumberChange();
        el.focus();
    }
    onInputKeyPress(event) {
        const pattern = /[0-9+\- ]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }
    fetchCountryData() {
        this.countryCodeData.allCountries.forEach(c => {
            const country = {
                name: c[0].toString(),
                iso2: c[1].toString(),
                dialCode: c[2].toString(),
                priority: +c[3] || 0,
                areaCodes: c[4] || undefined,
                flagClass: c[1].toString().toUpperCase(),
                placeHolder: ''
            };
            if (this.enablePlaceholder) {
                country.placeHolder = InternationalPhoneInputComponent.getPhoneNumberPlaceHolder(country.iso2.toUpperCase());
            }
            this.allCountries.push(country);
        });
    }
    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        this.disabled = isDisabled;
    }
    writeValue(value) {
        // when form is reset
        if (value === null) {
            this.reset();
        }
        if (value) {
            this.numberInstance = parsePhoneNumberFromString(value);
            if (this.numberInstance) {
                const countryCode = this.numberInstance.country;
                this.phoneNumber = this.numberInstance.formatNational();
                if (!countryCode) {
                    return;
                }
                setTimeout(() => {
                    this.selectedCountry = this.allCountries.find(c => c.iso2 === countryCode.toLowerCase());
                    this.countryChanged.emit(this.selectedCountry);
                }, 1);
            }
        }
    }
    get empty() {
        return !this.phoneNumber;
    }
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(value) {
        this._placeholder = value;
        this.stateChanges.next();
    }
    get required() {
        return this._required;
    }
    set required(value) {
        this._required = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = coerceBooleanProperty(value);
        this.stateChanges.next();
    }
    setDescribedByIds(ids) {
        this.describedBy = ids.join(' ');
    }
    onContainerClick(event) {
        if (event.target.tagName.toLowerCase() !== 'input') {
            // tslint:disable-next-line:no-non-null-assertion
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }
    reset() {
        this.phoneNumber = '';
        this.propagateChange(null);
    }
    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef);
    }
}
InternationalPhoneInputComponent.nextId = 0;
export { ɵ0 };
