<div>
    <div class="balance">
        <div class="form_items row">
            <div class="form_container row">

                    <fieldset [disabled]="readOnly">
                <div class="col" appearance="outline">
                    <label>{{balanceFeeText}}</label>
                    <input
                           type="number"
                           [(ngModel)]="amount"
                           (keydown)="removeInvalidCharacters($event)"
                           required
                           (change)="onChanged()"
                           min="0"
                           class="form-control col-4"
                           value="0">
                </div>
            </fieldset>

            </div>
        </div>
    </div>
</div>
