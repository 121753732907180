/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../partials/content/general/error/error.component.ngfactory";
import * as i3 from "../../../partials/content/general/error/error.component";
import * as i4 from "./error-page.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../core/_base/layout/services/layout-config.service";
var styles_ErrorPageComponent = [i0.styles];
var RenderType_ErrorPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ErrorPageComponent, data: {} });
export { RenderType_ErrorPageComponent as RenderType_ErrorPageComponent };
export function View_ErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-error", [], [[8, "className", 0]], null, null, i2.View_ErrorComponent_0, i2.RenderType_ErrorComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorComponent, [], { type: [0, "type"], image: [1, "image"], code: [2, "code"], title: [3, "title"], subtitle: [4, "subtitle"], desc: [5, "desc"], return: [6, "return"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.type; var currVal_2 = _co.image; var currVal_3 = _co.code; var currVal_4 = _co.title; var currVal_5 = _co.subtitle; var currVal_6 = _co.desc; var currVal_7 = _co.return; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classes; _ck(_v, 0, 0, currVal_0); }); }
export function View_ErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "kt-error-page", [], null, null, null, View_ErrorPageComponent_0, RenderType_ErrorPageComponent)), i1.ɵdid(1, 245760, null, 0, i4.ErrorPageComponent, [i5.ActivatedRoute, i6.LayoutConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPageComponentNgFactory = i1.ɵccf("kt-error-page", i4.ErrorPageComponent, View_ErrorPageComponent_Host_0, { type: "type", image: "image", code: "code", title: "title", subtitle: "subtitle", desc: "desc", return: "return" }, {}, []);
export { ErrorPageComponentNgFactory as ErrorPageComponentNgFactory };
