import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
// NGRX
import { Action } from '@ngrx/store';
import { KycReport } from '../../models/kyc-report';


export enum KycReportsActionTypes {
    LOAD_KYC_REPORTS = '[KYC REPORTS] Load KYC Reports',
    GET_KYC_REPORTS = '[KYC REPORTS] Get KYC Reports',
    GET_KYC_REPORTS_FAILURE = '[KYC REPORTS] Get KYC Reports failure',
    GET_KYC_REPORTS_SUCCESS = '[KYC REPORTS] Get KYC Reports Success',
    ADD_KYC_REPORT = '[KYC REPORTS] Add KYC Report',
    LOAD_KYC_REPORTS_PAGE = '[KYC REPORTS] KYC Reports Loaded',
    ADD_KYC_REPORT_SUCCESS = '[KYC REPORTS] Add KYC Report Success',
    ADD_KYC_REPORT_FAILURE = '[KYC REPORTS] Add KYC Report Failure',
    EDIT_KYC_REPORT = '[KYC REPORTS] Edit KYC Report',
    EDIT_KYC_REPORT_SUCCESS = '[KYC REPORTS] KYC Report Edited',
    EDIT_KYC_REPORT_FAIL = '[KYC REPORTS] KYC Report Failed to Edit',
    DISABLE_KYC_REPORT = '[KYC REPORTS] Disable KYC Report',
    DELETE_KYC_REPORT_SUCCESS = '[KYC REPORTS] KYC Report Deleted',
    DELETE_KYC_REPORT_FAIL = '[KYC REPORTS] KYC Report Failed to Delete'
}


export class LoadKycReportsAction implements Action {
    readonly type = KycReportsActionTypes.LOAD_KYC_REPORTS;
    constructor(public payload: KycReport[]) { }
}

export class KycReportsPageRequestedAction implements Action {
    readonly type = KycReportsActionTypes.GET_KYC_REPORTS;
    constructor(public payload: { entityId: string, page: QueryParamsModel }) { }
}

export class KycReportsPageLoadedAction implements Action {
    readonly type = KycReportsActionTypes.LOAD_KYC_REPORTS_PAGE;
    constructor(public payload: { reports: KycReport[], totalCount: number, page: QueryParamsModel }) { }
}

export class DisableKycReportAction implements Action {
    readonly type = KycReportsActionTypes.DISABLE_KYC_REPORT;
    constructor(public payload: { reportId: string }) { }
}

export class GetKycReportsFailureAction implements Action {
    readonly type = KycReportsActionTypes.GET_KYC_REPORTS_FAILURE;
    constructor(public payload: string) { }
}

export class GetKycReportsSuccessAction implements Action {
    readonly type = KycReportsActionTypes.GET_KYC_REPORTS_SUCCESS;
    constructor(public payload: string) { }
}

export class AddKycReportAction implements Action {
    readonly type = KycReportsActionTypes.ADD_KYC_REPORT;
    constructor(public payload: KycReport) { }
}

export class AddKycReportSuccessAction implements Action {
    readonly type = KycReportsActionTypes.ADD_KYC_REPORT_SUCCESS;
    constructor(public payload: string) { }
}

export class AddKycReportFailureAction implements Action {
    readonly type = KycReportsActionTypes.ADD_KYC_REPORT_FAILURE;
    constructor(public payload: string) { }
}

export class EditKycReportAction implements Action {
    readonly type = KycReportsActionTypes.EDIT_KYC_REPORT;
    constructor(public payload: KycReport) { }
}

export type KycReportsActions
    = LoadKycReportsAction
    | KycReportsPageRequestedAction
    | GetKycReportsFailureAction
    | GetKycReportsSuccessAction
    | AddKycReportAction
    | AddKycReportSuccessAction
    | AddKycReportFailureAction
    | KycReportsPageLoadedAction
    | EditKycReportAction;
