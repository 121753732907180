<div class="InOutBound">
    <div class="zone">
       {{gbpZone}}
    </div>
    <form [formGroup]="inOutBoundForm" class="bounds row">
        <div class="bound inbound col-6 pr-4">
            <div class="form_items row">
                <div class="form_container">
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label class="inout">Inbound</mat-label>
                        <select (change)="onTypeChange($event.target.value, Direction.IN)"
                                #inType
                                formControlName="inType">
                            <option *ngFor="let type of types" [value]="type">
                                {{type}}
                            </option>
                        </select>
                    </div>
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label class="amt" >Amount</mat-label>
                        <input formControlName="inAmount"
                               name="inAmount"
                               (blur)="onChanged()"
                               type="number"
                               required
                               min="0"
                               aria-required="true"
                               class="form-control"
                               validate
                               (keyup)="removeInvalidCharacters($event)"
                               value="">
                    </div>
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label>Minimum</mat-label>
                        <input formControlName="inFloor"
                               name="inFloor"
                               (blur)="onChanged()"
                               type="number"
                               min="0"
                               class="form-control"
                               (keyup)="removeInvalidCharacters($event)"
                               value=""
                               [matTooltip]="getErrorMessage(inOutBoundForm.controls['inFloor'])"
                               [matTooltipDisabled]="!inOutBoundForm.controls['inFloor'].invalid"
                               >
                    </div>
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label>Maximum</mat-label>
                        <input formControlName="inCap"
                               name="inCap"
                               (blur)="onChanged()"
                               type="number"
                               min="0"
                               class="form-control"
                               (keyup)="removeInvalidCharacters($event)"
                               value="">
                    </div>
                </div>
            </div>
        </div>
        <div class="bound outbound col-6 pl-4">
            <div class="form_items row">
                <div class="form_container">
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label class="inout">Outbound</mat-label>
                        <select (change)="onTypeChange($event.target.value, Direction.OUT)"
                                #outType
                                formControlName="outType">
                            <option *ngFor="let type of types" [value]="type">
                                {{type}}
                            </option>
                        </select>
                    </div>
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label class="amt" >Amount</mat-label>
                        <input formControlName="outAmount"
                               name="outAmount"
                               type="number"
                               (blur)="onChanged()"
                               required
                               min="0"
                               aria-required="true"
                               class="form-control"
                               (keyup)="removeInvalidCharacters($event)"
                               value="">
                    </div>
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label>Minimum</mat-label>
                        <input formControlName="outFloor"
                               name="outFloor"
                               (blur)="onChanged()"
                               type="number"
                               min="0"
                               class="form-control"
                               (keyup)="removeInvalidCharacters($event)"
                               value=""
                               [matTooltip]="getErrorMessage(inOutBoundForm.controls['outFloor'])"
                               [matTooltipDisabled]="!inOutBoundForm.controls['outFloor'].invalid">
                    </div>
                    <div class="pr-2"
                         appearance="outline">
                        <mat-label>Maximum</mat-label>
                        <input formControlName="outCap"
                               name="outCap"
                               (blur)="onChanged()"
                               type="number"
                               min="0"
                               class="form-control"
                               (keyup)="removeInvalidCharacters($event)"
                               value="">
                    </div>
                </div>
            </div>
        </div>
        <button [hidden]="true" ></button>
    </form>
</div>
<mat-divider></mat-divider>
