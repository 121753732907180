<div class="modal-header">
    <h5 class="modal-title"></h5>
    <button type="button" class="close" (click)="activeModal.close('Close click')">
    </button>
</div>
<div class="modal-body">
<div class="alert alert-bold"
    [ngClass]="{ 'alert-solid-success': !responseStatus.isError, 'alert-solid-danger': responseStatus.isError }"
    *ngIf="responseStatus.show" id="thanksalot">
    {{ responseStatus.message }}
</div>
<div *ngIf="!onboardingLink" class="onboarding-link">
    Loading...
</div>
<div *ngIf="onboardingLink" class="onboarding-link">
    {{ onboardingLink }}
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
    <a *ngIf="onboardingLink" [href]="onboardingLink" target="_blank" type="button" class="btn btn-bold btn-info">Go to onboarding</a>
</div>
