import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
import { KycReport } from '../../models/kyc-report';
import { KycReportsActions, KycReportsActionTypes } from '../actions/kyc-reports';
// NGRX

export interface KycReportState extends EntityState<KycReport> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedReportId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<KycReport> = createEntityAdapter<KycReport>();

export const initialSystemState: KycReportState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    lastCreatedReportId: undefined,
    showInitWaitingMessage: true
});

export function systemsReducer(state = initialSystemState, action: KycReportsActions): KycReportState {
    switch (action.type) {
        case KycReportsActionTypes.LOAD_KYC_REPORTS_PAGE: {
            return adapter.addMany(action.payload.reports, {
                ...initialSystemState,
                totalCount: action.payload.totalCount,
                lastQuery: action.payload.page,
                listLoading: false,
                showInitWaitingMessage: false
            });
        }
        // case EntitiesActionTypes.ADD_DOCUMENT_TO_ENTITY: {
        //     const systems = state.onboarding.systems.slice(0);
        //     const addEntities = systems.map(entity => {
        //         return Object.assign({ ...entity }, { documents: action.payload[0].entity_id === entity.id ? action.payload : [] });
        //     });
        //     return {
        //         ...state,
        //         onboarding: {
        //             systems: [...addEntities]
        //         }
        //     };
        // }
        default: return state;
    }
}
