<div class="modal-header">
    <h5 class="modal-title">{{ document.filename |titlecase }}</h5>
    <button type="button" class="close" (click)="activeModal.close('Close click')">
    </button>
</div>
<div class="modal-body">

<ngx-doc-viewer *ngIf="!isImage(document.filename)" [url]="(fileUrl |async)" viewer="google" style="width:100%;height:70vh;"></ngx-doc-viewer>
<div *ngIf="isImage(document.filename)" class="d-flex justify-content-center">
<img [src]="(fileUrl |async)" class="d-inline-block w-75" alt="">
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" class="btn btn-bold btn-info" (click)="onDownloadFile(document)">Download</button>
</div>
