// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth

const routes: Routes = [
    {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
    {
        path: '',
        component: BaseComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'entities',
                loadChildren: () => import('./views/pages/entities/entities.module').then(m => m.EntitiesModule),
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'system',
                loadChildren: () => import('./views/pages/system/system.module').then(m => m.SystemModule),
                canActivate: [
                    AuthGuard
                ],
            },

            {
                path: 'partners',
                loadChildren: () => import('./views/pages/partners/partners.module').then(m => m.PartnersModule),
                canActivate: [
                    AuthGuard
                ],
            },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: {
                    type: 'error-v6',
                    code: 403,
                    title: '403... Access forbidden',
                    desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
                }
            },
            {
                path: 'error/404',
                component: ErrorPageComponent,
                data: {
                    type: 'error-v3',
                    code: 404,
                    title: 'Page Not Found',
                    desc: 'Page Not Found'
                }
            },
            {
                path: 'error/401',
                component: ErrorPageComponent,
                data: {
                    type: 'error-v6',
                    code: 401,
                    title: '401... Unauthorized',
                    desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
                }
            },
            {path: 'error/:type', component: ErrorPageComponent},
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
        ]
    },
    {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
