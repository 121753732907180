<div class="text-center">
    <h1 mat-dialog-title>Are you sure?</h1>
    <div mat-dialog-content>
        <p>Are you sure you want to {{ data.action }} this {{ data.item }}? {{ data.extraInfo }}</p>
    </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
    <button class="btn btn-bold btn-sm btn-label-danger mr-2" (click)="onNoClick()">Cancel</button>
    <button *ngIf="!data.danger" class="btn btn-bold btn-sm btn-success" [mat-dialog-close]="data.action" cdkFocusInitial>{{ data.action | titlecase }}</button>
    <button *ngIf="data.danger" class="btn btn-bold btn-sm btn-danger" [mat-dialog-close]="data.action"
        cdkFocusInitial>{{ data.action | titlecase }}</button>

</div>
