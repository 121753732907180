import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MapQuestionsPipe } from 'src/app/pipes/map-questions.pipe';
import { ObjMapPipe } from 'src/app/pipes/object-map.pipe';
import { InternationalPhoneInputModule } from '../../../../components/mat-intl-phone/international-phone-input.module';
import { CoreModule } from '../../../../core/core.module';
import { LayoutUtilsService } from '../../../../core/_base/crud';
import { IsArrayPipe } from '../../../../pipes/is-array.pipe';
import { TypeofPipe } from '../../../../pipes/typeof.pipe';
import { MaterialModule } from '../../../../shared/material.module';
import { ActionNotificationComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent } from '../../../partials/content/crud';
import { PartialsModule } from '../../../partials/partials.module';
import { AccountSettingsFormComponent } from './account-settings-form/account-settings-form.component';
import { ActivityHistoryComponent } from './activity-history/activity-history.component';
import { AddIndividualShareholderModalFormComponent } from './add-individual-shareholder-modal-form/add-individual-shareholder-modal-form.component';
import { AddNoteComponent } from './add-note/add-note.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { BankingInfoFormComponent } from './banking-info-form/banking-info-form.component';
import { CheckListComponent } from './check-list/check-list.component';
import { CorporateContactFormComponent } from './corporate-contact-form/corporate-contact-form.component';
import { CorporateProfileComponent } from './corporate-profile/corporate-profile.component';
import { DocumentViewerModalComponent } from './document-viewer-modal/document-viewer-modal.component';
import { DocumentsFormComponent } from './documents-form/documents-form.component';
import { DocumentsManagementComponent } from './documents-management/documents-management.component';
import { EditCorporateComponent } from './edit-corporate/edit-corporate.component';
import { EditDocumentsComponent } from './edit-documents/edit-documents.component';
import { EditIndividualComponent } from './edit-individual/edit-individual.component';
import { EntityAccountsComponent } from './entity-accounts/entity-accounts.component';
import { EntityUsersFormModalComponent } from './entity-users/entity-users-form.component';
import { EntityUsersComponent } from './entity-users/entity-users.component';
import { FileViewerModalComponent } from './file-viewer-modal/file-viewer-modal.component';
import { IndividualFormModalComponent } from './identity-verification/identity-verification-form-modal.component';
import { IdentityVerificationComponent } from './identity-verification/identity-verification.component';
import { IndividualProfileComponent } from './individual-profile/individual-profile.component';
import { LinkEntityModalComponent } from './link-entity-modal/link-entity-modal.component';
import { OnboardingAnsweredQuestionsComponent } from './onboarding-answered-questions/onboarding-answered-questions.component';
import { OnboardingChecklistComponent } from './onboarding-checklist/onboarding-checklist.component';
import { OnboardingDueDiligenceQuestionnaireComponent } from './onboarding-due-diligence-questionnaire/onboarding-due-diligence-questionnaire.component';
import { PersonalInfoFormComponent } from './personal-info-form/personal-info-form.component';
import { RiskAssessmentQuestionsComponent } from './risk-assessment-questions/risk-assessment-questions.component';
import { SendEmailModalComponent } from './send-email-modal/send-email-modal.component';
import { ShowLinkModalComponent } from './show-link-modal/show-link-modal.component';
import { SourceOfFundsFormComponent } from './source-of-funds-form/source-of-funds-form.component';
import { WelcomePackModalComponent } from './welcome-pack-modal/welcome-pack-modal.component';
import { EntityBankAccountsComponent } from './entity-bank-accounts/entity-bank-accounts.component';
import { KycReportsComponent } from './kyc-reports/kyc-reports.component';
import {MatStepperModule} from "@angular/material/stepper";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}

@NgModule({
	declarations: [
		IndividualProfileComponent,
		AddNoteComponent,
		EditIndividualComponent,
		DocumentsFormComponent,
		AccountSettingsFormComponent,
		PersonalInfoFormComponent,
		BankingInfoFormComponent,
		EditDocumentsComponent,
		AddressFormComponent,
		SourceOfFundsFormComponent,
		DocumentsManagementComponent,
		CorporateProfileComponent,
		EditCorporateComponent,
		CorporateContactFormComponent,
		ActivityHistoryComponent,
		CheckListComponent,
		FileViewerModalComponent,
		OnboardingChecklistComponent,
		OnboardingAnsweredQuestionsComponent,
		DocumentViewerModalComponent,
		TypeofPipe,
		IsArrayPipe,
		RiskAssessmentQuestionsComponent,
		OnboardingDueDiligenceQuestionnaireComponent,
		LinkEntityModalComponent,
		AddIndividualShareholderModalFormComponent,
		ShowLinkModalComponent,
		MapQuestionsPipe,
		ObjMapPipe,
		EntityAccountsComponent,
		WelcomePackModalComponent,
		SendEmailModalComponent,
		EntityUsersComponent,
		EntityUsersFormModalComponent,
		IdentityVerificationComponent,
		IndividualFormModalComponent,
        KycReportsComponent,
        EntityBankAccountsComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		CoreModule,
		NgxMaskModule.forRoot(options),
		FormsModule,
		MaterialModule,
		NgBootstrapFormValidationModule,
		NgbModule,
		NgbAlertModule,
		PartialsModule,
		InternationalPhoneInputModule,
		RouterModule,
		NgxDocViewerModule
	],
	providers: [LayoutUtilsService],
	exports: [
		IndividualProfileComponent,
		EditIndividualComponent,
		DocumentsFormComponent,
		AccountSettingsFormComponent,
		PersonalInfoFormComponent,
		BankingInfoFormComponent,
		EditDocumentsComponent,
		AddressFormComponent,
		SourceOfFundsFormComponent,
		DocumentsManagementComponent,
		CorporateProfileComponent,
		EditCorporateComponent,
		CorporateContactFormComponent,
		OnboardingAnsweredQuestionsComponent,
		RiskAssessmentQuestionsComponent,
		OnboardingDueDiligenceQuestionnaireComponent,
		LinkEntityModalComponent,
		AddIndividualShareholderModalFormComponent,
		ShowLinkModalComponent,
        EntityBankAccountsComponent,
        KycReportsComponent
	],
	entryComponents: [
		AddNoteComponent,
		DocumentViewerModalComponent,
		ActionNotificationComponent,
		FetchEntityDialogComponent,
		DeleteEntityDialogComponent,
		LinkEntityModalComponent,
		AddIndividualShareholderModalFormComponent,
		ShowLinkModalComponent,
		WelcomePackModalComponent,
		SendEmailModalComponent,
		EntityUsersFormModalComponent,
		IndividualFormModalComponent,
        KycReportsComponent
	]
})
export class ComponentsModule {}
