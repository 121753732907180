<!-- begin:: Content Head -->
<div class="kt-subheader kt-grid__item" [ngClass]="{'kt-container--clear': clear}"
     [hidden]="subheaderService.disabled$ | async" id="kt_subheader">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
        <div class="kt-subheader__main">
            <h3 *ngIf="title" class="kt-subheader__title">{{title}}</h3>
            <h4 *ngIf="desc" class="kt-subheader__desc">{{desc}}</h4>
            <div class="kt-subheader__breadcrumbs" *ngIf="breadcrumbs.length > 0">
                <span class="kt-subheader__separator" [hidden]="true"></span>
                <a class="kt-subheader__breadcrumbs-home"><i class="flaticon2-shelter"></i></a>
                <ng-container *ngFor="let item of breadcrumbs">
                    <span class="kt-subheader__breadcrumbs-separator"></span>
                    <a [routerLink]="item.page" class="kt-subheader__breadcrumbs-link">
                        {{item.title}}
                    </a>
                </ng-container>
                <!-- <span class="kt-subheader__breadcrumbs-link kt-subheader__breadcrumbs-link--active">Active link</span> -->
            </div>
            <form class="kt-margin-l-20" id="kt_subheader_search_form" *ngIf="showFilter">
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search">
                    <div class="input-group">
                        <input type="text" [formControl]="queryField" class="form-control" placeholder="Search this blog">
                        <div class="input-group-append">
                            <button class="btn" (click)="onSearch()" type="submit">
                                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                                    id="Path-2" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                                <path
                                                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                                    id="Path" fill="#000000" fill-rule="nonzero"></path>
                                            </g>
                                        </svg>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>

                </div>
            </form>

            <div class="btn-group kt-margin-l-5" *ngIf="showFilter">
                <div ngbDropdown [placement]="'bottom-left'" class="dropdown">
                    <a ngbDropdownToggle href="javascript:;" class="btn btn-info btn-sm dropdown-toggle">
                        Order By
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
                        <button [routerLink]="[currentUrl]" [queryParams]="{ order: undefined}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Default
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ order: 'asc'}" queryParamsHandling="merge"
                                [replaceUrl]="true" ngbDropdownItem>Alphabetically A-Z
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ order: 'desc'}" queryParamsHandling="merge"
                                [replaceUrl]="true" ngbDropdownItem>Alphabetically Z-A
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ order: 'recent'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Most Recent
                        </button>
                    </div>
                </div>
                <div *ngIf="currentRoute !== 'corporate'" ngbDropdown [placement]="'bottom-left'"
                     class="d-inline-block dropdown">
                    <a ngbDropdownToggle href="javascript:;" class="btn btn-info btn-sm dropdown-toggle">
                        KYC
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
                        <button [routerLink]="[currentUrl]" [queryParams]="{ onfido: undefined}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>All
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ onfido: 'clear'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Clear
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ onfido: 'consider'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Consider
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ onfido: 'unknown'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Unknown
                        </button>
                    </div>
                </div>
                <div *ngIf="currentRoute === 'onboarding'" ngbDropdown [placement]="'bottom-left'"
                     class="dropdown">
                    <a ngbDropdownToggle href="javascript:;" class="btn btn-info btn-sm dropdown-toggle">
                        Type
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
                        <button [routerLink]="[currentUrl]" [queryParams]="{ type: 'corporate'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Corporate
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ type: 'individual'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Individual
                        </button>
                    </div>
                </div>
                <div *ngIf="currentRoute !== 'onboarding'" ngbDropdown [placement]="'bottom-left'"
                     class="dropdown">
                    <a ngbDropdownToggle href="javascript:;" class="btn btn-info btn-sm dropdown-toggle">
                        Trade Status
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
                        <button [routerLink]="[currentUrl]" [queryParams]="{ trade_status: undefined}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>All
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ trade_status: 'can_trade'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Can Trade
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ trade_status: 'can_trade_swiss_co'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Can Trade Swiss Co
                        </button>
                    </div>
                </div>
                <div *ngIf="currentRoute !== 'onboarding'" ngbDropdown [placement]="'bottom-left'"
                     class="dropdown">
                    <a ngbDropdownToggle href="javascript:;" class="btn btn-info btn-sm dropdown-toggle">
                        Verification Status
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
                        <button [routerLink]="[currentUrl]" [queryParams]="{ verification_status: undefined}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>All
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ verification_status: 'VerifiedL2'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>VerifiedL2
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ verification_status: 'VerifiedL1'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>VerifiedL1
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ verification_status: 'InProgress'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>InProgress
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ verification_status: 'Queued'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Queued
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ verification_status: 'Non-Verified'}"
                                queryParamsHandling="merge" [replaceUrl]="true" ngbDropdownItem>Non-verified
                        </button>
                    </div>
                </div>

                <div ngbDropdown [placement]="'bottom-left'" class="dropdown">
                    <a ngbDropdownToggle href="javascript:;" class="btn btn-info btn-sm dropdown-toggle">
                        Filter
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
                        <button [routerLink]="[currentUrl]" [queryParams]="{filter: undefined}" queryParamsHandling="merge"
                                [replaceUrl]="true" ngbDropdownItem>All
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ filter: 'archived'}" queryParamsHandling="merge"
                                [replaceUrl]="true" ngbDropdownItem>Archived
                        </button>
                        <button [routerLink]="[currentUrl]" [queryParams]="{ filter: 'rejected'}" queryParamsHandling="merge"
                                [replaceUrl]="true" ngbDropdownItem>Rejected
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <button class="btn btn-bold btn-info btn-sm kt-margin-t-10" (click)="onAddIndvidual()">Add
                    Individual
                </button>
                <button class="btn btn-bold btn-info btn-sm kt-margin-t-10" (click)="onAddCorporate()">Add
                    Corporate
                </button>
            </div>
        </div>
    </div>
</div>
<!-- end:: Content Head -->
