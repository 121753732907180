import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { InternationalPhoneInputComponent } from './international-phone-input.component';
import { SearchPipe } from './search.pipe';
import { MaterialModule } from '../../shared/material.module';

@NgModule({
  declarations: [
    InternationalPhoneInputComponent,
    SearchPipe,
  ],
  imports:[
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [InternationalPhoneInputComponent]
})
export class InternationalPhoneInputModule { }
