import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { RestApiService } from '../../services/rest-api.service';
import { BeneficiariesActionTypes, BeneficiariesPageLoadedAction, BeneficiariesPageRequestedAction } from '../actions/beneficiaries';
import { QueryParamsModel } from './../../core/_base/crud/models/query-models/query-params.model';
import { QueryResultsModel } from './../../core/_base/crud/models/query-models/query-results.model';


@Injectable()
export class BeneficiariesEffects {
    // showPageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: true });
    // hidePageLoadingDistpatcher = new UsersPageToggleLoading({ isLoading: false });

    // showActionLoadingDistpatcher = new UsersActionToggleLoading({ isLoading: true });
    // hideActionLoadingDistpatcher = new UsersActionToggleLoading({ isLoading: false });

    @Effect()
    getBeneficiaries$: Observable<Action> = this.actions$
        .pipe(ofType<BeneficiariesPageRequestedAction>(BeneficiariesActionTypes.GET_BENEFICIARIES),
            mergeMap(({ payload }) => {
                // this.store.dispatch(this.showPageLoadingDistpatcher);
                const requestToServer = this.restApiService.findBeneficiaries(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map(response => {
                const result: QueryResultsModel = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new BeneficiariesPageLoadedAction({
                    beneficiaries: result.items,
                    totalCount: result.totalCount,
                    page: lastQuery
                });
            }));

    constructor(
        private actions$: Actions,
        private restApiService: RestApiService,
        private router: Router,
    ) { }
}
