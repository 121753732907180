<div *ngFor='let rail of (paymentRails | async )' class='transaction'>
    <kt-in-out-bound [paymentRail]='rail'
                     [readOnly]='readOnly'
                     (valid)='onValid($event)'> </kt-in-out-bound>
</div>
<kt-balance-fee
    *ngIf='balanceCurrency.includes(currency)'
    [currency]='currency'
    [readOnly]='readOnly'
    (balanceOutput)='onBalanceOutput()'
    (valid)='onValid($event)'>
</kt-balance-fee>
