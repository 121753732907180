import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DisplayEntityUser } from './interfaces/display-entity-user.interface';
import { DisplayPermissionGroup } from './interfaces/display-permission-group.interface';
import { EntityUsersActions } from './interfaces/entity-users-actions.enum';

@Component({
	selector: 'entity-users-form-modal',
	templateUrl: './entity-users-form.component.html',
	styleUrls: ['./entity-users-form.component.scss']
})
export class EntityUsersFormModalComponent implements OnInit {
	@Input()
	data: DisplayEntityUser
	@Input()
	action: EntityUsersActions
	@Input()
	permissions: DisplayPermissionGroup[]

	userForm: FormGroup

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit() {
		this.userForm = new FormGroup({
			id: new FormControl(),
			email: new FormControl('', Validators.email),
			phone: new FormControl('', Validators.required),
			fullName: new FormControl('', Validators.required),
			role: new FormControl('user', Validators.required)
		})

		this.permissions.forEach((permission: DisplayPermissionGroup) => {
			this.userForm.addControl(permission.fieldName, new FormControl())
		})

		if (this.action === EntityUsersActions.ADD) {
			this.userForm.patchValue({ ['view-only']: true })
			this.mapDisplayPermission('view-only', true)
		} else if (this.action === EntityUsersActions.UPDATE) {
			this.userForm.patchValue({
				id: this.data.id,
				email: this.data.email,
				phone: this.data.phone,
				fullName: this.data.fullName,
				role: this.data.role
			})
			if (this.data.permissions.full.length) {
				this.data.permissions.full.forEach((group: DisplayPermissionGroup) => {
					this.userForm.patchValue({ [group.fieldName]: true })
					this.mapDisplayPermission(group.fieldName, true)
				})
			} else {
				this.userForm.patchValue({ ['view-only']: true })
				this.mapDisplayPermission('view-only', true)
			}
		}
	}

	onSubmit() {
		if (this.userForm.valid) {
			const user = this.mapDisplayEntityUser<{ [key: string]: any }>(this.userForm.getRawValue())
			this.activeModal.close([this.action, user])
		}
	}

	onCancel(): void {
		this.userForm.reset()
		this.activeModal.close()
	}

	onPermissionChange(event: MatCheckboxChange): void {
		if (event.checked && event.source.name === 'view-only') {
			this.permissions.forEach(permission => {
				if (permission.fieldName != 'view-only') {
					this.userForm.patchValue({ [permission.fieldName]: false })
					this.mapDisplayPermission(permission.fieldName, false)
				}
			})
		} else {
			this.mapDisplayPermission(event.source.name, event.checked)
			if (event.checked) {
				this.userForm.patchValue({ ['view-only']: false })
				this.mapDisplayPermission('view-only', false)
			}
		}
		const totalTrue: number = this.permissions.filter(permission => {
			return permission.value === true
		}).length
		if (!event.checked && totalTrue === 0) {
			this.userForm.patchValue({ ['view-only']: true })
			this.mapDisplayPermission('view-only', true)
		}
	}

	private mapDisplayEntityUser<T>(formValues: T): DisplayEntityUser {
		const fieldNames = ['id', 'fullName', 'email', 'phone', 'role', 'permissions', 'view-only']
		let user: DisplayEntityUser = {
			id: formValues['id'],
			fullName: formValues['fullName'],
			email: formValues['email'],
			phone: formValues['phone'],
			role: formValues['role'],
			permissions: {
				full: [],
				summary: []
			}
		}
		Object.keys(formValues).forEach(fieldName => {
			if (!fieldNames.includes(fieldName) && formValues[fieldName]) {
				const group = this.permissions.find(permission => {
					return permission.fieldName === fieldName && permission.value
				})
				user.permissions.full.push(group)
			}
		})
		return user
	}

	private mapDisplayPermission(fieldName: string, selected: boolean): void {
		const permissionIndex = this.permissions.findIndex(displayGroup => displayGroup.fieldName === fieldName)
		this.permissions[permissionIndex].value = selected
	}
}
