import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-show-link-modal',
  templateUrl: './show-link-modal.component.html',
  styleUrls: ['./show-link-modal.component.scss']
})
export class ShowLinkModalComponent implements OnInit {
    link: BehaviorSubject<any>;
    onboardingLink: string;
    hasError: BehaviorSubject<any>;
    responseStatus = {
        show: false,
        isError: false,
        message: 'Success, Entity Profile was successfully updated.'
    };
 constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
      this.link.subscribe(res => {
          if (res && res.hasOwnProperty('link')) {
              this.onboardingLink = res.link;
          }
          if (res instanceof HttpErrorResponse) {
              this.responseStatus.isError = true;
              this.responseStatus.show = true;
              this.responseStatus.message = res.message;
          }
      });
  }

}
