<div class="modal-header">
    <h5 class="modal-title">Send email</h5>
    <button type="button" class="close" (click)="activeModal.close()">
    </button>
</div>
<div class="modal-body">
    <!--    <pre>{{data | json}}</pre>-->
    <form [formGroup]="emailForm">
        <div class="row">
            <div class="col">
                <label for="fromEmail" class="w-100">
                    From email:
                    <input formControlName="fromEmail" type="email" id="fromEmail" class="form-control" [placeholder]="emailForm.controls['fromEmail'].value">
                </label>
            </div>
            <div class="col">
                <label for="fromAlias" class="w-100">
                    From alias:
                    <input formControlName="fromAlias" type="text" id="fromAlias" class="form-control" [placeholder]="emailForm.controls['fromAlias'].value"
                           (input)="updateBody()">
                </label>
            </div>
        </div>
        <div class="row" id="emailBodyContainer">
            <div class="col">
                <label for="emailBody" class="w-100">
                    Body:
                    <textarea formControlName="body" id="emailBody" class="form-control" [value]="emailForm.controls['body'].value"></textarea>
                </label>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="d-flex align-items-end justify-content-between">
                    <label for="toEmail" class="w-100">
                        To email:
                        <input formControlName="toEmail" type="email" id="toEmail" class="form-control">
                    </label>
                    <div>
                        <button type="button" class="btn btn-primary mb-2" (click)="onAddEmailClick(emailForm.controls['toEmail'].value)"
                                [disabled]="!emailForm.controls['toEmail'].value
                                || emailForm.controls['toEmail'].value.length < 1
                                || emailForm.controls['toEmail'].invalid">Add
                        </button>
                    </div>
                </div>
            </div>
            <div class="col"><label>To email addresses:</label>
                <div *ngFor="let toEmail of data.recipients.to; let i = index" class="d-flex align-items-center justify-content-between">
                    <div>{{toEmail}}</div>
                    <button mat-icon-button (click)="onRemoveEmailClick(i)">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSendClick()"
            [disabled]="!data.recipients.to || data.recipients.to.length < 1">Send
    </button>
</div>
