import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
// NGRX
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
// Hammer JS
import 'hammerjs';
import * as json from 'highlight.js/lib/languages/json';
import * as scss from 'highlight.js/lib/languages/scss';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as xml from 'highlight.js/lib/languages/xml';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Highlight JS
import { HighlightLanguage, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { IConfig, NgxMaskModule } from 'ngx-mask';
// Perfect Scroll bar
import { PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// Env
import { environment } from '../environments/environment';
// Modules
import { AppRoutingModule } from './app-routing.module';
// Copmponents
import { AppComponent } from './app.component';
import { AuthService } from './core/auth';
import { CoreModule } from './core/core.module';
// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './core/_base/crud';
// Layout Services
import { DataTableService, KtDialogService, LayoutConfigService, LayoutRefService, MenuAsideService, MenuConfigService, MenuHorizontalService, PageConfigService, SplashScreenService, SubheaderService } from './core/_base/layout';
// Config
import { LayoutConfig } from './core/_config/layout.config';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor'
import { RestApiService } from './services/rest-api.service';
import { EntitiesEffects } from './store/effects/entities';
import { EntityProfileEffects } from './store/effects/entity-profiles';
import { SystemsEffects } from './store/effects/systems';
// State
// import { metaReducers, reducers } from './core/reducers';
import { metaReducers, reducers } from './store/reducers';
// Auth
import { AuthModule } from './views/pages/auth/auth.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
import { ThemeModule } from './views/theme/theme.module';
import { BeneficiariesEffects } from './store/effects/beneficiaries';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

import * as Sentry from '@sentry/browser';
import { ClipboardModule } from 'ngx-clipboard';


Sentry.init({
    dsn: environment.sentryDSN
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}
    handleError(error) {
        console.error(error)
        Sentry.captureException(error.originalError || error);
        // const eventId = Sentry.captureException(error.originalError || error);
        // Sentry.showReportDialog({ eventId });
    }
}

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return () => {
        const appVersion = localStorage.getItem('app_version');
        if (!appVersion || appVersion !== environment.appVersion) {
            // reset layout config and store version
            localStorage.clear();
            appConfig.resetConfig();
            localStorage.setItem('app_version', environment.appVersion);
        }

        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}

export function hljsLanguages(): HighlightLanguage[] {
    return [
        {name: 'typescript', func: typescript},
        {name: 'scss', func: scss},
        {name: 'xml', func: xml},
        {name: 'json', func: json}
    ];
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,

        // environment.isMockEnabled ? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
        //     passThruUnknownUrl: true,
        //     dataEncapsulation: false
        // }) : [],
        NgxPermissionsModule.forRoot(),
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([EntitiesEffects, EntityProfileEffects, SystemsEffects, BeneficiariesEffects]),
        StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        AuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        ThemeModule,
        NgxMaskModule.forRoot(options),
        ClipboardModule
    ],
    exports: [],
    providers: [
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        AuthService,
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        DataTableService,
        SplashScreenService,
        RestApiService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: GestureConfig
        },
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService], multi: true
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {languages: hljsLanguages}
        },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
